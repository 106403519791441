import React, { useState, useEffect } from 'react'
import '../Style/App.css'
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import {API_URL} from "../Config/config"

export default function ForgetPassword() {
    const [moveImage, setMoveImage] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);


    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleInput = (e) => {
      setEmail(e.target.value);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleReset = async () => {
      try {
        const emailExistsResponse = await axios.post(`${API_URL}/api/check_email_exists`, { email });
  
        if (emailExistsResponse.data === 'Email exists') {
          const sendVerificationResponse = await axios.post(`${API_URL}/api/emailVerification`, { email });
  
          if (sendVerificationResponse.data.message) {
            toast.success(sendVerificationResponse.data.message);


            // const flipTimeout = setTimeout(async () => {
            //     await setIsFlipped(!isFlipped);
            //     clearTimeout(flipTimeout);
            // }, 3000);
          
            // handleOpenModal();

            setTimeout(async () => {
                await setIsFlipped(!isFlipped);
        
                handleOpenModal();
            }, 3000); 

          } else {
            toast.error('Failed to send verification email. Please try again.');
          }
        } else {
          toast.error('Email not found in the database. Please enter a valid email.');
        }
      } catch (error) {
        console.error('Error during forget password:', error.message);
        toast.error('An error occurred. Please try again.');
      }
  
      
      // toast.success(`A reset link has been sent to ${email}.`);
    };
  
  

    useEffect(() => {
        const moveInterval = setInterval(() => {
            setMoveImage((prevMove) => !prevMove);
        }, 1500); 

        return () => {
            clearInterval(moveInterval);
        };
    }, []);


    // const handleFlip = () => {
    //     setIsFlipped(!isFlipped);
    // };

    return (
        
        <div className='relative min-h-screen grid content-center font-bricolagegrotesque bg-[#101010] bg-cover bg-center' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
            <Toaster/>
            <div className="flip-card-forget mx-auto z-10 text-white">
                <div className={`flip-card-inner-forget ${isFlipped ? 'flipped' : ''}`}>
                    <div class="flip-card-inner-forget">
                        <div class="flip-card-front-forget bg-black/60 p-5 rounded-2xl grid content-center shadow-md shadow-sky-500 border-t border-sky-700">
                            <div className='flex justify-center font-semibold text-white/60 hover:text-white duration-500 text-xl'><a href='/seeker/registration'>Log In</a></div>
                            <input className="border-b  border-white/50 hover:border-white duration-500 w-[100%] mt-10 bg-transparent pb-3 placeholder:text-white outline-none" placeholder="Enter the email"></input>
                            <div className="flex justify-center mt-10">
                                <button className='flex pt-3 pb-1 px-3 bg-[#101010]/50 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={handleReset}>
                                    <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[101px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='-translate-y-0.5 mx-3 group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Submit</p> <button className=' p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                </button>
                            </div>

                        </div>
                        <div class="flip-card-back-forget p-5 bg-white grid content-center rounded-2xl">
                            <img src="/assets/Others/check-mark-verified.gif" className='mx-auto w-[50%]'></img>
                            <p className="text-black text-center">Mail send</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' absolute w-full h-full flex justify-center  z-0'>
                <div className='w-[100%] flex justify-between h-full'>
                    <div className='h-full md:w-[50%] flex justify-center items-start'>
                        <img style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translateY(65px)' : 'translateY(0)',
                        }} src='./assets/Others/Ellipse1.png' alt='circle1' className='md:w-[30%]'></img>
                    </div>
                    <div className='h-full flex items-end' style={{
                        transition: 'transform 1s ease-in-out',
                        transform: moveImage ? 'translateY(-65px)' : 'translateY(0)',
                    }}
                    ><img src='./assets/Others/Ellipse2.png' alt='circle2' className='w-[80%]'></img>
                    </div>
                </div>
            </div>
            {showModal && (
          <div
  className="fixed inset-0 flex justify-center items-center z-50"
  style={{
    backdropFilter: "blur(5px)",
  }}
>
  <div className="box bg-white p-5 rounded-lg shadow-md">
    <p className="text-center">Check you Email!</p>
    <div className="flex justify-center gap-5 mt-3">
      <a href="https://mail.google.com" target="_blank">
        <div className="box bg-FABcBlue text-white px-5 py-2 rounded-md shadow-sm hover:scale-105 transition-transform duration-300 ease-in-out">
          <i className="fa-solid fa-envelope text-lg"></i>
          <span className="ml-2 text-sm">Check Email</span>
        </div>
      </a>
      <button
        className="box bg-FABcYellow text-black px-5 py-2 rounded-md shadow-sm hover:scale-105 transition-transform duration-300 ease-in-out"
        onClick={handleCloseModal}
      >
        <i className="fa-solid fa-times-circle text-lg"></i>
        <span className="ml-2 text-sm">Close</span>
      </button>
    </div>
  </div>
</div>

        )}

        </div>
    )
}
