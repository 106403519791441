import React, { useState } from 'react';
import 'animate.css';
import '../Style/App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeanpub, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faChalkboardUser } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css'
Aos.init();

const WebinarRegistrationForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleIframeLoad = () => setIsLoading(false);



    return (
        <div className=" text-white font-bricolagegrotesque bg-cover  bg-center" style={{ backgroundImage: 'url("/assets/BG/bg.png")' }}>
            {/* Animated Introduction Section */}
            <div className="mb-10 w-[90%] mx-auto flex flex-col md:flex-row gap-5 md:gap-10 min-h-screen py-5 md:py-0 ">
                <div className='md:w-[50%] grid content-center'>
                    <h1 className="text-2xl md:text-5xl font-bold mb-3 animate-fadeIn">
                        Highly <span className='text-sky-500'>interactive webniar</span> and events in minutes.
                    </h1>
                    <p className=" font-light animate-fadeIn delay-1s text-white/75">
                        The simplest form of variety of engaging experience that build community and drive revenue all year round.
                    </p>
                    <div className='flex justify-center md:justify-start mt-5'>
                        <button className='flex pt-3 pb-1 px-3 bg-[#101010]/50 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={openModal}>
                            <button
                                className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[148px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                            <p className='mx-3 -translate-y-0.5 text-base group-hover:-translate-x-5 transform-transition duration-500 ease-in-out'>
                                Register Now </p>
                            <button
                                className=' p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                        </button>
                    </div>
                    <p className='mt-5'><span className='text-yellow-600'>Date :</span> May 15, 2020, Friday <br /><span className='text-yellow-600'>Time :</span> 9:00 AM - 4:00 PM EST</p>
                </div>
                <div className='md:w-[50%] flex items-center justify-center'>
                    <div><img src='/assets/Others/web.png'></img></div>
                </div>
            </div>

            <div className='w-[90%] mx-auto flex flex-col md:flex-row gap-10 min-h-screen  py-5 md:py-0'>
                <div className='md:w-[50%] '>
                    <div className='flex gap-5'>
                        <div className="w-[50%] group relative">
                            <img src="assets/SME/Sakthi.png" className="z-0 w-[100%]" alt="Sakthi" />
                            <div className="text-center absolute text-black flex bottom-0 right-0 transform translate-y-0 translate-x-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-5 group-hover:translate-x-5 transition-all duration-500 ease-in-out z-10">
                                <div className='w-3 h-3 rounded-full bg-sky-500 -translate-x-1 -translate-y-3'></div>
                                <div className='px-3 py-1 rounded-lg bg-white'>
                                    <p className="font-bold">Sakthi Visakan Rajaguru</p>
                                    <p className="text-black/75 text-sm">CEO</p>
                                    <FontAwesomeIcon icon={faLinkedin} className='text-[#0077B5] text-lg cursor-pointer' />
                                </div>
                            </div>
                        </div>
                        <div className="w-[30%] group relative flex items-end">
                            <img src="assets/SME/Sakthi.png" className="z-0" alt="Sakthi" />
                            <div className="text-center absolute text-black flex bottom-0 right-0 transform translate-y-0 translate-x-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-14 group-hover:translate-x-14 transition-all duration-500 ease-in-out z-10">
                                <div className='w-4 h-3 rounded-full bg-sky-500 -translate-x-1 -translate-y-3'></div>
                                <div className='px-3 py-1 rounded-lg bg-white'>
                                    <p className="font-bold">Sakthi Visakan Rajaguru</p>
                                    <p className="text-black/75 text-sm">CEO</p>
                                    <FontAwesomeIcon icon={faLinkedin} className='text-[#0077B5] text-lg cursor-pointer' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='w-[30%] '><img src='assets/SME/Sakthi.png' className=''></img></div>
                        <div className='w-[40%]'><img src='assets/SME/Sakthi.png' className=''></img></div>
                    </div>
                </div>
                <div className='md:w-[50%] grid content-center'>
                    <h1 className='text-4xl font-bold'> Our Awsome Event Speaker </h1>
                    <p className='py-3'><span className='text-sky-500 font-bold text-lg'>Don’t Miss Out! </span><br />
                        This is your chance to learn, grow, and connect with experts and peers. Spaces are limited, so register now to secure your spot in this exclusive event!</p>
                    <div className='flex justify-center md:justify-start mt-5'>
                        <button className='flex pt-3 pb-1 px-3 bg-[#101010]/50 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={openModal}>
                            <button
                                className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[100px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                            <p className='mx-3 -translate-y-0.5 text-base group-hover:-translate-x-5 transform-transition duration-500 ease-in-out'>
                                Join us </p>
                            <button
                                className=' p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                        </button>
                    </div>
                </div>
            </div>

            <div className='w-[90%] mx-auto  flex flex-col md:flex-row gap-10 items-center min-h-screen  py-5 md:py-0'>
                <div className='md:w-[50%] '>
                    <img src='/assets/Others/web1.jpg' className='brightness-50 rounded-tl-[100px]'></img>
                </div>
                <div className='md:w-[50%] '>
                    <div className='flex gap-5'>
                        <div><FontAwesomeIcon icon={faLeanpub} className='bg-yellow-500 rounded-full p-2.5 text-xl text-black' /></div>
                        <div>
                            <p className='font-bold text-xl'> What you'll learn:</p>
                            <ul className='mt-3 md:pl-5'>
                                <li>✔️ Searching for jobs</li>
                                <li>✔️ How to start applying for jobs</li>
                                <li>✔️ Where to find job posts</li>
                                <li>✔️ Preventing online scams</li>
                                <li>✔️ Creating a resume</li>
                                <li>✔️ Skills that will make you hirable</li>
                                <li>✔️ Landing your first remote job</li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-10'>
                        <div><FontAwesomeIcon icon={faChalkboardUser} className='bg-yellow-500 rounded-full p-2.5 text-xl text-black' /></div>
                        <div>
                            <p className=' font-bold text-xl'>Webinar Requirements:</p>
                            <ul className='mt-3 md:pl-5'>
                                <li>- Desktop/Laptop or Mobile Device</li>
                                <li>- Google Hangouts</li>
                                <li>- Webcam</li>
                                <li>- Headset</li>
                                <li>- Internet Connection</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Form */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black  bg-opacity-50 flex justify-center items-center z-50 animate-fadeIn">
                    <div className="bg-black border border-white/20 text-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
                        <div className="flex justify-between items-center p-4 border-b border-white/20">
                            <h2 className="text-2xl font-semibold ">Webinar Registration</h2>
                            <button
                                onClick={closeModal}
                                className="text-gray-500 hover:text-gray-700 transition duration-150"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="p-6 relative">
                            {isLoading && (
                                <div className="absolute inset-0 flex justify-center items-center bg-white">
                                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                                </div>
                            )}
                            <iframe
                                title="Webinar Registration"
                                src="https://forms.office.com/r/vEBevSQ7f8"
                                width="100%"
                                height="500"
                                className="border-0"
                                onLoad={handleIframeLoad}
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WebinarRegistrationForm;