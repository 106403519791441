import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function Course() {

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const email = useSelector((state) => state.seeker_email);
  const [paymentStatuses, setPaymentStatuses] = useState({});
  const [courses, setCourses] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    window.addEventListener('contextmenu', handleContextMenu);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);



  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://edu.d5art.com/api/Allcourses');
        const data = await response.json(); 
        console.log(data);
        const data1 = data.filter((index) => index.status == 'enabled')
        setCourses(data1);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);


  const [users, setUsers] = useState([]);


  useEffect(() => {
    axios
      .get(`https://edu.d5art.com/api/UserList?email=${email}`)
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);





  let role; 

  if (users.length > 0) {
    role = users[0].role;
    console.log("role", role);
  } else {
    console.error("Users array is empty. Cannot access role.");
  }
  




  useEffect(() => {
    setSearchTerm(search || '');
  }, [search]);


  // const handleSearch = (e) => {
  //   const term = e.target.value;
  //   setSearchTerm(term);

  //   const filtered = courses.filter((course) =>
  //     course.course_name.toLowerCase().includes(term.toLowerCase())
  //   );
  //   setFilteredCourses(filtered);
  // };


  useEffect(() => {
    const filtered = courses.filter((course) =>
      course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchTerm, courses]);

  const handleSearch = () => {
    const filtered = courses.filter((course) =>
      course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };



  const displayedCourses = searchTerm ? filteredCourses : courses;


  //----------------check status----


  useEffect(() => {
    const fetchPaymentStatuses = async () => {
      try {
        const statuses = {};
        await Promise.all(courses.map(async (course) => {
          const response = await axios.post(
            "https://edu.d5art.com/api/checkPaymentStatus",
            { email: email, courseId: course.course_id }
          );
          console.log("Response for course", course.course_id, ":", response.data);
          statuses[course.course_id] = response.data.PaymentStatus;
        }));
        console.log("Payment statuses:", statuses);
        setPaymentStatuses(statuses);
      } catch (error) {
        console.error("Error fetching payment statuses:", error);
      }
    };

    fetchPaymentStatuses();
  }, [courses]);

  



const truncateOutcomes = (outcomes, maxWords) => {

  console.log('outcomes',outcomes)
  if (typeof outcomes !== 'string') {
    return '';
  }
  const words = outcomes.split(' ');
  const truncatedDescription = words.slice(0, maxWords).join(' ');
  const showMore = words.length > 25; // Updated to 25 words
  return showMore ? `${truncatedDescription}...` : truncatedDescription;
};


const truncateDescription = (description) => {
   if (!description || typeof description !== 'string') {
    return "No description available.";
  }
  const words = description.split(' ');
  const truncatedDescription = words.slice(0, 30).join(' ');
  const showMore = words.length > 39;
  return showMore ? `${truncatedDescription}...` : truncatedDescription;
};

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};




const courseChunks = chunkArray(filteredCourses, 3);

  return (
    <div className='font-bricolagegrotesque text-white bg-[#121212]' style={{ backgroundImage: "url('./Rectangle 6467.png')" }}>
      <Header />
      <div className='main-container '>
        <div className=' pb-10'>
          <div className='flex justify-center pt-10 '>
            <p className='font-bold text-3xl lg:text-5xl'>START <span className='text-[#0087a5]'>LEARNING</span></p>
          </div>
          <div className='flex justify-center'>
            <svg width="174" height="31" viewBox="0 0 174 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 17.9998C10.5 7.5 56.6856 24.9909 78 28C120.5 34 162 24 173 1" stroke="url(#paint0_linear_4_1797)" stroke-width="2" stroke-linecap="round" />
              <defs>
                <linearGradient id="paint0_linear_4_1797" x1="1" y1="1" x2="11.0515" y2="59.9411" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F539F8" />
                  <stop offset="0.416763" stop-color="#C342F9" />
                  <stop offset="1" stop-color="#5356FB" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className='flex justify-center mt-5'>
            <p className='font-bold text-2xl'>Explore topics and skills</p>
          </div>
          <div className='flex justify-center mt-5'>
            <p className=' flex justify-between bg-white/10 backdrop-blur-md md:px-5 md:pl-3 px-2 py-2 rounded-lg md:w-[40%]'>
              <input className='outline-none pl-2 md:w-[75%] bg-transparent' placeholder="Start learning about..." type="search" value={searchTerm} onChange={handleInputChange}></input>
              <i class="fa-solid fa-magnifying-glass mt-1.5"></i>
            </p>
          </div>
        </div>


{/* 
        <div className="sec-4  px-5 md:px-12  ">

          <div className="card-container flex flex-col md:flex-row justify-center gap-10 mt-12">

            {courseChunks.slice(0, 3).map(course => (

              <div className=" sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-around shadow-md">
                <img
                  src={course.image_url}
                  alt=""
                  className="rounded-lg mb-8 "
                />
                <div>
                  <h1 className=" text-xl">
                    <span className="text-2xl  font-bold">
                      {" "}
                   
                      {course.course_name}
                    </span>
                  </h1>
                  <div className="data flex justify-between mt-8 text-sm">
                    <p className="font-semibold">
                      <i className="fa-solid fa-person-chalkboard text-slate-400 mr-2" />
                      Lessons: {course.total_contents}
                    </p>
                    <p className="font-semibold">
                      <i className="fa-solid fa-trophy text-slate-400 mr-2" />
                      Level: {course.category}
                    </p>
                  </div>
                  <ul className="mt-6">
                    <h1 className="font-bold">Description</h1>
                  

              <p className="py-5">{truncateDescription(course.course_description)}</p>

                    
                  </ul>
                </div>
                <div className="flex items-center justify-evenly mt-6">
                
                  <NavLink
                    to={`/precourse?id=${course.course_id}`}
                    key={course.id}
                  >
                    <button className="px-12 py-3  text-FABcBlue border-2 rounded-lg border-FABcBlue ">
                      {paymentStatuses[course.course_id] === "Success"  || role === "skylift_user"  ? "Start  Learning" : "Enroll Now"}

                      <i className="fa-solid fa-arrow-right-long -rotate-45 ml-2" />
                    </button>
                  </NavLink>
                </div>
              </div>

            ))}


          </div>


          <div className="card-container flex flex-col md:flex-row justify-center gap-10 mt-12">
            {filteredCourses.slice(3, 6).map(course => (

              <div className=" sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-around shadow-md">
                <img
                  src={course.image_url}
                  alt=""
                  className="rounded-lg mb-8 "
                />
                <div>
                  <h1 className=" text-xl">
                    <span className="text-2xl  font-bold">
                      {" "}
                      {course.course_name}
                    </span>
                  </h1>
                  <div className="data flex justify-between mt-8 text-sm">
                    <p className="font-semibold">
                      <i className="fa-solid fa-person-chalkboard text-slate-400 mr-2" />
                      Lessons: {course.total_contents}
                    </p>
                    <p className="font-semibold">
                      <i className="fa-solid fa-trophy text-slate-400 mr-2" />
                      Level: {course.category}
                    </p>
                  </div>
                  <ul className="mt-6">
                    <h1 className="font-bold">Description</h1>
           <p className="py-5">{truncateDescription(course.course_description)}</p>
                  </ul>
                </div>
                <div className="flex items-center justify-evenly mt-6">
                  <NavLink
                    to={`/precourse?id=${course.course_id}`}
                    key={course.id}
                  >
                    <button className="px-12 py-3  text-FABcBlue border-2 rounded-lg border-FABcBlue ">
                      {paymentStatuses[course.course_id] === "Success"  || role === "skylift_user"  ? "Start  Learning" : "Enroll Now"}

                      <i className="fa-solid fa-arrow-right-long -rotate-45 ml-2" />
                    </button>
                  </NavLink>
                </div>
              </div>
            ))}


          </div>




          <div className="card-contaner lg:flex justify-center gap-10 mt-12 pb-10">
            <div className="Mastering Blockchain sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
              <img
                src="/digitalMarketing.jpeg"
                alt=""
                className="rounded-lg"
              />
              <div>
                <h1 className="text-xl">
                  <span className="text-2xl font-bold ">
                    {" "}
                    Digital Sales and Marketing Internship Program
                  </span>
                </h1>
                <div className="data flex justify-between mt-8 text-sm">
                  <p className="font-semibold">
                    <i className="fa-solid fa-person-chalkboard text-slate-400" />
                    Lessons: 15
                  </p>
                  <p className="font-semibold">
                    <i className="fa-solid fa-trophy text-slate-400" />
                    Level: MASTER
                  </p>
                </div>
                <ul className="mt-6">
                  <h1 className="font-bold">Learning Outcomes:</h1>
                  <li>
                    • Develop and implement a comprehensive Digital marketing strategy aligned with your sales goals.
                  </li>
                  <li>
                    • Create high-quality content that engages your target audience and drives conversions.
                  </li>
                </ul>
              </div>
              <div className="flex items-center justify-evenly ">
             
                <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue ">
                  Coming Soon
                </button>
              </div>
            </div>
            <div className="Digital Assets sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
              <img
                src="/blobkchainAndAI.jpg"
                alt=""
                className="rounded-lg "
              />
              <div>
                <h1 className="text-xl">
                  <span className="text-2xl font-bold ">
                    {" "}
                    Blockchain Development and GenAI Internship Program
                  </span>
                </h1>
                <div className="data flex justify-between mt-8 text-sm">
                  <p className="font-semibold">
                    <i className="fa-solid fa-person-chalkboard text-slate-400" />
                    Lessons: 6
                  </p>
                  <p className="font-semibold">
                    <i className="fa-solid fa-trophy text-slate-400" />
                    Level: MASTER
                  </p>
                </div>
                <ul className="mt-6">
                  <h1 className="font-bold">Learning Outcomes:</h1>
                  <li>
                    • Explain the core principles of blockchain technology and its various components.

                  </li>
                  <li>
                    • Design and develop decentralized applications (dApps) using popular blockchain platforms.

                  </li>
                </ul>
              </div>
              <div className="flex items-center justify-evenly">
                <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue ">
                  Coming Soon
                </button>
              </div>
            </div>
            <div className="Blockchain & Cybersecurity sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
              <img
                src="https://fabc.global/assets/tailwind/assets/COURSE 5.png"
                alt=""
                className="rounded-lg "
              />
              <div>
                <h1 className=" text-xl">
                  <span className="text-2xl font-bold ">
                    MASTERING BLOCKCHAIN -TECHNICAL

                    
                  </span>
                </h1>
                <div className="data flex justify-between mt-8 text-sm">
                  <p className="font-semibold">
                    <i className="fa-solid fa-person-chalkboard text-slate-400" />
                    Lessons: 240
                  </p>
                  <p className="font-semibold">
                    <i className="fa-solid fa-trophy text-slate-400" />
                    Level: MASTER
                  </p>
                </div>
                <ul className="mt-6">
                  <h1 className="font-bold">Learning Outcomes:</h1>
                  <li>
                    • Explain the core concepts of blockchain technology and its applications.
                  </li>
                  <li>
                    • Identify and understand the different components of a blockchain network.

                  </li>
                </ul>
              </div>
              <div className="flex items-center justify-evenly ">
                <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue ">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div> */}









        <div className="sec-4 px-5 md:px-12">
      {courseChunks.map((chunk, chunkIndex) => (
        <div
          key={chunkIndex}
          className="card-container flex flex-col md:flex-row justify-center gap-10 mt-12"
        >
          {chunk.map((course) => (
            <div
              key={course.id}
              className="sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-around shadow-md"
            >
           
              <img src={`./assets/Courses${course.image_url}`} alt={course.course_name} className="rounded-lg mb-8" />

              <div>
                <h1 className="text-xl">
                  <span className="text-2xl font-bold">{course.course_name}</span>
                </h1>
                <div className="data flex justify-between mt-8 text-sm">
                  <p className="font-semibold">
                    <i className="fa-solid fa-person-chalkboard text-slate-400 mr-2" />
                    Lessons: {course.total_contents}
                  </p>
                  <p className="font-semibold">
                    <i className="fa-solid fa-trophy text-slate-400 mr-2" />
                    Level: {course.category}
                  </p>
                </div>
                <ul className="mt-6">
                  <h1 className="font-bold">Description</h1>
                  <p className="py-5">{truncateDescription(course.course_description)}</p>
                </ul>
              </div>
              <div className="flex items-center justify-evenly mt-6">
                <NavLink to={`/precourse?id=${course.course_id}`} key={course.id}>
                  <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue">
                    {paymentStatuses[course.course_id] === "Success" || role === "skylift_user"
                      ? "Start Learning"
                      : "Enroll Now"}
                    <i className="fa-solid fa-arrow-right-long -rotate-45 ml-2" />
                  </button>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* Additional static courses can be included below */}
      <div className="card-container lg:flex justify-center gap-10 mt-12 pb-10">
        <div className="Mastering Blockchain sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
          <img src="./assets/Courses/digitalMarketing.jpeg" alt="Digital Sales" className="rounded-lg" />
          <div>
            <h1 className="text-xl">
              <span className="text-2xl font-bold">
                Digital Sales and Marketing Internship Program
              </span>
            </h1>
            <div className="data flex justify-between mt-8 text-sm">
              <p className="font-semibold">
                <i className="fa-solid fa-person-chalkboard text-slate-400" />
                Lessons: 15
              </p>
              <p className="font-semibold">
                <i className="fa-solid fa-trophy text-slate-400" />
                Level: MASTER
              </p>
            </div>
            <ul className="mt-6">
              <h1 className="font-bold">Learning Outcomes:</h1>
              <li>• Develop and implement a comprehensive Digital marketing strategy aligned with your sales goals.</li>
              <li>• Create high-quality content that engages your target audience and drives conversions.</li>
            </ul>
          </div>
          <div className="flex items-center justify-evenly ">
            <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue">
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Digital Assets sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
          <img src="./assets/Courses/blobkchainAndAI.jpg" alt="Blockchain and AI" className="rounded-lg" />
          <div>
            <h1 className="text-xl">
              <span className="text-2xl font-bold">
                Blockchain Development and GenAI Internship Program
              </span>
            </h1>
            <div className="data flex justify-between mt-8 text-sm">
              <p className="font-semibold">
                <i className="fa-solid fa-person-chalkboard text-slate-400" />
                Lessons: 6
              </p>
              <p className="font-semibold">
                <i className="fa-solid fa-trophy text-slate-400" />
                Level: MASTER
              </p>
            </div>
            <ul className="mt-6">
              <h1 className="font-bold">Learning Outcomes:</h1>
              <li>• Explain the core principles of blockchain technology and its various components.</li>
              <li>• Design and develop decentralized applications (dApps) using popular blockchain platforms.</li>
            </ul>
          </div>
          <div className="flex items-center justify-evenly">
            <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue">
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Blockchain & Cybersecurity sec-4 card md:w-[30%] p-6 rounded-lg bg-black/60 mt-6 lg:mt-0 grid content-between gap-5 shadow-md">
          <img
            src="https://fabc.global/assets/tailwind/assets/COURSE 5.png"
            alt="Mastering Blockchain"
            className="rounded-lg"
          />
          <div>
            <h1 className="text-xl">
              <span className="text-2xl font-bold">MASTERING BLOCKCHAIN - TECHNICAL</span>
            </h1>
            <div className="data flex justify-between mt-8 text-sm">
              <p className="font-semibold">
                <i className="fa-solid fa-person-chalkboard text-slate-400" />
                Lessons: 240
              </p>
              <p className="font-semibold">
                <i className="fa-solid fa-trophy text-slate-400" />
                Level: MASTER
              </p>
            </div>
            <ul className="mt-6">
              <h1 className="font-bold">Learning Outcomes:</h1>
              <li>• Explain the core concepts of blockchain technology and its applications.</li>
              <li>• Identify and understand the different components of a blockchain network.</li>
            </ul>
          </div>
          <div className="flex items-center justify-evenly ">
            <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue">
              Coming Soon
            </button>
          </div>
        </div>
      </div>
    </div>



        {/* <div class="bg-FABcBlue lg:py-8 p-4 mt-10 text-center text-white font-bold md:text-xl lg:text-3xl">
      <h1>YOUR FUTURE IN WEB3 BEGINS HERE.</h1>
      <h1>REACH OUT
          <span class="text-FABcYellow">NOW</span>
      </h1>
  </div> */}


        {/* <div className="contactUs-container py-10 pb-20 lg:px-12 lg:flex items-center justify-center gap-40">
    <div className="form p-7 lg:p-10  rounded-xl shadow-xl text-FABcGray mx-2 lg:mx-0 lg:w-[35%] bg-[#cce7ed] ">
      <h1 className="font-bold  text-2xl">Get in touch with us!</h1>
      <div style={{ color: "red" }} id="success_message" />
      <form id="contact_us_form" method="post">
        <input
          type="text"
          placeholder="Name"
          name="name"
          className="bg-transparent outline-0 border-0 border-b-2 p-3 border-FABcBlue w-[100%] mt-6"
          required=""
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          className="bg-transparent outline-0 border-0 border-b-2 p-3 border-FABcBlue w-[100%] mt-14"
          required=""
        />
        <textarea
          name="Text1"
          cols={40}
          rows={5}
          className="bg-transparent outline-0 border-0 border-b-2 p-3 border-FABcBlue w-[100%] mt-14"
          placeholder="Message"
          required=""
          defaultValue={""}
        />
        
        <input
          type="submit"
          className="w-[100%] text-white bg-FABcBlue rounded-lg py-3 mt-14 hover:bg-FABcYellow hover:"
          defaultValue="Send"
        />
      </form>
    </div>
    <div className="lg:w-4/12 mt-6 lg:mt-0">
      <img
        src="https://fabc.global/assets/tailwind/assets/Mesa de trabajo 1 1.png"
        alt="..."
        className="w-[100%]"
      />
    </div>
  </div> */}


        <Footer />
      </div>
    </div>
  )
}