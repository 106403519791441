

import { useState, useEffect } from "react";

var createGuest = require("cross-domain-storage/guest");

function HomePage() {
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
  
    var bazStorage = createGuest("https://edu.d5art.com");
  //  var bazStorage = createGuest("http://localhost:3000");



    bazStorage.get("edu_email", function (error, value) {
      if (error) {
        console.error("Error retrieving token from localhost:3000:", error);
      } else {
        console.log("Token retrieved from cross-domain storage:", value);

        setAuthToken(value);
      }
    });
  }, []);

  
  return (
    <div className="App">
      <header className="App-header">
        <h1>Home Page (staging server)</h1>
        {authToken ? (
          <p>Logged in! Auth Token: {authToken}</p>
        ) : (
          <p>Not logged in. No token found.</p>
        )}
      </header>
    </div>
  );
}

export default HomePage;



