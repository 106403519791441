

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Assessment() {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [typedAnswers, setTypedAnswers] = useState({});
    const [userEmail, setUserEmail] = useState('');
    const [validatorEmail, setValidatorEmail] = useState('');
    const [questionSetName, setQuestionSetName] = useState('');
    const [questionSetId, setQuestionSetId] = useState('');
    const [userAnswers, setUserAnswers] = useState([]);
    const [questionSets, setQuestionSets] = useState([]);
    const [formattedTime, setFormattedTime] = useState('00:00:00');
    const [remainingTime, setRemainingTime] = useState(0);
    const [isMessage, setIsMessage] = useState(false);

    const user_email = useSelector((state) => state.seeker_email);
    const location = useLocation();
    const { skill } = queryString.parse(location.search);

    const handleAnswerSelection = (optionIndex) => {
        setSelectedOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[questionIndex] = optionIndex;
            return updatedOptions;
        });
    };

    const handleTypedAnswerChange = (event) => {
        const { name, value } = event.target;
        setTypedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [name]: value,
        }));
    };

    const handleNextQuestion = () => {
        setQuestionIndex((prevIndex) => prevIndex + 1);
    };

    const handleSubmission = () => {
        const answers = questionSets.map((question, index) => {
            const points = question.type === 'type3' ? 5 : 1;
            const answer = question.type === 'type1' ? question.options[selectedOptions[index]] : typedAnswers[`typedAnswer_${question.id}`];
            return { type: question.type, answer, question: question.question, points , id: question.id, options: question.options};
        });
    
        const data = {
            Set_name: questionSetName,
            question_set_id: questionSetId,
            answers: answers,
            seeker_email: userEmail,
            validator_email: validatorEmail,
        };
    
        axios.post('https://ex.d5art.com/api/user_answers_set', data)
            .then(response => {
                console.log('Successfully posted data:', response.data);
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    };
    
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/fetchQuestions?email=${user_email}&skill_pref=${skill}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch questions');
                }
                const data = await response.json(); // Parse response body as JSON
                console.log("2222", data);
                setQuestionSetId(data.id);
                setValidatorEmail(data.userEmail);
                const parsedData = data.data; // Already parsed as JSON
                console.log('1111', parsedData);
                if (Array.isArray(parsedData)) {
                    setQuestionSets(parsedData);
                    setUserEmail(user_email);
                    setQuestionSetName(skill);
                } else {
                    console.log('Data is not an array');
                }
            } catch (error) {
                console.log('Failed to fetch questions');
                console.error('Error fetching questions:', error);
            }
        };
    
        fetchQuestions();
    }, [skill, user_email]);
    
    
    useEffect(() => {
        const totalTime = questionSets.length * 60;
        let timer = totalTime;

        const interval = setInterval(() => {
            if (timer > 0) {
                timer--;
                const hours = Math.floor(timer / 3600);
                const minutes = Math.floor((timer % 3600) / 60);
                const seconds = timer % 60;
                setFormattedTime(`${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
                setRemainingTime(timer);
            } else {
                clearInterval(interval);
                handleSubmission();
                toast.success('Your answers have been saved automatically as time is up!');

                window.location.href = '/RMS/Seeker/SeekerDashboard';


            }
        }, 1000);

        return () => clearInterval(interval);
    }, [questionSets]);

    const handleAnchorClick = (index) => {
        setQuestionIndex(index);
    };

    const handleMessage = () => {
        setIsMessage(!isMessage);
        handleSubmission();
        toast.success('Your answers have been saved successfully!');

    };

    return (
        <div className='text-white bg-[#1A1919] 2xl:h-screen font-poppins  flex justify-center' style={{backgroundImage: 'url("/assets/BG/FABC.png")'}}>


            <div className='w-[85%] py-10'>
                <nav class=" lg:py-5 lg:flex items-center justify-between">
                    <div class="content1 lg:w-[60%]">
                        <h1 className="text-xl font-bold">Question Set Name
                            <span className='text-[#31989B]'> {skill}</span>
                        </h1>
                        <div class="content-1 mt-5">
                            <h1 class="font-play text-lg font-bold text-[#969696]">Instructions:</h1>
                            <p class="px-5 py-3 rounded-xl text-xs bg-[#101010] mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate </p>
                        </div>
                    </div>
                    <div class="content2 lg:w-[30%] mt-10 lg:mt-0">
                        <div className='flex'>
                            <div>
                                
                                <div><img className='w-[100%]' src='/assets/Others/Rectangle.png'></img>
                                    <p className='-translate-y-[60px] text-[35px] flex justify-center font-play px-5'>{formattedTime}</p>
                                </div>
                            </div>
                            <div>
                                <img src='/assets/Others/avatar2.png' ></img>
                            </div>
                        </div>
                    </div>
                </nav>
                <div class="lg:flex justify-between">
                    <div class="lg:w-[60%] mb-10">
                        <div class="">
                        {questionSets.map((question, index) => (
                            <div key={index} className="content-2 mt-5" id={`div${question.id}`} style={{ display: questionIndex === index ? 'block' : 'none' }}>
                                <h1 className="text-xl font-bold mb-5">Question: {question.id} <span className='ml-5 text-[#FFBA00] border border-[#FFBA00] rounded-full px-3 py-0'>Points: {question.type === 'type3' ? '5' : '1'}</span></h1>
                                <p>{question.question}</p>
                                
                                <div className="rounded-2xl  py-2 grid content-around">
                                <p className="text-xs text-[#BBBBBB] text-end">(Choose All the Correct Options)</p>
                                    {question.type === 'type1' && (

                                        question.options.map((option, optionIndex) => (
                                            <div>
                                          
                                            <div key={optionIndex} className="py-4 flex">
                                            
                                                <div className='flex items-center'>
                                                    <button
                                                        className={`w-3 h-3 ${selectedOptions[questionIndex] === optionIndex ? 'bg-cyan-400 border-cyan-400' : 'bg-none border'}`}
                                                        onClick={() => handleAnswerSelection(optionIndex)}
                                                    ></button>
                                                </div>
                                                <span className="ml-4">{option}</span>
                                            </div>
                                            </div>
                                        ))
                                    )}
                        
                                    <div className={`transition-height duration-300 ease-in-out ${question.type === 'type2' || question.type === 'type3' ? 'h-48' : 'h-0'} overflow-hidden`}>
                                        {question.type === 'type2' ? (
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                type="text"
                                                name={`typedAnswer_${question.id}`}
                                                value={typedAnswers[`typedAnswer_${question.id}`] || ''}
                                                onChange={handleTypedAnswerChange}
                                                className="bg-black text-white p-5 rounded-2xl mt-2 w-[100%]  outline-none"
                                                placeholder="Describe your answer"
                                            />
                                        ) : (
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                name={`typedAnswer_${question.id}`}
                                                value={typedAnswers[`typedAnswer_${question.id}`] || ''}
                                                onChange={handleTypedAnswerChange}
                                                className="bg-black text-white p-2 rounded-2xl mt-2 w-[100%]  outline-none"
                                                placeholder="Write the Condition / Comments for the code"
                                            />
                                        )}
                                    </div>
                        
                                    {index === questionSets.length - 1 ? (
                                        <div className='flex justify-center py-12'>
                                            <button className='px-10 py-2 text-xl text-white font-bold rounded-2xl bg-gradient-to-r from-[#0087A5] to-[#0E2DA7]' onClick={handleMessage}>
                                                Submit
                                            </button>
                                        </div>
                                    ) : (
                                        <div className='flex justify-center py-12'>
                                            <button className='px-10 py-2 text-xl text-white font-bold rounded-2xl bg-gradient-to-r from-[#0087A5] to-[#0E2DA7]' onClick={handleNextQuestion}>
                                                Save & Next
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        
                            {isMessage && (
                                <div className="z-10 w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm animate__animated animate__fadeInUp">
                                    <div className='lg:w-[40%] bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0'>
                                        <div className='flex justify-end'><i  onClick={handleMessage}></i></div>
                                        <div>
                                            <p className='text-[#F29D38] font-bold text-center text-2xl pt-5' >Validation </p>
                                            <div className='flex pb-5 pt-3 lg:pt-0'>
                                                <div className='w-[35%]'>
                                                    <img src='/assets/Mascots/image 90.png'></img>
                                                </div>
                                                <div className='w-[65%] pr-7 lg:pt-7'>

                                                    <p>Your response get submitted and wait for the result that needs to validate by the validator </p>
                                                    <p className='mt-2'>It approximately takes 2 - 3 working day</p>
                                                
                                                <a href="/RMS/Seeker/SeekerDashboard">
                                                   <div className='flex justify-center'> <button className='font-play  mt-5 text-md px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white' href="/RMS/Seeker/SeekerDashboard">
                                                    My Assessment
                                                </button>
                                                </div>
                                                </a>
                                                    </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="lg:w-[30%] h-auto mt-5 flex justify-center mb-10">
                        <div class="w-full">
                            <div class="mt-5 bg-FABcBlue rounded-xl drop-shadow-lg px-2 py-3 flex-row justify-center bg-white/5">
                                <h1 className='text-white text-xl flex justify-center'>Question</h1>
                                <div class="mt-5 bg-black rounded-xl p-5 text-cyan-500">
                                    <div class="flex justify-around mb-5">
                                        {questionSets.map((question, index) => (
                                            <a key={index} href='#' onClick={() => handleAnchorClick(index)}>
                                                <button class="px-4 py-4 bg-black focus:border-b focus:border-cyan-500">{index + 1}</button>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        </div>
    );
}
