import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Moralis from 'moralis';
import LoginHeader from '../Layout/LoginHeader';
import Footer from '../../../Layout/Footer';import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import Loader from '../loaderdemo';
// import ErrorPage from '../please_wait_page';

export default function UserDetails() {
    const [nftItems, setNftItems] = useState([]);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);

    const location = useLocation();

    const fetchData = async () => {
        const params = new URLSearchParams(location.search);
        const tokenId = params.get('tokenId');
        console.log('Token ID:', tokenId);

        try {
            console.log('Fetching data...');
            await Moralis.start({
                apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE"

            });
            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": "0x13882",
                "format": "decimal",
                "address": "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec"
            });

           
            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
            console.log('IPFS Image:', ipfsImage);
            const temp = response.raw.result
            console.log('Response:', response.raw.result);
            setNftItems(response.raw.result);


            const filteredItems = tokenId
                ? temp.filter(item => item.token_id === tokenId)
                : [];
            console.log('Filtered Items:', filteredItems);

            setItems(filteredItems);

            setLoading(false);
        } catch (error) {
            if (error && error.code === 401) {
                setLoading(true); // Show loader for 401 error code
            } else {
                setLoading(false);
            }

            console.error('Error fetching data:', error);
        }
    };




    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };


    useEffect(() => {
        const fetchDataAndItem = async () => {
            await fetchData();
        };
        fetchDataAndItem();
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // if (loading) {
    //     return <Loader />;
    // }

    return (
        <div className='font-poppins bg-[#373737] '>
            <LoginHeader />

            {/* Conditional rendering for loader and blank page when their is no response from moralis */}
            {
            // loading ? (
            //     <Loader />
            // ) : items.length === 0 ? (
            //     <ErrorPage />

            // ) : 
            (

                items.map((item, index) => {
                    const metadata = JSON.parse(item.metadata);
                    const ipfsImage = metadata.image.split('://')[1];

                    

     

                    const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
                    const skills = skillsAttributes.map(attr => attr.value);
                    console.log('skills', skills);

                    const skillsdescriptionAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_description');
                    const skills_description = skillsdescriptionAttributes.map(attr => attr.value);
                    console.log('skills_description', skills_description);

                    const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                    const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                    console.log('prefered_roll', prefered_rolls);

                    const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
                    

                    const experience_roleAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_role');
                    const experience_roles = experience_roleAttributes.map(attr => attr.value);

                    const first_experience_role = experience_roles.slice(0)[0];
                    const middle_experience_role = experience_roles.slice(1)[0];
                    const last_experience_role = experience_roles.slice(-1)[0];


                    const projectAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project');
                    const project = projectAttributes.map(attr => attr.value);
                    console.log('projectAttributes', project);

                    const project_languageAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project_language_used');
                    const project_language_used = project_languageAttributes.map(attr => attr.value);
                    console.log('project_language_used', project_language_used);

                    const projectDescription = metadata.attributes.filter(attr => attr.trait_type === 'about_project');
                    const project_description = projectDescription.map(attr => attr.value);
                    console.log('projectAttributes', project_description);


                  

                    const certificate_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'certificate');
                    const certificate = certificate_Attributes.map(attr => attr.value);
                    console.log("certificate", certificate);


                    const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'experience');
                    const experience = experience_Attributes.map(attr => attr.value);
                    console.log('experience123123', experience);


                    const edu_sslc_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_inst');
                    const edu_sslc_inst = edu_sslc_inst_Attributes.map(attr => attr.value);

                    const edu_sslc_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_perc');
                    const edu_sslc_perc = edu_sslc_perc_Attributes.map(attr => attr.value);

                    const edu_sslc_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_year');
                    const edu_sslc_year = edu_sslc_year_Attributes.map(attr => attr.value);

                    const edu_sslc_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_description');
                    const edu_sslc_desc = edu_sslc_desc_Attributes.map(attr => attr.value);

                    //
                    const edu_hsc_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_inst');
                    const edu_hsc_inst = edu_hsc_inst_Attributes.map(attr => attr.value);

                    const edu_hsc_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_perc');
                    const edu_hsc_perc = edu_hsc_perc_Attributes.map(attr => attr.value);

                    const edu_hsc_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_year');
                    const edu_hsc_year = edu_hsc_year_Attributes.map(attr => attr.value);

                    const edu_hsc_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_description');
                    const edu_hsc_desc = edu_hsc_desc_Attributes.map(attr => attr.value);

                    //

                    const edu_cgpa_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_inst');
                    const edu_cgpa_inst = edu_cgpa_inst_Attributes.map(attr => attr.value);

                    const edu_cgpa_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_perc');
                    const edu_cgpa_perc = edu_cgpa_perc_Attributes.map(attr => attr.value);

                    const edu_cgpa_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_year');
                    const edu_cgpa_year = edu_cgpa_year_Attributes.map(attr => attr.value);

                    const edu_cgpa_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_description');
                    const edu_cgpa_desc = edu_cgpa_desc_Attributes.map(attr => attr.value);

                    //

                    const edu_mba_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_inst');
                    const edu_mba_inst = edu_mba_inst_Attributes.map(attr => attr.value);

                    const edu_mba_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_perc');
                    const edu_mba_perc = edu_mba_perc_Attributes.map(attr => attr.value);

                    const edu_mba_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_year');
                    const edu_mba_year = edu_mba_year_Attributes.map(attr => attr.value);

                    const project_external_url_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'project_external_url');
                    const project_external_url = project_external_url_Attributes.map(attr => attr.value);

                    const seeker_name_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'seeker_name');
                    const seeker_name = seeker_name_Attributes.map(attr => attr.value);

                    const percentage = 50;

                   

                    const redirect = (url) => {
                        if (!url.startsWith('http://') && !url.startsWith('https://')) {

                            url = 'https://' + url;
                        }

                        window.location.href = url;
                    };

                    return (
                        <React.Fragment key={index}>
                            <div className='Details flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                <div className='w-[90%] md:flex py-5 md:py-10'>
                                    <div className='md:w-[50%] p-5 md:p-10'>
                                        <h1 className='font-bold'>Hi!</h1>
                                        <h1 className='font-bold text-3xl mt-5'>My Name Is {seeker_name}</h1>
                                        <p className='text-sm mt-5 font-bold'>{last_experience_role}</p>
                                        <p className='text-xs mt-5'>{metadata.description}</p>
                                        <div className='flex gap-5 mt-5'>
                                            {/* <i className='fa-brands fa-linkedin text-3xl'></i>
                                            <i className='fa-brands fa-x-twitter text-3xl'></i> */}

                                        </div>
                                    </div>
                                    <div className='md:w-[50%]'>
                                        <div>
                                            {/* <img src='/assets/User1.png'></img> */}
                                            <img src=  {getImageFromMetadata(item.metadata)}></img>

                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='MySkills flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                <div className='w-[85%] flex-col py-10'>
                                    <div className='md:flex'>
                                        <div className='md:w-[50%] md:p-10'>
                                            <h1 className='text-3xl font-bold'>My skills 🚀 </h1>
                                            <p className='text-xs mt-5'>{ }</p>

                                        </div>

                                        <div className='md:w-[50%] md:px-20 py-10'>
                                            {skillsAttributes.map((attr, index) => {
                                                try {
                                                    const skillDetails = JSON.parse(attr.value);

                                                    if (Array.isArray(skillDetails) && skillDetails.length > 0) {
                                                        return (
                                                            <div key={index}>
                                                                {skillDetails.map((skill, subIndex) => (
                                                                    <div key={subIndex}>
                                                                        <p className='uppercase flex justify-between'>
                                                                            <span>{skill.skill}</span>
                                                                            <span className='mr-10'>{skill.percentage}%</span>
                                                                        </p>
                                                                        <div className='py-3'>
                                                                            <div className='h-2 rounded w-full bg-gray-100'>
                                                                                <div className='h-2 bg-yellow-500 rounded' style={{ width: `${skill.percentage}%` }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        );
                                                    }
                                                } catch (error) {
                                                    console.error('Error parsing skill data:', error);
                                                    return null; // Skip rendering if parsing fails
                                                }
                                                return null; // Skip rendering if skillDetails is null or empty
                                            })}
                                        </div>
                                    </div>


                                    <div className='md:px-10'>
                                        <h1 className='text-2xl font-bold'>Certifications 🏅</h1>
                                        <div className='flex gap-5 md:py-10'>
                                            {certificate.map((certDetails, index) => {
                                                const certificateDetails = JSON.parse(certDetails);
                                                if (certificateDetails && certificateDetails.name && certificateDetails.source) {
                                                    return (
                                                        <div key={index} className='md:w-[23%] p-5'>
                                                            <div className='flex justify-center'>
                                                                <div className='bg-white rounded-full'>
                                                                    <img src='/assets/Others/badge.png' alt='Certificate Badge' />
                                                                </div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <p className='text-lg mt-5'>{certificateDetails.name}</p>
                                                                <p className='text-xs mt-5'>Certificate obtained via, {certificateDetails.source}.</p>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    console.error('Certificate data is missing required fields:', certificateDetails);
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='EXPERIENCES. flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                <div className='w-[90%] py-7'>
                                    <h1 className='text-3xl font-bold text-center mb-10'>EXPERIENCES ⏳ </h1>
                                    {experience.map((exp, index) => {
                                        try {
                                            const experienceDetails = JSON.parse(exp);
                                            if (experienceDetails && experienceDetails.companyname && experienceDetails.from && experienceDetails.to && experienceDetails.role &&
                                                experienceDetails.companyname.trim() !== '' && experienceDetails.from.trim() !== '' && experienceDetails.to.trim() !== '' && experienceDetails.role.trim() !== '') {
                                                return (
                                                    <div key={index} className='flex'>
                                                        <div className='w-[50%] flex justify-end px-10'>
                                                            <div className='grid content-around'>
                                                                <p className='text-end'>{experienceDetails.role}<br />{experienceDetails.companyname}</p>
                                                            </div>
                                                        </div>
                                                        <div className='h-20 w-[1px] bg-gray-100'>
                                                            <div className='h-full w-3 grid content-around -translate-x-1.5'>
                                                                <div className='p-1.5 rounded-full bg-yellow-500'></div>
                                                            </div>
                                                        </div>
                                                        <div className='w-[50%] flex justify-start px-10'>
                                                            <div className='grid content-around'>
                                                                <p>{experienceDetails.from} - {experienceDetails.to}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        } catch (error) {
                                            console.error('Error parsing experience data:', error);
                                            return null; // Skip rendering if parsing fails
                                        }
                                    })}
                                </div>
                            </div>

                            <div className='EDUCATION.. flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                <div className='w-[90%] md:py-10'>
                                    <h1 className='text-3xl font-bold text-center'>EDUCATION 🎓</h1>
                                    <div className='md:flex justify-center py-10'>
                                        {edu_sslc_inst && edu_sslc_year && edu_sslc_perc && edu_sslc_desc && edu_cgpa_inst && edu_cgpa_year && edu_cgpa_perc && edu_cgpa_desc && (
                                            <div className='md:w-[80%] px-5 md:px-10'>
                                                <h1>Completed the most recent Education at <font color="yellow">{edu_sslc_inst}</font> in the year <font color="yellow">{edu_sslc_year}</font> and secured <font color="yellow">{edu_sslc_perc}%</font>.</h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{edu_sslc_desc}</p>

                                                 {/* <h1 className='mt-14'>Completed College at <font color="yellow">{edu_cgpa_inst}</font> in the year <font color="yellow">{edu_cgpa_year}</font> and secured <font color="yellow">{edu_cgpa_perc}%</font>.</h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{edu_cgpa_desc}</p>  */}
                                            </div>
                                        )}

                                        {/* {edu_hsc_inst && edu_hsc_year && edu_hsc_perc && edu_hsc_desc && edu_mba_inst && edu_mba_year && edu_mba_perc && (
                                            <div className='md:w-[40%] px-5 md:px-10 mt-5 md:mt-0'>
                                                <h1>Completed Higher Secondary School at <font color="yellow">{edu_hsc_inst}</font> in the year <font color="yellow">{edu_hsc_year}</font> and secured <font color="yellow">{edu_hsc_perc}%</font>.</h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{edu_hsc_desc}</p>


                                            </div>
                                        )} */}

                                    </div>
                                </div>
                            </div>

                            
                            <div className='Recent project flex justify-center bg-[#191919] text-white pb-10' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                <div className='w-[90%] py-10'>
                                    <h1 className='text-3xl font-bold text-center uppercase text-gray-300'>Recent project 💻</h1>
                                    <Slider {...settings}>
                                        <div className='part-1 md:flex justify-center py-10'>
                                            <div className='md:w-[100%] flex flex-row gap-7'>
                                                {project.map((project, index) => (
                                                    (project && project.trim() !== '' && index === 0) && (
                                                        <div key={index} className='flex md:flex justify-around gap-5'>
                                                            <div className='w-[50%] gap-4 grid content-between'>
                                                                <h1 className='font-bold text-2xl'>{project}</h1>
                                                                <p className='italic text-xm 2xl:text-base'>{project_description}</p>
                                                                <div>
                                                                    <button className='bg-white/10 px-1' onClick={() => redirect(project_external_url[index])}>
                                                                        <i className="fa-solid fa-arrow-right-long text-2xl -rotate-45 "></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='w-[30%]'>
                                                                <div><img src='/assets/Others/web-development-programmer.png' alt='Project screenshot' /></div>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                        {project.slice(1).map((project, index) => (
                                            <div key={index} className='part-2 md:flex justify-center py-10'>
                                                <div className='md:w-[100%] flex flex-row gap-7'>
                                                    {(project && project.trim() !== '') && (
                                                        <div className='flex md:flex justify-around gap-5'>
                                                            <div className='w-[50%] gap-4 grid content-between'>
                                                                <h1 className='font-bold text-2xl'>{project}</h1>
                                                                <p className='italic text-xm 2xl:text-base'>{project_description}</p>
                                                                <div>
                                                                    <button className='bg-white/10 px-1' onClick={() => redirect(project_external_url[index + 1])}>
                                                                        <i className="fa-solid fa-arrow-right-long text-2xl -rotate-45 "></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='w-[30%] '>
                                                                <div><img src='/assets/Others/web-development-programmer.png' alt='Project screenshot' /></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                    </Slider>

                                </div>
                            </div>





                            

                        </React.Fragment>
                    );
                })
            )}
             <Footer />
        </div>
    )
}