import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../Style/App.css';

export default function ResetPassword() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [moveImage, setMoveImage] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);

    useEffect(() => {
        const moveInterval = setInterval(() => {
            setMoveImage((prevMove) => !prevMove);
        }, 1500);

        return () => {
            clearInterval(moveInterval);
        };
    }, []);



    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const handleSubmit = () => {
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            handleFlip(); // Flip after the spinner animation
        }, 1000); // 1-second delay for the spinner
    };

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className='relative min-h-screen grid content-center font-bricolagegrotesque bg-[#101010] bg-cover bg-center' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
            <div className="flip-card-forget mx-auto z-10 text-white">
                <div className={`flip-card-inner-forget ${isFlipped ? 'flipped' : ''}`}>
                    <div className="flip-card-inner-forget">
                        <div className="flip-card-front-forget bg-black/60 p-7 rounded-2xl grid content-center shadow-md shadow-sky-500 border-t border-sky-700">
                            <div className='flex justify-center font-semibold text-white/60 hover:text-white duration-500 text-lg'><a href='/seeker/registration'>Reset Password</a></div>

                            <div className="relative mt-10">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    className="border-b border-white/50 hover:border-white duration-500 w-[100%] bg-transparent pb-3 pl-3 placeholder:text-white placeholder:text-sm outline-none"
                                    placeholder="Password"
                                />
                                <FontAwesomeIcon
                                    icon={passwordVisible ? faEyeSlash : faEye}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                />
                            </div>

                            <div className="relative mt-5">
                                <input
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    className="border-b border-white/50 hover:border-white duration-500 w-[100%] bg-transparent pb-3 pl-3 placeholder:text-white placeholder:text-sm outline-none"
                                    placeholder="Confirm Password"
                                />
                                <FontAwesomeIcon
                                    icon={confirmPasswordVisible ? faEyeSlash : faEye}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
                                    onClick={toggleConfirmPasswordVisibility}
                                />
                            </div>

                            <div className="flex justify-center mt-7">


                                <button
                                    className='flex justify-center items-center gap-2 border px-5 py-1.5 h-10 w-32 border-sky-700 rounded-full hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3]'
                                    onClick={handleSubmit}
                                >
                                    {isSubmitting ? (
                                        <div className='w-5 h-5 border-2 border-t-sky-400 border-white/15 rounded-full animate-spin'></div>
                                    ) : (
                                        <p>Submit</p>
                                    )}
                                </button>

                            </div>
                        </div>
                        <div className="flip-card-back-forget p-5 bg-white grid content-center rounded-2xl">
                            <img src="/assets/Others/check-mark-verified.gif" className='mx-auto w-[50%]'></img>
                            <p className="text-black text-center">Password changed</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute w-full h-full flex justify-center z-0'>
                <div className='w-[100%] flex justify-between h-full'>
                    <div className='h-full md:w-[50%] flex justify-center items-start'>
                        <img
                            style={{
                                transition: 'transform 1s ease-in-out',
                                transform: moveImage ? 'translateY(65px)' : 'translateY(0)',
                            }}
                            src='./assets/Others/Ellipse1.png'
                            alt='circle1'
                            className='md:w-[30%]'
                        />
                    </div>
                    <div className='h-full flex items-end'
                        style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translateY(-65px)' : 'translateY(0)',
                        }}
                    >
                        <img src='./assets/Others/Ellipse2.png' alt='circle2' className='w-[80%]' />
                    </div>
                </div>
            </div>
        </div>
    );
}
