// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import Select from 'react-select';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const JobSeekerRegistration = () => {
//     const [passwordsMatch, setPasswordsMatch] = useState(true);
//     const [passwordVisible, setPasswordVisible] = useState(false);
//     const [isConnected, setIsConnected] = useState(false); // Track wallet connection
//     const [personalInfo, setPersonalInfo] = useState({
//         name: '',
//         email: '',
//         country: '',
//         state: '',
//         countryCode: '',
//         mobileNumber: '',
//         dob: '',
//         avatar: './assets/image 90.png',
//         password: '',
//         confirmpassword: '',
//         walletAddress: '',
//     });

//     const countryOptions = [
//         // Add country options here
//         { label: 'United States', value: 'US', country_flag: '🇺🇸' },
//         { label: 'Canada', value: 'CA', country_flag: '🇨🇦' },
//         // Add more countries as needed
//     ];

//     const filteredStateOptions = [
//         // Add state options here, filtered by selected country
//         { name: 'California', value: 'CA' },
//         { name: 'New York', value: 'NY' },
//         // Add more states as needed
//     ];

//     const customStyles = {
//         control: (provided) => ({
//             ...provided,
//             backgroundColor: 'black',
//             color: 'white',
//             border: 'none',
//         }),
//         singleValue: (provided) => ({
//             ...provided,
//             color: 'white',
//         }),
//     };

//     const handleInputData = (key, field, value) => {
//         setPersonalInfo((prevState) => ({
//             ...prevState,
//             [field]: value,
//         }));

//         // Password matching check
//         if (field === 'confirmpassword' || field === 'password') {
//             setPasswordsMatch(personalInfo.password === value);
//         }
//     };

//     const handleTogglePasswordVisibility = () => {
//         setPasswordVisible(!passwordVisible);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
        
//         // Form validation
//         if (!personalInfo.passwordsMatch) {
//             alert('Passwords do not match!');
//             return;
//         }

//         // Submit form data to backend API
//         try {
//             const response = await fetch('/api/register', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(personalInfo),
//             });

//             if (!response.ok) {
//                 throw new Error('Registration failed');
//             }

//             const data = await response.json();
//             console.log('Registration successful:', data);
//             alert('Registration successful!');
//         } catch (error) {
//             console.error('Error:', error);
//             alert('Registration failed!');
//         }
//     };

//     const handleWalletLogOut = () => {
//         setIsConnected(false);
//         // Add wallet logout logic
//     };

//     const toggleModal = () => {
//         // Logic to toggle the modal
//     };

//     return (



//       <div className='bg-[#101010] text-white h-screen font-poppins bg-cover' style={{ backgroundImage: `url(./assets/Loginbg.png)` }}>
//       <Toaster />
//       {isLoading1 && (
//           <div className=''>
//               <div className='z-100'>
//                   <Loading isVisible1={isLoading1} />
//               </div>
//           </div>
//       )}
//       {isOpen && (

//           <div className=''>
//               <div className='z-100'>
//                   <Spinners isVisible={isLoading} />
//               </div>
//               <div className='px-7 md:px-10 rounded-2xl z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 overflow-y-auto'>
//                   <div className='md:w-[15%] flex justify-center md:justify-end'>
//                       <img src='/assets/Others/LeftSide.png' className='rotate-90 md:rotate-0'></img>
//                   </div>
//                   <div className={`${isTransformed ? 'md:w-[70%]' : 'border-0 w-0  '} z-10  backdrop-blur-sm bg-transparent transform-transition duration-1000 ease-out-in rounded-3xl`}>
//                       <div className='flex justify-center py-4'>
//                           <p className='text-2xl text-white'>Login As</p>
//                       </div>
//                       <div>
//                           <div className=' md:px-10 w-[100%] flex flex-col md:flex-row justify-center gap-2'>
//                               <div className='group md:w-[20%] border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  group-hover:transition-transform duration-500 ' >
//                                   <div className='flex justify-center py-5 md:py-2 text-white '>
//                                       <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? 'hidden' : ''}`} onClick={(e) => {
//                                           e.preventDefault();
//                                           handleRedirectSeeker();
//                                           // handleAnchorClick('div2'); 
//                                       }}>Seeker </a>
//                                       <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? '' : 'hidden'}`} id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} onClick={(e) => {
//                                           e.preventDefault();
//                                           handleRedirectRecruiter();
//                                           //handleAnchorClick('div1'); 

//                                       }}>Recruiter </a>
//                                   </div>
//                               </div>
//                               <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} >
//                                   <div className='flex justify-end '>
//                                       <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                                   </div>
//                                   <form onSubmit={handleSubmit5}>
//                                       <div className='flex justify-center py-2 text-white'>
//                                           <p>Recruiter Login</p>
//                                       </div>
//                                       <div className='flex flex-col gap-3 px-7 md:px-12 mt-3 '>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                               <div className='w-[98%]'><input className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full' placeholder='Enter your mail ID'
//                                                   required
//                                                   onChange={(e) => handleInputChange8('recruiterLogin', 'email', e.target.value)}></input>
//                                               </div>
//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                               <div className='w-[98%]'><input
//                                                   type={passwordVisible ? 'text' : 'password'}
//                                                   className='bg-black bg-opacity-5 text-center text-white outline-none py-2 w-[95%] '
//                                                   placeholder='Enter your Password'
//                                                   required
//                                                   onChange={(e) => handleInputChange8('recruiterLogin', 'password', e.target.value)}></input>
//                                                   <i
//                                                       className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
//                                                       onClick={handleTogglePasswordVisibility}
//                                                   ></i>
//                                               </div>
//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtpRecruiter}>Forget password</button></div>

//                                           <div className='flex justify-center mt-3'>
//                                               <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
//                                                   handleClick2();


//                                               }}>
//                                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                               </button>

//                                           </div>

//                                           <div className='flex justify-center '>
//                                               <p className='text-white font-bold text-sm'>or</p>
//                                           </div>

//                                           <div className="flex flex-col md:flex-row justify-center gap-2 md:pl-5">
//                                               <div className='flex justify-center'>
//                                                   <div onClick={handleRecruiterWalletLogin}>
//                                                       <w3m-button
//                                                           balance="hide" />
//                                                   </div>

//                                                   <>
//                                                       {isConnected && (
//                                                           <button onClick={handleWalletLogOut}>
//                                                               <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
//                                                           </button>
//                                                       )}
//                                                   </>

//                                               </div>

//                                               <div className='flex justify-center'>
//                                                   <div className="md:w-[1px] md:h-10 bg-white/50"></div>
//                                               </div>
//                                               <div className='flex justify-center'>
//                                                   <div className='flex justify-center overflow-hidden rounded-full' onClick={handleGoogleSignIn_recruiter}>
//                                                       <GoogleLogin
//                                                           clientId={clientId}
//                                                           buttonText="Signin as Recruiter"
//                                                           onSuccess={handleGoogleSignIn_recruiter}
//                                                           onFailure={onFailure}
//                                                           cookiePolicy={"single_host_origin"}
//                                                           isSignedIn={false}
//                                                           prompt="select_account"

//                                                       />
//                                                   </div>
//                                               </div>
//                                           </div>

//                                           <div className='flex justify-center'>
//                                               <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' onClick={toggleRecruiter}>Create new account</a>
//                                           </div>
//                                       </div>
//                                   </form>
//                               </div>
//                               <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} >
//                                   <div className='flex justify-end '>
//                                       <button className=' shadow-xl rounded-lg text-xl text-white font-bold  transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                                   </div>
//                                   <form onSubmit={handleSubmit6}>
//                                       <div className='flex justify-center py-2 text-white'>
//                                           <p>Seeker Login</p>
//                                       </div>
//                                       <div className='flex flex-col gap-3 px-7 md:px-12 mt-3'>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                               <div className='w-[98%]'><input className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full' placeholder='Enter your mail ID'
//                                                   required
//                                                   onChange={(e) => handleInputChange9('seekerLogin', 'email', e.target.value)}></input>
//                                               </div>
//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                               <div className='flex w-[98%]'><input
//                                                   type={passwordVisible ? 'text' : 'password'}
//                                                   className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-[95%]'
//                                                   placeholder='Enter your Password'
//                                                   required
//                                                   onChange={(e) => handleInputChange9('seekerLogin', 'password', e.target.value)}></input>
//                                                   <i
//                                                       className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
//                                                       onClick={handleTogglePasswordVisibility}
//                                                   ></i>
//                                               </div>
//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtp}>Forget password</button></div>

//                                           <div className='flex justify-center mt-3'>
//                                               <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
//                                                   handleClick3();

//                                               }} >
//                                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                               </button>
//                                           </div>
//                                           <div className='flex justify-center'>
//                                               <p className='text-white font-bold text-sm'>or</p>
//                                           </div>

//                                           <div className='flex flex-col md:flex-row gap-2 justify-center md:pl-7'>
//                                               <div className='flex justify-center'>
//                                                   <div onClick={handleSeekerWalletLogin}>
//                                                       <w3m-button
//                                                           balance="hide" />
//                                                   </div>
//                                                   <>
//                                                       {isConnected && (
//                                                           <button onClick={handleWalletLogOut}>
//                                                               <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
//                                                           </button>
//                                                       )}
//                                                   </>
//                                               </div>


//                                               <div className='flex justify-center'>
//                                                   <div className="md:w-[1px] md:h-10 bg-white/50"></div>
//                                               </div>

//                                               <div className='flex justify-center '>
//                                                   <div className='flex justify-center rounded-full overflow-hidden' onClick={handleGoogleSignIn_seeker}>
//                                                       <GoogleLogin
//                                                           clientId={clientId}
//                                                           buttonText="Sign-in as Seeker "
//                                                           onSuccess={handleGoogleSignIn_seeker}
//                                                           onFailure={onFailure}
//                                                           cookiePolicy={"single_host_origin"}
//                                                           isSignedIn={false}
//                                                           prompt="select_account"
//                                                       />
//                                                   </div>
//                                               </div>
//                                           </div>

//                                           <div className='flex justify-center'>
//                                               <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' href="#" onClick={togglemodal5}>Create new account</a>

//                                           </div>
//                                       </div>
//                                   </form>
//                               </div>
//                               <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} >
//                                   <div className='flex justify-end '>
//                                       <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                                   </div>
//                                   <form onSubmit={handleSubmitValidator}>
//                                       <div className='flex justify-center py-2 text-white'>
//                                           <p>Validator Login</p>
//                                       </div>
//                                       <div className='flex flex-col gap-3 px-7 md:px-12 mt-3'>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>

//                                               <div className='w-[98%]'><input
//                                                   className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full'
//                                                   placeholder='Enter your mail ID'
//                                                   required
//                                                   onChange={(e) => handleInputChangeValidator('validatorLogin', 'email', e.target.value)} >
//                                               </input>
//                                               </div>

//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex border-b border-gray-500'>
//                                               <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                               <div className='w-[98%] flex'>

//                                                   <input
//                                                       type={passwordVisible ? 'text' : 'password'}
//                                                       className='w-full bg-black bg-opacity-5 text-center text-white outline-none py-2  '
//                                                       placeholder='Enter your Password'
//                                                       required
//                                                       onChange={(e) => handleInputChangeValidator('validatorLogin', 'password', e.target.value)} >
//                                                   </input>
//                                                   <i
//                                                       className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
//                                                       onClick={handleTogglePasswordVisibility}
//                                                   ></i>

//                                               </div>
//                                               <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
//                                               </div>
//                                           </div>
//                                           <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtpValidator}>Forget password</button></div>

//                                           <div className='flex justify-center mt-3'>
//                                               <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
//                                                   handleClick4();

//                                               }} >
//                                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
//                                                   <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p>
//                                                   <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                               </button>

//                                           </div>
//                                           <div className='flex justify-center'>
//                                               <p className='text-white font-bold text-sm'>or</p>
//                                           </div>

//                                           <div className="flex flex-col md:flex-row justify-center gap-2 md:pl-5">
//                                               <div className='flex justify-center'>

//                                                   <div onClick={handleValidatorWalletLogin}>
//                                                       <w3m-button
//                                                           balance="hide" />
//                                                   </div>
//                                                   <>
//                                                       {isConnected && (
//                                                           <button onClick={handleWalletLogOut}>
//                                                               <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
//                                                           </button>
//                                                       )}
//                                                   </>
//                                               </div>

//                                               <div className='flex justify-center'>
//                                                   <div className="md:w-[1px] bg-white/50 md:h-10"></div>
//                                               </div>
//                                               <div className='flex justify-center'>
//                                                   <div className='flex justify-center overflow-hidden rounded-full' onClick={handleGoogleSignIn_validator}>
//                                                       <GoogleLogin
//                                                           clientId={clientId}
//                                                           buttonText="Sign-in as Validator"
//                                                           onSuccess={handleGoogleSignIn_validator}
//                                                           onFailure={onFailure}
//                                                           cookiePolicy={"single_host_origin"}
//                                                           isSignedIn={false}
//                                                           prompt="select_account"
//                                                       />
//                                                   </div>
//                                               </div>
//                                           </div>

//                                           <div className='flex justify-center'>
//                                               <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' onClick={toggleValidator}>Create new account</a>
//                                           </div>
//                                       </div>
//                                   </form>
//                               </div>
//                               <div className='group md:w-[20%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 ' >
//                                   <div className='flex justify-center py-5 md:py-2 text-white'>
//                                       <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? 'hidden' : ''}`} onClick={(e) => {
//                                           e.preventDefault();
//                                           handleRedirectValidator();
//                                           //  handleAnchorClick("div3") 
//                                       }}>Validator</a>
//                                       <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? '' : 'hidden'}`} id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} onClick={(e) => {
//                                           e.preventDefault();
//                                           handleRedirectRecruiter();
//                                           // handleAnchorClick('div1'); 
//                                       }}>Recruiter </a>
//                                   </div>

//                               </div>

//                           </div>
//                       </div>
//                   </div>
//                   <div className='md:w-[15%] flex justify-center md:justify-start'>
//                       <img src='/assets/Others/RightSide.png' className='rotate-90 md:rotate-0'></img>
//                   </div>
//               </div>
//               <div className='px-28 py-10 flex justify-center -translate-y-[27rem] '>
//                   <div className='rounded-full p-6 border-[6px] border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_10px_#08f,0_0_10px_#08f]'>
//                       <img src='/assets/NO_text_logo.png' ></img>
//                   </div>
//               </div>
//           </div>
//       )}
  

    
//   <div>
//             {isOpen5 && (
//                 <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
//                     <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
//                         <div className="modal-content">
//                             <div className='slide-in-from-top1'>
//                                 <div>
//                                     <div>
//                                         <div className='items-center'>
//                                             <div>
//                                                 <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
//                                             </div>
//                                             <div className='flex justify-end h-7'>
//                                                 <button className='shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i className="fa-solid fa-xmark"></i></button>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <form onSubmit={handleSubmit}>
//                                         <div className='mt-4 mb-20'>
//                                             <Slider className='bg-[#111111] rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' {...settings}>
//                                                 <div className='flex justify-center'>
//                                                     <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
//                                                     <div className='flex flex-col md:flex-row gap-5 md:gap-10 w-[90%] mx-auto mt-5'>
//                                                         <div className='md:w-[50%] flex items-center'>
//                                                             <div className='w-full flex flex-col gap-7'>
//                                                                 <div className='flex flex-col gap-7'>
//                                                                     <div className='flex justify-center'>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                         <div className='flex border-b border-gray-500 w-full'>
//                                                                             <input type='text' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Name seeker'
//                                                                                 onChange={(e) => handleInputData('personalInfo', 'name', e.target.value)}></input>
//                                                                             <i className="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
//                                                                             <span className="text-red-500 w-[3%] pl-2">*</span>
//                                                                         </div>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div className='md:w-[100%] flex'>
//                                                                     <Select
//                                                                         value={countryOptions.find(option => option.label === personalInfo.country)}
//                                                                         onChange={(selectedOption) => {
//                                                                             handleInputData('personalInfo', 'country', selectedOption.label);
//                                                                             handleInputData('personalInfo', 'countryCode', selectedOption.value);
//                                                                         }}
//                                                                         options={countryOptions}
//                                                                         placeholder='Country'
//                                                                         styles={customStyles}
//                                                                         getOptionLabel={(option) => `${option.label} (${option.value}) ${option.country_flag}`}
//                                                                         getOptionValue={(option) => option.label}
//                                                                         className='w-[97%]'
//                                                                     />

//                                                                     <span className="text-red-500 w-[3%] pl-1">*</span>
//                                                                 </div>

//                                                                 <div className='flex flex-col gap-3'>
//                                                                     <div className='flex justify-center'>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                         <div className='flex border-b border-gray-500 w-full'>
//                                                                             <input type='number' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Mobile number'
//                                                                                 onChange={(e) => handleInputData('personalInfo', 'mobileNumber', e.target.value)}></input>
//                                                                             <i className="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
//                                                                             <span className="text-red-500 w-[3%] pl-2">*</span>
//                                                                         </div>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div className='flex flex-col gap-3'>
//                                                                     <div className='flex justify-center'>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                         <div className='flex border-b border-gray-500 w-full'>
//                                                                             <DatePicker
//                                                                                 selected={personalInfo.dob}
//                                                                                 onChange={(date) => handleInputData('personalInfo', 'dob', date)}
//                                                                                 dateFormat="MM/dd/yyyy"
//                                                                                 placeholderText="Select your DOB"
//                                                                                 className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
//                                                                             />
//                                                                             <i className="fa-solid fa-calendar text-gray-300 text-xl mt-3"></i>
//                                                                             <span className="text-red-500 w-[3%] pl-2">*</span>
//                                                                         </div>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                     </div>
//                                                                 </div>

//                                                                 <div className='flex flex-col gap-7'>
//                                                                     <div className='flex justify-center'>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                         <div className='flex border-b border-gray-500 w-full'>
//                                                                             <input type={passwordVisible ? 'text' : 'password'} className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your password'
//                                                                                 onChange={(e) => handleInputData('personalInfo', 'password', e.target.value)}></input>
//                                                                             <button type="button" onClick={handleTogglePasswordVisibility}>
//                                                                                 {passwordVisible ? <i className="fa-solid fa-eye-slash text-gray-300 text-xl mt-3"></i> : <i className="fa-solid fa-eye text-gray-300 text-xl mt-3"></i>}
//                                                                             </button>
//                                                                             <span className="text-red-500 w-[3%] pl-2">*</span>
//                                                                         </div>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                     </div>

//                                                                     <div className='flex justify-center'>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                         <div className='flex border-b border-gray-500 w-full'>
//                                                                             <input type={passwordVisible ? 'text' : 'password'} className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Confirm your password'
//                                                                                 onChange={(e) => handleInputData('personalInfo', 'confirmpassword', e.target.value)}></input>
//                                                                             <button type="button" onClick={handleTogglePasswordVisibility}>
//                                                                                 {passwordVisible ? <i className="fa-solid fa-eye-slash text-gray-300 text-xl mt-3"></i> : <i className="fa-solid fa-eye text-gray-300 text-xl mt-3"></i>}
//                                                                             </button>
//                                                                             <span className="text-red-500 w-[3%] pl-2">*</span>
//                                                                         </div>
//                                                                         <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
//                                                                     </div>

//                                                                     {!passwordsMatch && <p className='text-red-500 text-center'>Passwords do not match</p>}
//                                                                 </div>

//                                                                 <div className='flex justify-center'>
//                                                                     <button type='submit' className='bg-[#0ea4a9] text-white py-2 px-5 rounded hover:bg-[#0c8b8e] transition-all'>Register</button>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Slider>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>


//   </div>
     
//     );
// };

// export default JobSeekerRegistration;















import React, { useState, useRef } from 'react';
import Slider from 'react-slick'; 
import DatePicker from 'react-datepicker'; 
import Select from 'react-select'; 
import 'react-datepicker/dist/react-datepicker.css'; 

import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';


import axios from 'axios';

const YourComponent = () => {


  const settings = "wewew" 
  const countryOptions = "23456"


 const [personalInfo, setPersonalInfo] = useState({
        name: '',
        email: '',
        country: '',
        state: '',
        countryCode: '',
        mobileNumber: '',
        dob: '',
        avatar: './assets/image 90.png',
        password: '',
        confirmpassword: '',
        walletAddress: '',
    });
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const { isConnected, handleWalletLogOut } = useWallet(); 
    const sliderRef = useRef(null);

    const handleInputData = (section, key, value) => {
        setPersonalInfo(prev => ({ ...prev, [key]: value }));
        if (key === 'password' || key === 'confirmpassword') {
            validatePasswords();
        }
    };

    const validatePasswords = () => {
        setPasswordsMatch(personalInfo.password === personalInfo.confirmpassword);
    };

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axios.post('/api/register', personalInfo);
            // Handle success
        } catch (error) {
            // Handle error
        } finally {
            setIsLoading(false);
        }
    };

    const savePersonalInfo = async () => {
        try {
            await axios.post('/api/savePersonalInfo', personalInfo);
        } catch (error) {
            // Handle error
        }
    };


    const customStyles = "w56"





    const toggleModal5 = () => {
        setIsOpen5(!isOpen5);
    };

    return (
        <div>
            {isOpen5 && (
                <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
                    <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
                        <div className="modal-content">
                            <div className='slide-in-from-top1'>
                                <div>
                                    <div className='items-center'>
                                        <div>
                                            <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
                                        </div>
                                        <div className='flex justify-end h-7'>
                                            <button className='shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal5}>
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='mt-4 mb-20'>
                                            <Slider className='bg-[#111111] rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
                                                <div className='flex justify-center'>
                                                    <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                    <div className='flex flex-col md:flex-row gap-5 md:gap-10 w-[90%] mx-auto mt-5'>
                                                        <div className='md:w-[50%] flex items-center'>
                                                            <div className='w-full flex flex-col gap-7'>
                                                                <div className='flex flex-col gap-7'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='flex border-b border-gray-500 w-full'>
                                                                            <input type='text' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Name seeker'
                                                                                onChange={(e) => handleInputData('personalInfo', 'name', e.target.value)} />
                                                                            <i className="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            <span className="text-red-500 w-[3%] pl-2">*</span>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='md:w-[100%] flex'>
                                                                    <Select
                                                                        value={countryOptions.find(option => option.label === personalInfo.country)}
                                                                        onChange={(selectedOption) => {
                                                                            handleInputData('personalInfo', 'country', selectedOption.label);
                                                                            handleInputData('personalInfo', 'countryCode', selectedOption.value);
                                                                        }}
                                                                        options={countryOptions}
                                                                        placeholder='Country'
                                                                        styles={customStyles}
                                                                        getOptionLabel={(option) => `${option.label} (${option.value}) ${option.country_flag}`}
                                                                        getOptionValue={(option) => option.label}
                                                                        className='w-[97%]'
                                                                    />
                                                                    <span className="text-red-500 w-[3%] pl-1">*</span>
                                                                </div>
                                                                <div className='flex flex-col gap-3'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='flex border-b border-gray-500 w-full'>
                                                                            <input type='number' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Mobile number'
                                                                                onChange={(e) => handleInputData('personalInfo', 'mobileNumber', e.target.value)} />
                                                                            <i className="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            <span className="text-red-500 w-[3%] pl-2">*</span>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-3'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='flex border-b border-gray-500 w-full'>
                                                                            <input
                                                                                type={passwordVisible ? 'text' : 'password'}
                                                                                className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                placeholder='Enter Password'
                                                                                value={personalInfo.password}
                                                                                onChange={(e) => handleInputData('personalInfo', 'password', e.target.value)}
                                                                            />
                                                                            <i
                                                                                className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                onClick={handleTogglePasswordVisibility}
                                                                            ></i>
                                                                            <span className="text-red-500 w-[3%] pl-2">*</span>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-3'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='flex border-b border-gray-500 w-full'>
                                                                            <input
                                                                                type={passwordVisible ? 'text' : 'password'}
                                                                                className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                placeholder='Confirm Password'
                                                                                value={personalInfo.confirmpassword}
                                                                                onChange={(e) => handleInputData('personalInfo', 'confirmpassword', e.target.value)}
                                                                            />
                                                                            <i
                                                                                className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                onClick={handleTogglePasswordVisibility}
                                                                            ></i>
                                                                            <span className="text-red-500 w-[3%] pl-2">*</span>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-3'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='flex border-b border-gray-500 w-full'>
                                                                            <DatePicker
                                                                                selected={new Date(personalInfo.dob)}
                                                                                onChange={(date) => handleInputData('personalInfo', 'dob', date)}
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select Date of Birth"
                                                                                className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                            />
                                                                            <i className="fa-solid fa-calendar text-gray-300 text-xl mt-3"></i>
                                                                            <span className="text-red-500 w-[3%] pl-2">*</span>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-center'>
                                                                    <button
                                                                        type='submit'
                                                                        className='bg-[#0ea4a9] text-white px-6 py-2 rounded-full'
                                                                        disabled={!passwordsMatch || isLoading}
                                                                    >
                                                                        {isLoading ? 'Submitting...' : 'Submit'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <button onClick={toggleModal5}>Open Modal</button>
        </div>
    );
};

export default YourComponent;
