import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import Home from '../Components/Home';
import Courses from '../Pages/Courses/Courses';
import PreCourse from '../Pages/Courses/PreCourse';
import Landing from '../Components/Landing';
import RecruiterRegister from '../Auth/RecruiterRegistration';
import SeekerRegistration from '../Auth/SeekerRegistration';
import ValidatorRegistration from "../Auth/ValidatorRegistration";
import SeekerReg from '../Auth/SeekerReg';
import ForgetPassword from '../Auth/ForgetPassword';
import ResetPassword from '../Auth/ResetPassword';
import Webinar from '../Components/Webinar';

import SeekerSkillArena from '../Pages/Seeker/Components/SeekerSkillArena';
import SeekerSkillArenalist from '../Pages/Seeker/Components/SeekerSkillArenalist';
import SeekerSkillmarket from '../Pages/Seeker/Components/SeekerSkillmarket';
import SeekerUserDetails from '../Pages/Seeker/Components/UserDetails';
import SeekerDashboard from '../Pages/Seeker/Dashboard/SeekerDashboard';
import SeekerAssessment from '../Pages/Seeker/Components/Assessment';
import SeekerEditProfile from '../Pages/Seeker/Components/EditProfile';
import Seeker_update_registration from '../Pages/Seeker/Components/seeker_update_registration';
import SeekerFAQ from '../Pages/Seeker/Components/seekerFAQ'

import Test from '../Test'
import Testlogin from '../Testlogin'


function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Home />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/PreCourse" element={<PreCourse />} />

      <Route path="/Webinar" element={<Webinar />} />


      {/* Registration */}
       <Route path="/reg" element={<SeekerReg />} />

      <Route path="/recruiter/registration" element={<RecruiterRegister />} />
      <Route path="/seeker/registration" element={<SeekerRegistration />} />
      <Route path="/validator/registration" element={<ValidatorRegistration />} />

      {/*Reset*/}
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />

      {/*Seeker*/}
      <Route path="/rms/seeker/skillarena" element={<SeekerSkillArena />} />
      <Route path="/rms/seeker/skillarenalist" element={<SeekerSkillArenalist />} />
      <Route path="/rms/seeker/skillmarket" element={<SeekerSkillmarket />} />
      <Route path="/rms/seeker/userDetails" element={<SeekerUserDetails />} />
      <Route path="/rms/seeker/seekerDashboard" element={<SeekerDashboard />} />
      <Route path="/rms/seeker/assessment" element={<SeekerAssessment />} />
      <Route path="/rms/seeker/editprofile" element={<SeekerEditProfile />} />
      <Route path="/updateprofile" element={<Seeker_update_registration />} />
      <Route path="/ex/seeker/faq" element={<SeekerFAQ/>} />




      {/* Testing */}
      <Route path="/test" element={<Test/>} />
      <Route path="/testlogin" element={<Testlogin/>} />



    </Routes>
  );

}

export default Routers;