import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'
import '../Style/App.css'

Aos.init();

export default function Header() {
  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const [isTransformed, setIsTransformed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const toggleModal = () => {
    // If modal is open, show loading
    if (!isOpen) {
      setIsLoading1(true);
      setTimeout(() => {
        setIsLoading1(false);
        setIsOpen(!isOpen); // Close the modal after hiding the loading
      }, 1000); // Adjust the duration as needed
    } else {
      setIsOpen(!isOpen); // Close the modal directly
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsTransformed(true);
    } else {
      setIsTransformed(false);
    }
  }, [isOpen]);

  const [activeLink, setActiveLink] = useState(false);

  const linkClasses = 'relative hover:text-sky-400 transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-sky-400 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-sky-400 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]';
  const activeLinkClasses = 'before:w-[50%] after:w-[50%] text-sky-400';

  //const dispatch = useDispatch();

  // const Logout = () => {
  //   dispatch(setUserEmail(""));
  //   window.location.reload();
  // };
  return (
    <div>
      <div className='h-[15vh] pt-3 ' data-aos='fade-down'>
        <nav className='flex  backdrop-blur-sm fixed right-0  left-0 z-20 border border-[#0EA4A9] shadow-md shadow-[#0EA4A9] mx-3 md:mx-10  px-5 py-1 md:py-3 rounded-full font-poppins'>
          <div className='w-[85%] lg:w-[15%]'>
            <div className='w-[80%]'>
              <a href='/'> <img src='./assets/Logo/D5ART_T White_PNG_new.png' className='h-12 md:h-14'></img> </a>
            </div>
          </div>
          <div className='w-[15%] lg:w-[85%] h-[56px] hidden md:table'>
            <div className=' flex h-[56px] '>
              <div className='w-[80%]  text-gray-300 font-bold flex justify-center gap-16'>
                <div className='flex items-center'>
                <NavLink to='/home' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                  Home
                </NavLink>
                </div>
                <div className='flex items-center'>
                <NavLink to='/SkillArena' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                  Skill Exchange
                </NavLink>
                </div>
                <div className='flex items-center'>
                <NavLink to='/courses' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
              Courses
                </NavLink>
                </div>
                <div className='flex items-center'>
                <NavLink
                  to='/faq'
                  className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                  FAQ
                </NavLink>
                </div>

              </div>
              <div className='w-[20%] flex items-center justify-end'>
                <button className='flex pt-3 pb-1 px-3 bg-[#101010]/50 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
                  <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[110px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                </button>
              </div>
            </div>
          </div>
          <div className='w-[15%] table lg:hidden grid content-center'>
            <div className='flex justify-center '>
              <button onClick={handleDropdown}>
                <i class="fa-solid fa-ellipsis-vertical text-white text-2xl "></i>
              </button>
              {isDropdown && (
                <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
                  <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                    <a href='/' className='text-center'>Home</a>
                    <a href='/SkillArena' className='text-center'>Skill Exchange</a>
                    <a href='https://edu.d5art.com/' className='text-center'>Courses</a>
                    <a href='/faq' className='text-center'>FAQ</a>

                    <div className='flex justify-center'>
                      <button className='flex py-1 pt-2 px-3 bg-[#101010] rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
                        <button className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
      {/*
      {isLoading1 && (
        <div className=''>
          <div className='z-100'>
            <Loading isVisible1={isLoading1} />
          </div>
        </div>
      )}*/}
      {isOpen && (
        <div className=''>
          <div className='z-100'>
           {/* <Spinners isVisible={isLoading} />*/}
          </div>
          <div className='px-7 md:px-10  z-20 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row md:items-center md:justify-center bg-black bg-opacity-10 backdrop-blur-lg overflow-y-auto'>
            <div className='md:w-[15%] flex justify-center md:justify-end'>
              <img src='./assets/Others/LeftSide.png' className='rotate-90 md:rotate-0'></img>
            </div>
            <div className={`${isTransformed ? 'md:w-[70%] ' : 'border-0 w-0 '} z-20   bg-transparent transform-width duration-500 ease-out-in rounded-3xl`}>

              <div className=' w-[100%] flex justify-center gap-2'>
                <div className='w-[95%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10'>
                  <div className='flex justify-end '>
                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                  </div>
                  <h1 className='font-bold text-center text-white text-xl md:text-3xl'>Are you here us ?</h1>
                  <div className='flex flex-col md:flex-row justify-center 2xl:gap-20 gap-10 py-10'>
                    <a href='/recruiter/registration'>
                      <div class="flip-card mx-auto md:mx-0">
                        <div class="flip-card-inner">
                          <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                            <div className='flex justify-center'><img src="./assets/Others/Recuiterimg.png" />
                            </div>
                            <p className='mt-2 text-white font-bold'>Recruiter</p>

                          </div>
                          <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                            <h1 className='text-gray-400 '>Recruiter</h1>
                            <p className='text-center text-sm font-bold mt-5'> Find the perfect candidate: Connect with top talent to elevate your team and drive success.</p>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href='/seeker/registration'>
                      <div class="flip-card mx-auto md:mx-0">
                        <div class="flip-card-inner">
                          <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                            <div className='flex justify-center'><img src="./assets/Others/seekerimg.png" />
                            </div>
                            <p className='text-white font-bold'>Job Seeker</p>

                          </div>
                          <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                            <h1 className='text-gray-400 '>Job Seeker</h1>
                            <p className='text-center text-sm font-bold mt-5'> Unlock your potential: Explore top job opportunities tailored to your skills and ambitions.</p>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href='/validator/registration'>
                      <div class="flip-card mx-auto md:mx-0">
                        <div class="flip-card-inner">
                          <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                            <div className='flex justify-center'><img src="./assets/Others/validatorimg.png" />
                            </div>
                            <p className='mt-2 text-white font-bold'>validator</p>

                          </div>
                          <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                            <h1 className='text-gray-400 '>Validator</h1>
                            <p className='text-center text-sm font-bold mt-5'> Verify job seekers skills: Ensure top talent with precise and reliable skill validation.</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='md:w-[15%] flex justify-center md:justify-start  '>
              <img src='./assets/Others/RightSide.png' className='rotate-90 md:rotate-0'></img>
            </div>
          </div>
        </div>
      )}
    </div>

  )
}