
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Moralis from 'moralis';
import { ethers } from 'ethers';
import LoginHeader from '../Layout/LoginHeader';
import Footer from '../../../Layout/Footer';
import axios from 'axios';
import Select from 'react-select';
import { useSelector } from 'react-redux';

//import Loader from '../loaderdemo';
// import ErrorPage from '../please_wait_page';
import Skillslist from '../../../Config/Json/Skills.json';
import CryptoJS from 'crypto-js';




export default function SkillArenalist() {
    const [starCount, setStarCount] = useState(0);
    const [nftItems, setNftItems] = useState([]);
    const [userInfo, setUserInfo] = useState({});

    const [loading, setLoading] = useState(true);

    const Email = useSelector((state) => state.user_email);
    console.log("Email Logged In", Email)

    // console.log("process.env.SEEKER_SECRETKEY",process.env.REACT_APP_SEEKER_SECRETKEY);


    const [selectedSkill, setSelectedSkill] = React.useState([]);
    const [inputValueSkill, setInputValueSkill] = React.useState('');

    const handleSkill = (selectedOption) => {
        if (selectedSkill.length < 6) {
            setSelectedSkill([...selectedSkill, selectedOption]);
        }
        setInputValueSkill('');
    };
    const removeOption2 = (index) => {
        const updatedOptions = [...selectedSkill];
        updatedOptions.splice(index, 1);
        setSelectedSkill(updatedOptions);
    };

    const SkillOptions = Skillslist;



    const [selectedLocation, setSelectedLocation] = React.useState([]);
    const [inputValueLocation, setInputValueLocation] = React.useState('');

    const handleLocation = (selectedOption) => {
        if (selectedLocation.length < 3) {
            setSelectedLocation([...selectedLocation, selectedOption]);
        }
        setInputValueLocation('');
    };
    const removeOption = (index) => {
        const updatedOptions = [...selectedLocation];
        updatedOptions.splice(index, 1);
        setSelectedLocation(updatedOptions);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '0px solid #0a3840',
            backgroundColor: '#374151',
            borderRadius: '30px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
    };


    const incrementStar = () => {
        if (starCount < 5) {
            setStarCount(starCount + 1);
        }
    };
    const decrementStar = () => {
        if (starCount > 0) {
            setStarCount(starCount - 1);
        }
    };


    const location = useLocation();

    const { search } = location;
    const params = new URLSearchParams(search);
    const userEmail = params.get('userEmail');

    //   old moralis api key : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImM1NjU4OGY0LTMxZWUtNDgyOC04ZjgwLTVmMTFhOWIzMThhOSIsIm9yZ0lkIjoiMzgwNzQwIiwidXNlcklkIjoiMzkxMjI3IiwidHlwZUlkIjoiOGUxMjNmYzItNjBhNS00ODg2LWE2OTMtZWI1OTE0Yzk5YTc1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDkyOTczMzMsImV4cCI6NDg2NTA1NzMzM30.xknGONE6zJzIyn_RoA26pMy_TVOWurhfxdzu6o2_UHU

    const fetchData = async () => {
        try {
            await Moralis.start({
                apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE"
            });

            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": "0x13882",
                "format": "decimal",
                "address": "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec"
            });


            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
            console.log(ipfsImage);

            console.log(response.raw.result);
            setNftItems((prevItems) => [...prevItems, ...response.raw.result]);



            setLoading(false);
        } catch (error) {
            if (error && error.code === 401) {
                setLoading(true); // Show loader for 401 error code
            } else {
                setLoading(false);
            }
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            const skillsAttributes = parsedMetadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
            console.log("Skills Attributes:", skillsAttributes);

            const skills = skillsAttributes.map(attr => attr.value);
            console.log('Skills2222:', skills);
            console.log("222222222222222", parsedMetadata.attributes);

            return parsedMetadata.image || "images/default-image.jpg";




        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };

    const handleButtonClick = (tokenId) => {

        // const newPath = `/RMS/Seeker/Skillmarket?tokenId=${tokenId}`;
        // window.location.href = newPath;

        // const Seeker_secretKey = "seeker123";
        // const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
        const Seeker_secretKey = 'Seeker@sdfs23423@##%#$@$Y%USJNFJSWB##*$&@^23w54293y428935yjrj#$@$@#';

        let encryptedTokenId = CryptoJS.AES.encrypt(tokenId, Seeker_secretKey).toString();
        // console.log("encryptedTokenId", tokenId);
        const newPath = `/RMS/Seeker/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
        window.location.href = newPath;
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [workModeQuery, setWorkModeQuery] = useState('');
    const [minExperience, setMinExperience] = useState(0);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleWorkModeChange = (e) => {
        setWorkModeQuery(e.target.value);
    };

    const handleExperienceChange = (e) => {
        setMinExperience(parseInt(e.target.value));
    };

    const filteredItems = nftItems.filter((item) => {
        const metadata = JSON.parse(item.metadata);
        const itemName = metadata.name?.toLowerCase() || '';
        const workModeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
        const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
        const experience = experience_Attributes.map(attr => parseInt(attr.value)).filter(exp => !isNaN(exp) && exp !== undefined);
        const hasValidExperience = experience.some(exp => exp >= minExperience || exp === 0 || exp === null);


        const workModeValue = workModeAttribute?.value?.toLowerCase() || '';


        const selectedSkills = selectedSkill.map(skill => skill.label);
        console.log("select", metadata.attributes);


        const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
        const skills = skillsAttributes.flatMap(attr => JSON.parse(attr.value));
        const hasSelectedSkills = selectedSkills.every(skill => skills.includes(skill));
        // console.log("select1",hasSelectedSkills);

        return itemName.includes(searchQuery.toLowerCase()) &&
            (!workModeQuery || (workModeValue.includes(workModeQuery.toLowerCase()))) &&
            hasValidExperience && hasSelectedSkills;
    });



    ///////////////


    // if (loading) {
    //     return <Loader />;
    // }


    return (

        <div>
            {
                //  loading ? (
                //     <Loader />
                // ) : filteredItems.length === 0 ? (

                //         <ErrorPage />

                // ) : 
                (

                    <div className='font-poppins bg-[#191919] '>
                        <LoginHeader />

                        <div className='Content1 pb-10' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }} >
                            <div className='flex justify-center'>
                                <h1 className='text-center text-3xl font-bold'
                                    style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                    }}>Skill Arena</h1>
                            </div>
                            <div className='flex justify-center'>
                                <div className='w-[90%] pt-10 text-white '>
                                    <div className='rounded-full bg-gray-300 flex'>
                                        <input className=' outline-none px-10 py-2 text-black w-[95%] rounded-full'
                                            placeholder='Search for Skill Id'
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <i className="fa-solid fa-magnifying-glass text-black w-[5%] mt-3 text-center"></i>
                                    </div>

                                    <p className='md:flex justify-between mt-5 text md:text-xs 2xl:text-base font-bold'>
                                        <p className='md:flex gap-7'>
                                            <p>Experience: <span className='ml-2 text-[#0087a5]'>{minExperience || '0-1'} Years </span></p>
                                            <p>Location: <span className='ml-2 text-[#0087a5]'>Any</span></p>
                                            <p>Contract type: <span className='ml-2 text-[#0087a5]'>{workModeQuery || 'Any'}</span></p>
                                        </p>
                                        <p>Sort by: <span className='ml-2 text-[#0087a5]'>Relevance</span></p>
                                    </p>
                                    <p className='text md:text-xs font-bold mt-7 2xl:text-base'>Search result: <span className='ml-2 text-[#0087a5] '>100</span></p>
                                    <div className='lg:flex gap-7 h-screen'>
                                        <div className='lg:w-[30%] flex'>
                                            <div className='w-full rounded-2xl border border-sky-800 mt-5 p-5 pt-6 2xl:pt-12'>
                                                <h1 className='font-bold text-xl 2xl:text-2xl'>All Filter:</h1>
                                                <div className='mt-3 px-3'>
                                                    <h2 className='text-sm 2xl:text-base font-bold'>Work mode:</h2>
                                                    <input type='search'
                                                        placeholder='Choose your preferred mode'
                                                        className='bg-gray-700 outline-none placeholder-[#d3d3d3] rounded-full py-2 px-5 w-[100%] mt-1 text-xs'
                                                        value={workModeQuery}
                                                        onChange={handleWorkModeChange}
                                                    />


                                                    <h2 className='text-sm 2xl:text-base font-bold mt-3'>Experience:</h2>
                                                    <input type='range'
                                                        className='w-full'
                                                        min='0'
                                                        max='20'
                                                        value={minExperience}
                                                        onChange={handleExperienceChange}
                                                    />
                                                    <p className='flex justify-between text-sm'><p>Fresher</p><p>{minExperience || '10+'}   years</p></p>
                                                    <h2 className='text-sm 2xl:text-base font-bold mt-8'>Skill / Tools:</h2>
                                                    <div className='pt-2'>
                                                        <Select
                                                            inputValueSkill={inputValueSkill}
                                                            onInputChange={(value) => setInputValueSkill(value)}
                                                            value={selectedSkill}
                                                            onChange={handleSkill}
                                                            options={SkillOptions}
                                                            placeholder='Add Skills'
                                                            styles={customStyles}

                                                        />
                                                        <div className="">
                                                            <ul className='grid grid-cols-2 text-center gap-3 mt-3'>
                                                                {selectedSkill.map((option, index) => (
                                                                    <li className='rounded-full text-xs text-black px-3 py-1 bg-white' key={index}>
                                                                        {option.label}
                                                                        <span
                                                                            className='ml-1'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => removeOption2(index)}>
                                                                            &#x2715;
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {/* <h2 className='text-sm 2xl:text-base font-bold mt-3'>Rating:</h2>
                                        <div className='flex'>
                                            <div className='w-[50%] flex gap-2 py-3'>
                                                {[...Array(starCount)].map((_, index) => (
                                                    <i key={index} className='fa-solid fa-star text-yellow-600 py-1'></i>
                                                ))}
                                            </div>
                                            <div className='w-[50%] grid content-center'>
                                                <div className='flex bg-gray-700 rounded-full justify-around py-1'>
                                                    <button className='px-2 rounded-lg bg-black' onClick={decrementStar}>
                                                        <i className="fa-solid fa-minus"></i>
                                                    </button>
                                                    <p>{starCount}</p>
                                                    <button className='px-2 rounded-lg bg-black' onClick={incrementStar}>
                                                        <i className="fa-solid fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='lg:w-[70%] pt-5 overflow-y-auto scrollbar'>



                                            {filteredItems.map((item, index) => {
                                                const metadata = JSON.parse(item.metadata);
                                                // console.log('metadata:',metadata);
                                                const ipfsImage = metadata.image.split('://')[1];
                                                //console.log('name', metadata.name);
                                                //console.log('token id', item.token_id);

                                                const locationAttribute = metadata.attributes.find(attr => attr.trait_type === 'location');


                                                const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
                                                console.log("Skills Attributes:", skillsAttributes);

                                                const skills = skillsAttributes.map(attr => attr.value);





                                                const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
                                                const experience = experience_Attributes.map(attr => attr.value);
                                                console.log('experience123123', experience);



                                                const project_noAttribute = metadata.attributes.find(attr => attr.trait_type === 'project_no');
                                                const projectValue = project_noAttribute && project_noAttribute.value !== undefined ? project_noAttribute.value : 0;

                                                const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                                                const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                                                console.log('prefered_roll', prefered_rolls);

                                                const tokenAttribute = metadata.attributes.find(attr => attr.trait_type === 'token_id');


                                                const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');


                                                const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 7) : '';
                                                return (

                                                    <div key={index} className='flex flex-col gap-7'>

                                                        <div className='bg-gradient-to-br from-[#0E2DA7] to-[#0EA4A9] p-[2px] rounded-2xl mb-4'>
                                                            <div className='bg-[#191919]  rounded-2xl lg:flex' style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}>
                                                                <div className='2xl:ml-12 lg:w-[40%] py-5 pl-5'>
                                                                    <h1 className='text-lg 2xl:text-xl font-bold pt-1'>Skill ID : <span className='text-[#0EA4A9]'>{slicedName}</span></h1>
                                                                    <div className='flex pt-2'>
                                                                        <div className='w-[40%]'>
                                                                            <img className='h-[118px]' src={getImageFromMetadata(item.metadata)}></img>
                                                                        </div>
                                                                        <div className='w-[60%] pt-3 text-xs 2xl:text-base'>
                                                                            <div className='flex justify-around'>
                                                                                {skills.map((skillString, index) => {
                                                                                    try {

                                                                                        const skillsArray = JSON.parse(skillString);


                                                                                        return skillsArray.map((skill, skillIndex) => (
                                                                                            <div key={`${index}-${skillIndex}`}>
                                                                                                <button
                                                                                                    className='px-3 py-[2px] rounded-full bg-[#00768B] uppercase  mr-2'
                                                                                                >
                                                                                                    {skill}
                                                                                                </button>
                                                                                            </div>
                                                                                        ));
                                                                                    } catch (error) {
                                                                                        console.error("Error parsing skill:", error);
                                                                                        return null;
                                                                                    }
                                                                                })}
                                                                            </div>

                                                                            <div className='flex justify-center gap-2 pt-5 text-lg'>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='lg:w-[60%] grid content-between pb-5 pr-5'>
                                                                    <div className='flex justify-end'>
                                                                        <div className=' -translate-y-[3px] h-[28px]'>
                                                                            <div className='h-[28px] w-[120px] bg-center bg-cover text-xs flex items-center justify-center' style={{ backgroundImage: `url("/assets/Others/time.png")` }}>
                                                                                <p>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
                                                                            </div></div>
                                                                    </div>

                                                                    <div className='flex flex-col ml-10 gap-14'>
                                                                        <div>

                                                                            <p className='flex flex-col lg:flex-row gap-3 md:gap-10 text md:text-sm 2xl:text-base '>

                                                                                <p><i class="fa-solid fa-briefcase mr-2"></i>{experience} years</p>
                                                                                <p><i class="fa-solid fa-diagram-project mr-2"></i>Projects: {projectValue}</p>
                                                                                <button onClick={() => handleButtonClick(item.token_id)} className='px-3 py-[2px] rounded-full bg-[#00768B] uppercase mr-2'>
                                                                                    <p>View Profile</p>
                                                                                </button>

                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className='font-bold text md:text-sm 2xl:text-base mt-5 md:mt-0'>Prefered Role:</p>
                                                                            <div className='flex flex-col lg:flex-row gap-3 text-sm 2xl:text-base pt-2'>
                                                                                {prefered_rollsAttribute.map((prefered_roll, index) => {
                                                                                    if (prefered_roll.value) {
                                                                                        try {

                                                                                            const preferedRoles = JSON.parse(prefered_roll.value);
                                                                                            return preferedRoles.map((preferedRole, roleIndex) => (
                                                                                                <div key={index + '-' + roleIndex}>
                                                                                                    <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{preferedRole}</p>
                                                                                                </div>
                                                                                            ));
                                                                                        } catch (error) {
                                                                                            console.error('Error parsing preferred role data:', error);
                                                                                            return null;
                                                                                        }
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>

                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <Footer />
        </div>
    )
}