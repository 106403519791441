// import React, { useEffect, useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { NavLink } from 'react-router-dom';

// export default function RecommendedCourse({ courseId }) {
//   const [courses, setCourses] = useState([]);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await fetch('https://edu.d5art.com/api/Allcourses');
//         const data = await response.json();
//         console.log(data);
//         setCourses(data);
//       } catch (error) {
//         console.error('Error fetching courses:', error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   const filteredCourses = courses.filter((course) => course.course_id !== courseId);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//   };

//   return (

//     <Slider {...settings} className="card-container lg:flex gap-10  px-10 mt-10  font-bricolagegrotesque">
//       {filteredCourses.map((course) => (
//         <div className="sec-4 card p-6 rounded-lg text-FABcGray bg-white mt-6 lg:mt-0 grid content-around border shadow-md" key={course.course_id}>
//           <img
//             src={course.image_url}
//             alt=""
//             className="rounded-lg mb-8"
//           />
//           <div>
//             <h1 className="text-xl">
//               <span className="text-2xl text-black font-bold">
//                 {course.course_name}
//               </span>
//             </h1>
//             <div className="data flex justify-between mt-8 text-sm">
//               <p className="font-semibold">
//                 <i className="fa-solid fa-person-chalkboard text-slate-400" />
//                 Lessons: {course.total_contents}
//               </p>
//               <p className="font-semibold">
//                 <i className="fa-solid fa-trophy text-slate-400" />
//                 Level: {course.category}
//               </p>
//             </div>
//             <ul className="mt-6">
//               <h1 className="font-bold">Learning Outcomes:</h1>
//               {course.content_data ? (
//                 <ul>
//                   {Array.isArray(course.content_data.outcomes) ? (
//                     course.content_data.outcomes.map((outcome, index) => (
//                       <li key={index}> • {outcome}</li>
//                     ))
//                   ) : (
//                     <li>• {JSON.parse(course.content_data).outcomes}</li>
//                   )}
//                 </ul>
//               ) : (
//                 <p>No learning outcomes available.</p>
//               )}
//             </ul>
//           </div>
//           <div className="flex items-center justify-evenly mt-6">
//             <NavLink to={`/precourse?id=${course.course_id}`} key={course.id}>
//               <button className="px-12 py-3 text-FABcBlue border-2 rounded-lg border-FABcBlue hover:border-2 hover:border-white hover:bg-blue-200">
//                 Enroll Now{" "}
//                 <i className="fa-solid fa-arrow-right-long -rotate-45 ml-2" />
//               </button>
//             </NavLink>
//           </div>
//         </div>
//       ))}
//     </Slider>
//   );
// }



import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function RecommendedCourse({ courseId }) {
  console.log("courseId", courseId)
  const [courses, setCourses] = useState([]);
  const email = useSelector((state) => state.seeker_email);


  const [paymentStatuses, setPaymentStatuses] = useState({});

  const location = useLocation();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://edu.d5art.com/api/Allcourses');
        const data = await response.json();
        console.log(data);
        setCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  // const handleEnrollNowClick = (e) => {
  // //   e.stopPropagation(); 
  // window.reloa
  // };


  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevents context menu from appearing
    };
    window.addEventListener('contextmenu', handleContextMenu); // Adds event listener for contextmenu event
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu); // Cleans up event listener
    };
  }, []);




  const handleEnrollNowClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
      window.location.reload(); // Reloads the page
    }, 1000);
  };





  const filteredCourses = courses.filter((course) => course.course_id !== courseId);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };




  const StyledSlider = styled(Slider)`
    .slick-dots li button:before {
        color: white; /* Set color to white */
    }

    .slick-dots li.slick-active button:before {
        color: white; /* Set color to white */
    }
`;





  const [users, setUsers] = useState([]);


  useEffect(() => {
    axios
      .get(`https://edu.d5art.com/api/UserList?email=${email}`)
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);





  let role;

  if (users.length > 0) {
    role = users[0].role;
    console.log("role", role);
  } else {
    console.error("Users array is empty. Cannot access role.");
  }

  //----------------check status----


  useEffect(() => {
    const fetchPaymentStatuses = async () => {
      try {
        const statuses = {};
        await Promise.all(courses.map(async (course) => {
          const response = await axios.post(
            "https://edu.d5art.com/api/checkPaymentStatus",
            { email: email, courseId: course.course_id }
          );
          console.log("Response for course", course.course_id, ":", response.data);
          statuses[course.course_id] = response.data.PaymentStatus;
        }));
        console.log("Payment statuses:", statuses);
        setPaymentStatuses(statuses);
      } catch (error) {
        console.error("Error fetching payment statuses:", error);
      }
    };

    fetchPaymentStatuses();
  }, [courses]);

  const truncateDescription = (description) => {
    const words = description.split(' ');
    const truncatedDescription = words.slice(0, 20).join(' ');
    const showMore = words.length > 20;
    return showMore ? `${ truncatedDescription }...` : truncatedDescription;
  };

  return (
    <div>
      <div className='flex justify-center'>
        <StyledSlider {...settings} className="card-container  mt-10  font-bricolagegrotesque w-[85%] md:w-[90%]">
          {filteredCourses.map((course) => (
            <div className=" px-3" key={course.course_id}>
              <div className='h-full grid content-between  p-6 md:h-[37rem] border border-gray-700 rounded-xl bg-[#000000]/90  mt-6 lg:mt-0 shadow-md '>
                <div>
                  <img
                    src={course.image_url}
                    alt=""
                    className="rounded-lg mb-8 "
                  />
                  <div>
                    <h1>
                      <span className="text-xl md:text-2xl text-white font-bold">
                        {course.course_name}
                      </span>
                    </h1>
                    <div className="data flex flex-col md:flex-row justify-between mt-8 text-sm">
                      <p className="font-semibold">
                        <i className="fa-solid fa-person-chalkboard text-slate-400 mr-2" />
                        Lessons: {course.total_contents}
                      </p>
                      <p className="font-semibold">
                        <i className="fa-solid fa-trophy text-slate-400 mr-2" />
                        Level: {course.category}
                      </p>
                    </div>
                    <ul className="mt-6">
                      <h1 className="font-bold">Description:</h1>
                      <p >{truncateDescription(course.course_description)}</p>
                    </ul>

                  </div>
                </div>
                <div className="flex items-center justify-evenly ">
                  <Link to={`/precourse?id=${course.course_id}`} onClick={handleEnrollNowClick}>

                    {/* <NavLink to={`/precourse?id=${course.course_id}`} key={course.id}> */}
                    <button className="px-12 py-3 mt-3 md:mt-0 text-FABcBlue border-2 rounded-lg border-FABcBlue ">

                      {paymentStatuses[course.course_id] === "Success" || role === "skylift_user" ? "Start  Learning" : "Enroll Now"}

                      <i className="fa-solid fa-arrow-right-long -rotate-45 ml-2" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </StyledSlider>
      </div>
    </div>
  );
}
