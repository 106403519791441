////-----------new UI--------

import React from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
// import "./specific.css";
import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import RecommendedCourse from "./RecommendedCourse";
// import Loader from "./Loader";
import CurrencyList from "./CurrencyList.json";



export default function PreCourse() {

  const [isOpen5, setIsOpen5] = useState(false);
  const [count, setCount] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    if (isOpen5 && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (isOpen5 && count === 0) {
      togglemodal5();
      navigate("/course");
    }

    return () => clearInterval(timer);
  }, [isOpen5, count]);

  const togglemodal5 = () => {
    if (!isOpen5) {
      setCount(5);
    }
    setIsOpen5(!isOpen5);
  };

  const [content, setContent] = useState("div1");
  /////have rewrite the course
  const email = useSelector((state) => state.seeker_email);
  console.log("email", email);
  const [showPopup, setShowPopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [contentData, setContentData] = useState(null);
  const [coursePrice, setCoursePrice] = useState(0);



  const [totalAmount, setTotalAmount] = useState(0);

  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [openApplyCoupon, setOpenApplyCoupon] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [loading, setLoading] = useState(false);

  const [courseData, setCourseData] = useState({
    course_name: "",
    course_objective: "",
    course_description: "",
    total_contents: ""
  });



  const [course, setCourse] = useState({
    name: courseData.course_name,
    author: "",
    img: "",
    price: "",
  });


  const location = useLocation();
  const courseId = new URLSearchParams(location.search).get("id");
  //const courseId = "95";





  console.log(courseId);
  const [value, setValue] = useState([]);




  const fetchCourseData = async () => {
    try {
      const response = await axios.post("https://edu.d5art.com/api/course", {
        id: courseId,
      });
      console.log("response", response);
      setValue(response.data.data);
      setCourseData(response.data.data[0]);
      const data = response.data.data[0];

      // setTotalAmount(data.price);

      setTotalAmount(parseFloat(data.price));

      setCoursePrice(response.data.data[0].price)
      // Assuming content_data is a JSON string, parse it
      const parsedContentData = JSON.parse(response.data.data[0].content_data);
      setContentData(parsedContentData);


      console.log("response.data.data", response.data.data[0]);

      setCourse({
        name: data.course_name,
        author: email,
        img: data.image_url,
        price: data.price,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);


  console.log("contentData", contentData);

  console.log("course Price", coursePrice)


  // useEffect(() => {

  //   setCourse(prevState => ({
  //     ...prevState,
  //     author:email,
  //     img:courseData.image_url,
  //     price: coursePrice,
  //   }));
  // }, [coursePrice]);


  const [amountToPay, setAmountToPay] = useState(0.00);
  const [cryptoDiscountAmount, setCryptoDiscountAmount] = useState(0);
  const [ discountAmount , setDiscountAmount] = useState(0);

  useEffect(() => {
    const calculateDiscounts = () => {
      const discountAmount = discount ? (totalAmount * discount / 100).toFixed(2) : 0;
      console.log("discountAmount", discountAmount);

      const amountPayable = discount ? (totalAmount - discountAmount).toFixed(2) : totalAmount.toFixed(2);
      console.log("amountToPay", amountPayable);

      const cryptoDiscount = discount ? (coursePrice * discount / 100).toFixed(2) : 0;
      console.log("cryptoDiscountAmount", cryptoDiscount);
      setDiscountAmount(discountAmount);
      setAmountToPay(amountPayable);
      setCryptoDiscountAmount(cryptoDiscount);
    };

    calculateDiscounts();
  }, [totalAmount, discount, coursePrice]);


  // const discountAmount = discount ? (totalAmount * discount / 100).toFixed(2) : 0;
  // console.log("discountAmount",discountAmount)
  // const amountToPay = discount ? (totalAmount - discountAmount).toFixed(2) : totalAmount.toFixed(2);
  // const cryptoDiscountAmount = discount ? (coursePrice * discount / 100).toFixed(2) : 0;

  // console.log("cryptoDiscountAmount",cryptoDiscountAmount)


  useEffect(() => {
    console.log('amountToPay',amountToPay)
    setCourse(prevCourse => ({
      ...prevCourse,
      price: amountToPay,
    }));
  }, [coursePrice, amountToPay]);

  console.log("course price in amount to pay", course.price)



  // const totalAmount = 100;

  // const discountAmount = discount ? (totalAmount * discount / 100).toFixed(2) : 0;
  // const amountToPay = discount ? (totalAmount - discountAmount).toFixed(2) : totalAmount.toFixed(2);

  const price1 = 10000;


  const [preAssessmentPopup, setPreAssessmentPopup] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState("");

  const [showConfirmPaymentPopup, setShowConfirmPaymentPopup] = useState(false);
  const [freeEnrollmentPopup, setFreeEnrollmentPopup] = useState(false);

  
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [paymentData, setPaymentData] = useState([]);

  const [paymentResponse, setPaymentResponse] = useState([]);


  


  //-----------------old code

  // const initPayment = (data) => {
  //   const options = {
  //     key: "rzp_test_YrzYxp9mOwo6N9",
  //     amount: data.amount,
  //     currency: data.currency,
  //     name: course.name,
  //     description: "Test Transaction",
  //     image: course.img,
  //     order_id: data.id,
  //     handler: async (response) => {
  //       try {
  //         setPaymentResponse(response);
  //         console.log("response payment", response);
  //         const verifyUrl = "https://edu.d5art.com/api/verify";
  //         const { data } = await axios.post(verifyUrl, response);
  //         console.log(data);
  //         if (data.message === "Payment verified successfully") {
  //           setPaymentSuccess(data.message);
  //           toast.success(data.message);
  //           setShowPopup(true);
  //         } else {
  //           toast.error("Something went wrong! Please Try again.");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };
  //   const rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // };


  // const handlePayment = async () => {
  //   try {
  //     const orderUrl = "https://edu.d5art.com/api/orders";
  //     console.log("course.price",course.price)
  //     const { data } = await axios.post(orderUrl, { amount: course.price });

  //     console.log(data);
  //     setPaymentData(data.data);
  //     // setShowPaymentPopup(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };




  // const handleConfirmPayment =async () => {
  //   await handlePayment();

  //   setShowPaymentPopup(false);
  //   setShowConfirmPaymentPopup(false);

  //   if (paymentData) {
  //     initPayment(paymentData);
  //     console.log("paymentData", paymentData);
  //     console.log("paymentData Amount", paymentData.amount);
  //     console.log("paymentData id", paymentData.id);
  //   }
  // };

  //----------------

  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [currencySymbol, setCurrencySymbol] = useState("$");

  const [price, setPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const apiKey = "0b6f16b4d0563ae6f87117706bb9c15256911c5925dfa5c4a92523e269ac5a0f";


  useEffect(() => {
    const fetchPrice = async () => {
      if (selectedCurrency) {
        try {
          const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${selectedCurrency}&api_key=${apiKey}`);
          if (response.data && response.data[selectedCurrency]) {
            const fetchedPrice = response.data[selectedCurrency];
            console.log(`Fetched price for ${selectedCurrency}: ${fetchedPrice}`);

            setPrice(fetchedPrice);
            const roundedAmount = Math.round(fetchedPrice * coursePrice);
            console.log(`Rounded total amount: ${roundedAmount}`);

            setTotalAmount(roundedAmount);
            setErrorMessage("");
          } else {
            console.error("Invalid data received from the API:", response.data);
            setErrorMessage(`Conversion rate for USD to ${selectedCurrency} is not available.`);
            setPrice(null);
            setTotalAmount(null);
          }
        } catch (error) {
          console.error("Failed to fetch price:", error);
          setErrorMessage("Failed to fetch the price. Please try again later.");
          setPrice(null);
          setTotalAmount(null);
        }
      }
    };

    fetchPrice();
  }, [selectedCurrency, apiKey, coursePrice]);



  console.log("select currency", selectedCurrency)



  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setSelectedCurrency(newCurrency);

    const currency = CurrencyList.find(currency => currency.code === newCurrency);
    setCurrencySymbol(currency ? currency.symbol : newCurrency);
  };


  const handleCancelPayment = () => {
    setShowPaymentPopup(false);
    setFreeEnrollmentPopup(false)
  };

  const [paymentStoreData, setPaymentStoreData] = useState({
    email,
    courseName: "",
    courseId,
    PaidMoney: "",
    TransactionID: "",
    PaymentStatus: "success",
    orderID: "",
  });



  const handlePayment = async () => {
    try {
      const orderUrl = "https://edu.d5art.com/api/orders";

      // const amountInPaise = Math.round(course.price );

      const { data } = await axios.post(orderUrl, { amount: totalAmount, currency: selectedCurrency });

      console.log("Order data:", data);
      setPaymentData(data.data);
      if (data.data) {
        initPayment(data.data);
      }
    } catch (error) {
      console.error("Error during payment initiation:", error);
      window.location.reload();

      toast.error("Something went wrong during payment initiation. Please try again.");

    }
  };

  




  const initPayment = (data) => {
    const options = {
    key: "rzp_live_9NGeZlNjB9Tgui", 
 //  key: "rzp_test_YrzYxp9mOwo6N9",
      amount: data.amount,
      currency: data.currency,
      name: course.name,
      description: "LIVE Transaction",
      image: course.img,
      order_id: data.id,
      handler: async (response) => {
        try {
          setPaymentStoreData((prevData) => ({
            ...prevData,
            courseName: course.name,
            PaidMoney: data.amount,
            TransactionID: response.razorpay_payment_id,
            orderID: response.razorpay_order_id,
          }));

          setPaymentResponse(response);
          console.log("Payment response:", response);
          console.log("Payment response:   paymentStoreData",   paymentStoreData);
        

          // Verify payment on the server
          const verifyUrl = "https://edu.d5art.com/api/verify";
          const { data: verifyData } = await axios.post(verifyUrl,
             {
            ...response ,
            paymentStoreData: {
              ...paymentStoreData,
              courseName: course.name,
              PaidMoney: data.amount,
              TransactionID: response.razorpay_payment_id,
              orderID: response.razorpay_order_id,
            },
          });

          console.log("Verification response:", verifyData);
          if (verifyData.message === "Payment verified successfully") {
            setPaymentSuccess(verifyData.message);
            toast.success(verifyData.message);
          //  await handlePaymentVerification();


            if (isCouponApplied) {
              handleCouponUpdate(couponCode)
            }

            setShowPopup(true);
          } else {
            toast.error("Something went wrong during payment verification. Please try again.");
          }
        } catch (error) {
          console.error("Error during payment handling:", error);
          toast.error("Something went wrong during payment handling. Please try again.");
        }
      },
      modal: {
        ondismiss: () => {
          console.log("Payment popup closed");
          window.location.reload(); 
        },
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };


  



  const handleConfirmPayment = async () => {
    try {
      await handlePayment();
    } catch (error) {
      console.error("Error during payment confirmation:", error);
      toast.error("Something went wrong during payment confirmation. Please try again.");
    } finally {
      setShowPaymentPopup(false);
      setShowConfirmPaymentPopup(false);
    }
  };



  // console.log(
  //   "setPaymentResponse.value.id",
  //   paymentResponse.razorpay_payment_id
  // );
  // console.log(
  //   " paymentResponse.razorpay_order_id",
  //   paymentResponse.razorpay_order_id
  // );



  console.log(
    "{courseData.course_name}",
    courseData.course_name,
    courseData.course_description
  );

  const PaymentID = paymentResponse.razorpay_payment_id;
  const OrderID = paymentResponse.razorpay_order_id;



  // useEffect(() => {
  //   if (courseData && paymentResponse) {
  //     setPaymentStoreData({
  //       ...paymentStoreData,
  //       courseName: courseData.course_name,
  //       TransactionID: paymentResponse.razorpay_payment_id,
  //       orderID: paymentResponse.razorpay_order_id,
  //       // PaidMoney:coursePrice,
  //     });
  //   }
  // }, [courseData, paymentResponse]);





  console.log("Paid money", paymentStoreData.PaidMoney);


  useEffect(() => {

    console.log('amountToPay',amountToPay);
    setPaymentStoreData(paymentStoreData => ({
      ...paymentStoreData,
      PaidMoney: totalAmount,
    }));
  }, [totalAmount]);




  // const handlePaymentVerification = async () => {
  //   try {
  //     console.log("Sending payment verification request:", paymentStoreData);
  //     const response = await axios.post(
  //       "https://edu.d5art.com/api/course_payment",
  //       paymentStoreData
  //     );
  //     if (response.status === 200) {
  //       const result = response.data;
  //       console.log(
  //         "Payment verification response:",
  //         result.message || "Success!"
  //       );
  //     } else {
  //       console.error("Payment verification failed:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error sending payment verification request:", error);
  //   }
  // };

  // (() => {
  //   if (
  //     email &&
  //     courseId &&
  //     courseData &&
  //     courseData.course_name &&
  //     paymentData &&
  //     paymentData.amount !== undefined &&
  //     paymentSuccess === "Payment verified successfully" &&
  //     PaymentID &&
  //     OrderID
  //   ) {
  //     handlePaymentVerification();
  //   } else {
  //     console.error(
  //       "Not all necessary data is available for payment verification."
  //     );
  //   }
  // })();

  //----------------check status----

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await axios.post(
          "https://edu.d5art.com/api/checkPaymentStatus",
          { email, courseId: `${courseId}` }
        );
        console.log("response.data.PaymentStatus", response.data.PaymentStatus)
        setPaymentStatus(response.data.PaymentStatus);
        console.log("response   for data send staus", response);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };

    fetchPaymentStatus();
  }, [email, courseId]);

  console.log("payment status", paymentStatus);



  // const handleClick = () => {
  //   if (paymentStatus === "Success") {
  //   setTimeout(() => {
  //     navigate(`/course?id=${courseId}`);
  //   }, 1000);
  // } else {
  //     handlePayment();
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    window.addEventListener('contextmenu', handleContextMenu);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);




  const [users, setUsers] = useState([]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://edu.d5art.com/api/UserList?email=${email}`);
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [email]);



  let role;

  if (users.length > 0) {
    role = users[0].role;
    console.log("role", role);
  } else {
    console.error("Users array is empty. Cannot access role.");
  }











  const handleClick = async () => {
    if (paymentStatus === 'Success' || role === "skylift_user") {
      await checkPreAssessment();
    } else {

      setShowPaymentPopup(true);

      // handlePayment();
    }
  };

  const checkPreAssessment = async () => {

    console.log("checkPreAssessment")
    try {
      const response = await axios.post('https://edu.d5art.com/api/preAssessmentStatus', {
        email,
        course_id: courseId,
      });
      const data = response.data;
      console.log("checkPreAssessment", data)

      if (data.status === 'success') {
        console.log('PreAssessment:', data.data);
        navigateToCourse();
      } else {
        console.log('Complete the PreAssessment');
        setPreAssessmentPopup(true)
      }
    } catch (error) {
      console.error('Error checking PreAssessment:', error);
    }
  };


  const navigateToCourse = () => {
    setTimeout(() => {
      navigate(`/course?id=${courseId}`);
    }, 1000);



    console.log(`Navigating to /course?id=${courseId}`);
  };



  //////-------PopUP

  const Popup = () => {
    return (
      <div
        className={`fixed top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-75 flex justify-center items-center z-50`}
      >
        <div className={`bg-white p-8 rounded-lg shadow-lg  text-center `}>
          <p className="text-xl font-semibold">
            Your payment has been verified!
          </p>
          <p className="mt-4">
            Now you can start learning {courseData.course_name}.
          </p>
          <p className="mt-4">
            Ready to attend the PreAssessment of {courseData.course_name}?
          </p>
          <div className="mt-8 flex justify-between">
            <button
              className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 rounded-md bg-FABcBlue text-white hover:bg-opacity-90"
              onClick={() => {
                window.location.href = `/PreAssessment?id=${courseId}`;
              }}
            >
              Attend the PreAssessment
            </button>
          </div>
        </div>
      </div>
    );
  };
  ///---------------------crypto payment---
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCryptoPayment = () => {
    setShowPaymentPopup(false);
    setShowConfirmPaymentPopup(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const [maticPrice, setMaticPrice] = useState(0);
  const [USDPrice, setUSDPrice] = useState(0);

  useEffect(() => {
    const apiKey =
      "0b6f16b4d0563ae6f87117706bb9c15256911c5925dfa5c4a92523e269ac5a0f";

    axios
      .get(
        `https://min-api.cryptocompare.com/data/price?fsym=INR&tsyms=MATIC,USD&api_key=${apiKey}`
      )
      .then((response) => {
        if (response.data && response.data.MATIC) {
          setMaticPrice(response.data.MATIC);
          setUSDPrice(response.data.USD)
        } else {
          console.error("Invalid data received from the API:", response.data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch ETH price:", error);
      });
  }, []);

  console.log("maticPrice", maticPrice, "USD price", USDPrice);



  let from_address, key;

  if (users.length > 0) {
    console.log("users", users[0].walletAddress);

    from_address = users[0].walletAddress;
    key = users[0].key;
  } else {
    console.log(
      "Users array is empty. Ensure data is loaded before accessing properties."
    );
  }

  console.log(from_address);
  const walletAddress = from_address

  const Price = courseData.price;

  const courseName = courseData.course_name;

  const Payment_Price = maticPrice * Price + 0.005;
  console.log("Payment_Price", Payment_Price);

  console.log("Price", Price, "maticPrice", maticPrice);



  const to_address = "0x950f6DaE118aCb71bC7F5CcA5704FaC48f208253";


  const handleConfirmPaymentProcess = () => {
    setShowPaymentPopup(false);
    setOpenApplyCoupon(true);
  

    // setShowConfirmPaymentPopup(true);
  };


  //---check matic balance


  const [balanceData, setBalanceData] = useState(0);

  const [balanceTokenData, setBalanceTokenData] = useState(0);
  const API = "ZAxYaITwCFgU3zofvNdi1RmKkqyqB6QOC05jtvQxvxBikZi13oglZPPl6ZwJiVnv"





  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://deep-index.moralis.io/api/v2.2/${walletAddress}/balance?chain=polygon%20amoy`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'X-API-Key': API,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        console.log("response",response)
        const data = await response.json();
        setBalanceData(data);
      } catch (error) {
        console.error('Error fetching balance data:', error);
      }
    };

    fetchData();
  }, [API, walletAddress]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          //` https://deep-index.moralis.io/api/v2.2/${walletAddress}/balance?chain=mumbai`,
          //0x0b0B44843E4295bfC0d38df55997bA1a4e2C1A75

         `https://deep-index.moralis.io/api/v2.2/${walletAddress}/erc20?chain=polygon%20amoy&token_addresses[0]=0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'X-API-Key': API,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("setBalanceTokenData response", response)
        setBalanceTokenData(data);
        console.log("data", data)
      } catch (error) {
        console.error('Error fetching balance data:', error);
      }
    };

    fetchData();
  }, [API, walletAddress]);


  //const balance = parseFloat(balanceTokenData[0].balance || 0); // Convert balance to a float

  const balance = balanceTokenData.length > 0 ? parseFloat(balanceTokenData[0].balance || 0) : 0;

  console.log("balance",balance)
  const TokenPrice = balance / 1e18;


  console.log("balanceTokenData", balanceTokenData);

  // const TokenPrice = balanceTokenData?.[0]?.balance / 1e18;

  console.log("TokenPrice", TokenPrice);


  console.log("walletAddress", walletAddress);
  console.log("balanceData", balanceData);
  console.log("balanceTokenData", balanceTokenData)



  const MaticBalance = balanceData.balance / 1e18
  // const MaticBalance = 0

  console.log("MaticBalance", MaticBalance)





  const [openGasFeePopup, setOpenGasFeePopup] = useState(false);
  const [OpenInsufficientBalancePopup, setOpenInsufficientBalancePopup] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false)

  const [popupContent, setPopupContent] = useState({});

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);


  const handlePay = async () => {
    if (TokenPrice < (coursePrice - cryptoDiscountAmount)) {

      setIsModalOpen(false);
      setOpenInsufficientBalancePopup(true);
    }
    //  else if (MaticBalance < 0.005) {

    //   setIsModalOpen(false);
    //   setOpenGasFeePopup(true);
    // }
    
    else {
      console.log('Payment initiated!');
      handleCryptoPay();
      setIsModalOpen(false);
      setPaymentLoading(true)
    }
  };


  const handleCryptoPay = async () => {
    try {

      // const response = await axios.post("https://edu.d5art.com/api/CryptoPayment", {
      //   courseName: courseName,
      //   courseId: courseId,
      //   address: from_address,
      //   value: 0.001,
      //   mail: email,
      //   chain: "MATIC",
      //   // key: key,
      // });



      const response = await axios.post("https://edu.d5art.com/api/transfer_token", {
        courseName: courseName,
        courseId: courseId,
        address: from_address,
        amount: (coursePrice - cryptoDiscountAmount).toFixed(3),
        email: email,
        //chain: "MATIC",
        // key: key,
      });

      console.log(response.data);
      console.log(response.data.status);


      if (response.data.status === 'success') {

        setPopupContent(response.data);
        setIsModalOpen(false);


        setTimeout(() => {

          setPaymentLoading(false)
          // setShowSuccessPopup(true);


        }, 2000);

        setShowPopup(true);
      }

    } catch (error) {
      console.error("Error performing withdraw action:", error);
      toast.error("Payment failed!")
      setPaymentLoading(false)

    }
  };


  const handleCancelGasPayment =async () => {
    setOpenGasFeePopup(false);
    setOpenInsufficientBalancePopup(false);
    await window.location.reload(); 
  };




  //-----------content--

  if (!contentData) {
    return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
      {/* <Loader /> */}
      <></>
    </div>
  }

  // const renderModules = () => {
  //   return contentData.modules.map((module, index) => (
  //     <div key={index}>
  //       <h1 className="text-white-400">{module.moduleName}</h1>
  //       <h3 className="text-gray-400">{`${module.lessons} Lessons | ${module.readings} Readings | ${module.quiz} Quiz`}</h3>
  //       <p className="text-gray-300">{module.content}</p>
       
  //     </div>
  //   ));
  // };


  const renderModules = () => {
    return contentData.modules.map((module, index) => (
      <div key={index} style={{ marginBottom: '20px' }}>
        <h2 className="text-gray-200">{module.moduleName}</h2>
        {/* <h3 className="text-gray-400">{`${module.lessons} Lessons | ${module.readings} Readings | ${module.quiz} Quiz`}</h3> */}
        {/* <p className="text-gray-200">{module.content}</p> */}
        <p className="text-gray-400" style={{ whiteSpace: 'pre' }}>
          {module.content.split('\n').map((line, lineIndex) => (
            <span key={lineIndex}>
              •  {line}
              <br />
            </span>
          ))}
        </p>
      </div>
    ));
  };

  const renderRequirements = () => {
    return (
      <div>
        <h1 id="Requirements" className="text-xl font-bold">
          Requirements
        </h1>

        <ul>
          {contentData.requirements.map((requirement, index) => (
            <li key={index} className="text-gray-400">
              {requirement}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  
  const renderOutcomes = () => {
    return (
      <div>
        <h1 id="Outcomes" className="text-xl font-bold">
          Outcomes
        </h1>
        <ul>
          {contentData.outcomes.map((outcome, index) => (
            <li key={index} className="text-gray-400">
              {outcome}
            </li>
          ))}
        </ul>
      </div>
    );
  };


  //-----


  //------popup for coupon

  const handleCouponUpdate = async (couponCode) => {
    try {

      await axios.post('https://edu.d5art.com/api/couponCodeUpdate', {
        couponCode,
        email
      });

      console.log('Coupon usage updated successfully');
    } catch (error) {
      console.error('Error updating coupon usage:', error);
    }
  };





  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      toast.error('Please enter a coupon code.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`https://edu.d5art.com/api/coupon/${couponCode}/${courseId}`);
      const coupon = response.data;


      setDiscount(coupon.discount_percentage);
      setIsCouponApplied(true);
      setError(null);
      console.log(`Coupon code applied. Amount to pay: ${amountToPay}`);
    } catch (err) {
      setError(err.response.data.error || 'An error occurred');
      setDiscount(null);
      setIsCouponApplied(false);
      console.log(`No coupon code applied. Total amount: ${totalAmount.toFixed(2)}`);
    } finally {
      setIsLoading(false);
    }
  };



  // const handleCouponPay = () => {


  //   setOpenApplyCoupon(false)
  //   setShowConfirmPaymentPopup(true)
  //   // if (discount !== null) {
  //   if (isCouponApplied) {

  //     // handleCouponUpdate(couponCode)
  //     setTotalAmount(amountToPay)

  //     console.log(`Proceeding with amount to pay: ${amountToPay}`);

  //   } else {
  //     console.log(`Proceeding with total amount: ${totalAmount.toFixed(2)}`);
  //   }

  // };


  console.log("amountToPay",amountToPay)





  const handleCouponPay = () => {
    setOpenApplyCoupon(false);

    const numericAmountToPay = parseFloat(amountToPay);

    console.log("amountToPay", numericAmountToPay, "isCouponApplied", isCouponApplied);

    if (isNaN(numericAmountToPay)) {
      console.error('amountToPay is not a valid number:', amountToPay);
      toast.error('Amount to pay is invalid.');
      return;
    }

    if (isCouponApplied && numericAmountToPay === 0.00) {
      setFreeEnrollmentPopup(true);
      toast.success('You have a 100% discount! Enjoy your free enrollment.');
    } 
  else if (isCouponApplied ) {
  setShowConfirmPaymentPopup(true);
  console.log('Confirm payment popup should open');
  setTotalAmount(numericAmountToPay);
  console.log(`Proceeding with amount to pay: ${selectedCurrency} ${numericAmountToPay.toFixed(2)}`);
  toast.success(`Proceeding with discounted amount: ${selectedCurrency} ${numericAmountToPay.toFixed(2)}`);
}
 else {
      setShowConfirmPaymentPopup(true)
      console.log(`Proceeding with total amount:  ${selectedCurrency} ${totalAmount.toFixed(2)}`);
      toast.success(`Proceeding with total amount: ${selectedCurrency} ${totalAmount.toFixed(2)}`);
    }
  };




  const handleConfirmFreeEnrollment = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://edu.d5art.com/api/free-enrollment', {
        email: email,
        courseName: courseName,
        courseId: courseId,
      
      });

      if (response.data.status === "success") {
        if (isCouponApplied) {
          handleCouponUpdate(couponCode);
        }

        toast.success('You have been successfully enrolled for free!');
        setFreeEnrollmentPopup(false);
        setShowPopup(true);
      } else {
        toast.error('Failed to enroll for free course. Please try again.');
      }
    } catch (error) {
      console.error('Error enrolling for free course:', error);
      toast.error('Something went wrong while enrolling for free!');
    } finally {
      setLoading(false); 
    }
  };



  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: state.isFocused ? '#E43875' : '#E43875',
      borderColor: state.isFocused ? '#00897b' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 1px #00897b' : 'none',
      color: '#ffffff',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#27062b',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#E43875' : '#E438755c', 
      color: '#fff', 
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff', 
    }),
  };

  return (
    <div>
   



      <div className="main-container font-bricolagegrotesque bg-cover bg-center text-white bg-[#121212]" style={{ backgroundImage: "url('./Rectangle 6467.png')" }}>
      <Header />
      <Toaster />
        <div className="sec-1  flex flex-col lg:flex-row justify-between py-10">
          <div className="lg:w-[55%] lg:px-12 px-5 flex items-center">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-4xl py-2">
                {" "}
                {courseData.course_name}
              </p>
              <div className="data flex justify-between mt-2 text-sm">
                <p className="font-semibold">
                  <i className="fa-solid fa-person-chalkboard text-slate-400 mr-2" />
                  Lessons: {courseData.total_contents}
                </p>
                <p className="font-semibold">
                  <i className="fa-solid fa-trophy text-slate-400 mr-2" />
                  Level: {courseData.category}
                </p>
                <p className="font-semibold">
                  <i className="fa-solid fa-language text-slate-400 mr-2" />
                  Language: English
                </p>
              </div>
              <div className="mt-4 ">
                <p className="text-base">
                  {courseData.course_description}
                </p>
              </div>
              <div className="mt-4 hidden">
                <p className="text-[#363A3D]">Instructors</p>
                <div className="flex gap-8 mt-3">
                  <div className="flex gap-2 items-center">
                    <img src="./assets/Others/Proff.png"></img>
                    <div>
                      <p className="text-[#080808] font-bold text-lg">Adam Roosevelt</p>
                      <p className="text-gray-600 text-sm ">University of Dubai</p>
                      <p className="text-gray-600 text-sm">4 Courses</p>
                    </div>
                  </div>

                </div>
              </div>
              <div className="mt-2 flex flex-col md:flex-row gap-2 md:gap-0 mb-5 md:mb-0 justify-between">
                <div className="flex md:justify-start justify-center">
                  <button
                    onClick={handleClick}
                    className="bg-[#0087a5] px-7 py-2 text-white rounded-lg"
                  >

                    {paymentStatus === "Success" || role === "skylift_user" ? "Start Learning" : "Enroll Now"}
                  </button>
                </div>

                {paymentStatus !== "Success" && (
                  <div >
                    <div className="flex justify-end gap-3">
                    <div className="flex items-center">
                    {price ? (
                      <div>
                        <p className="text-2xl font-bold text-FABcYellow">
                          {amountToPay}
                          {/* {price * coursePrice} */}
                          {""}
                        </p>
                      </div>
                    ) : (
                      <button disabled className="bg-gray-500 text-white py-2 px-4 rounded">
                       {""}
                      </button>
                    )}
                  </div>

                      <select
                        className="text-black bg-blue-300 border border-FABcBlue px-3 py-2 w-[50%] rounded-lg outline-none"
                        value={selectedCurrency}
                        placeholder="Select Currency"
                        onChange={handleCurrencyChange}
                        
                        >
                        {CurrencyList.map(currency => (
                          <option key={currency.code} value={currency.code}>
                            {currency.code} {currency.symbol}
                          </option>
                        ))}
                      </select>

                                              
                    </div>
                    {errorMessage && (
                      <div>
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                      </div>
                    )}

                  </div>
                )}


                {showPaymentPopup && (
                  <div className="fixed top-0 left-0 w-full h-full text-white flex items-center justify-center">
                    <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
                    <div className="max-w-md p-6 bg-black rounded-lg shadow-lg z-10">
                      <h2 className="text-2xl font-bold mb-4">Payment</h2>
                      <p className="text-lg mb-4">
                        Are you sure you want to continue with the payment?
                      </p>
                      <div className=" z-20 flex justify-between">
                        <button
                          className="w-1/2 bg-black border text-white font-bold py-2 px-4 rounded-full mr-2"
                          onClick={handleCancelPayment}
                        >
                          Cancel
                        </button>
                        <button
                          className="w-1/2 bg-green-500 text-black font-bold py-2 px-4 rounded-full"
                          onClick={handleConfirmPaymentProcess}
                        >
                          Yes, Continue
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {showConfirmPaymentPopup && (
                  <div className="fixed top-0 left-0 w-full h-full text-white flex  items-center justify-center">
                    <div className="bg-black backdrop-blur-sm text-center bg-opacity-75 w-full h-full absolute"></div>
                    <div className="w-[90%] md:w-[30%] border border-gray-800 p-6  bg-black rounded-lg shadow-lg z-10">
                      <h2 className="text-2xl font-bold  text-center mb-4">Payment options</h2>
                      <p className="text-lg text-center mb-4">Choose you payment Option</p>
                      {/* <div className=" z-20 flex justify-between"> */}
                      <div className=" z-20 flex justify-center">

                        <button
                          className="w-1/2 bg-blue-500 text-black font-bold py-2 px-4 rounded-full mr-2"
                          onClick={handleConfirmPayment}
                        >
                          Fiat Payment
                        </button>


                        <button
                          className="w-1/2 bg-green-500 text-black font-bold py-2 px-4 rounded-full"
                          onClick={handleCryptoPayment}
                        >
                         D⁵Art Token Payment
                        </button> 

                      </div>
                    </div>
                  </div>
                )}


{freeEnrollmentPopup && (
        <div className="fixed top-0 left-0 w-full h-full text-white flex items-center justify-center">
          <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
          <div className="max-w-md p-6 bg-black rounded-lg shadow-lg z-10">
            <h2 className="text-2xl font-bold mb-4">Free Enrollment</h2>
            <p className="text-lg mb-4">
              You have a 100% discount! Do you want to enroll in this course for free?
            </p>
            <div className="z-20 flex justify-between">
              <button
                className="w-1/2 bg-black border text-white font-bold py-2 px-4 rounded-full mr-2"
                onClick={() => setFreeEnrollmentPopup(false)}
                disabled={loading} // Disable button when loading
              >
                No, Cancel
              </button>
              <button
                className="w-1/2 bg-green-500 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center"
                onClick={handleConfirmFreeEnrollment}
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                ) : (
                  "Yes, Enroll for Free"
                )}
              </button>
            </div>
          </div>
        </div>
      )}



                {showSuccessPopup && (
                  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                    <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
                    <div className="max-w-md p-6 bg-white rounded-lg shadow-lg z-10">
                      <h2 className="text-2xl font-bold mb-4">Payment completed successfully</h2>
                      <div className=" z-20 flex justify-between">
                        <p>Status: {popupContent.status}</p>
                        <br />
                        <p>Message: {popupContent.message}</p><br />
                        <p>Transaction Hash: {popupContent.txHash}</p><br />
                      </div>
                    </div>
                  </div>
                )}



                {preAssessmentPopup && (
                  <div className="fixed top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-black text-center">
                      <p className="text-xl font-semibold">Ready for PreAssessment</p>
                      <p className="mt-4">Now you can start learning {courseData.course_name}.</p>
                      <p className="mt-4">Ready to attend the PreAssessment of {courseData.course_name}?</p>
                      <div className="mt-8 flex justify-between">
                        <button
                          className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
                          onClick={() => setPreAssessmentPopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="px-4 py-2 rounded-md bg-FABcBlue text-white hover:bg-opacity-90"
                          onClick={() => {
                            window.location.href = `/PreAssessment?id=${courseId}`;
                          }}
                        >
                          Attend the PreAssessment
                        </button>
                      </div>
                    </div>
                  </div>
                )}







                {paymentLoading && (
                  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                    <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
                    <div className="max-w-md p-6 bg-black rounded-lg shadow-lg z-10 flex flex-col items-center">
                      <h2 className="text-2xl font-bold mb-4">Processing Payment</h2>
                      <p className="text-lg mb-4">Please do not refresh the page.</p>
                      <div className="z-20 flex justify-center items-center mb-4">
                        <svg className="w-8 h-8 animate-spin text-green-500 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {/* Optionally, provide a cancellation mechanism if needed */}
                        {/* <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded">Cancel Payment</button> */}
                      </div>
                    </div>
                  </div>
                )}





                <div
                  className={`fixed z-50 inset-0 text-black overflow-y-auto ${isModalOpen ? "block" : "hidden"  }`}
                  aria-labelledby="modal-title"
                  role="dialog"
                  aria-modal="true"
                >
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                      aria-hidden="true"
                    ></div>

                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900"
                          id="modal-title"
                        >
                          Payment Details
                        </h3>

                        {/* <div className="mt-4">
                          <label
                            htmlFor="from_walletAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            From Wallet Address:
                          </label>
                          <input
                            type="text"
                            id="from_walletAddress"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2"
                            value={from_address}
                            readOnly
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="to_walletAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            To Wallet Address:
                          </label>
                          <input
                            type="text"
                            id="to_walletAddress"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2"
                            value={to_address}
                            readOnly
                          />
                        </div> */}
                        <div className="mt-4">
                          <label
                            htmlFor="coursename"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Course Name:
                          </label>
                          <input
                            type="text"
                            id="coursename"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2"
                            value={courseName}
                            readOnly
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email:
                          </label>
                          <input
                            type="email"
                            id="email"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2"
                            value={email}
                            readOnly
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="pay_amount"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Pay  D⁵ART Tokens:
                          </label>
                          <input
                            type="number"
                            id="pay_amount"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2"
                            value={(coursePrice - cryptoDiscountAmount).toFixed(3)}
                            readOnly
                          />

                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={handlePay}
                        >
                          Pay Now
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <p className="text-sm font-bold mt-2">123 already enrolled</p> */}
              </div>
            </div>
          </div>
          <div className="lg:w-[45%] ">
          <img src={`./assets/Courses${courseData.image_url}`} alt={courseData.course_name} className="rounded-lg " />
          </div>
        </div>
        <div className="sec-2 flex justify-center">
          <div className=" flex flex-col lg:flex-row lg:gap-8 py-5">
            <a href="#courseoverview">
              {" "}
              <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                Course Overview
              </button>
            </a>

            <a href="#Modules">
              {" "}
              <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                Modules
              </button>
            </a>
            <a href="#Requirements">
              {" "}
              <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                Requirements
              </button>
            </a>
            <a href="#Outcomes">
              {" "}
              <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                Outcomes
              </button>
            </a>
            {/* <a href="#Reviews">
                {" "}
                <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                  Reviews
                </button>
              </a> */}
            <a href="#Recommended">
              {" "}
              <button className="px-3 py-2 rounded-lg bg-[#0087A533] text-[#108faa] ">
                Recommended Courses
              </button>
            </a>
          </div>
        </div>




        <div className="sec-3 p-7 lg:px-[6rem] lg:pb-[3rem]">
          <div>
            <h1 id="courseoverview" className="text-xl font-bold">
              Course Overview
            </h1>
            <h2 className="text-gray-400">{courseData.courseOverview}</h2>
          </div>


{paymentStatus === "Success" ?(
          <div className="mt-5">
            <h1 id="Modules" className="text-xl font-bold">
              Modules
            </h1>
            {renderModules()}
          </div>
):("")
}

          <div className="mt-5">{renderRequirements()}</div>
          <div className="mt-5">{renderOutcomes()}</div>
          <div className="mt-3 flex justify-center">
            <button onClick={handleClick} className="bg-[#0087a5] text-white px-5 py-2 rounded-lg">
              {/* {paymentStatus === "Success" ? "Start  Learning" : "Enroll Now"} */}
              {paymentStatus === "Success" || role === "skylift_user" ? "Start Learning" : "Enroll Now"}

            </button>
          </div>
        </div>



  


        <div className="sec-5  py-[3rem]">
          <div className="flex justify-center">
            <p id="Recommended" className="uppercase text-xl lg:text-3xl  font-bold">
              Recommendend <span className="text-[#0087a5]">courses</span>
            </p>
          </div>


          <RecommendedCourse courseId={courseId} />







        </div>
      </div>



      {isOpen5 && (
        <div className=" lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
          <div className="modal-container w-4/5 ml-10 mt-10">
            <div className="modal-content lg:w-[80%]">
              <div
                className="slide-in-from-top lg:w-[50%]   bg-cover bg-center bg-no-repeat  rounded-lg px-2 shadow-2xl pb-5 z-30  lg:absolute top-[10%] left-[25%]"
                style={{ backgroundImage: "url('/animation_ll6iy9ch 2.png')" }}
              >
                <div className="flex flex-col gap-4  pb-2  rounded-t-3xl">
                  <div className="flex items-center">
                    <img src="/Image.png"></img>
                    <div>
                      <p className="font-bold text-2xl">CONGRATULATION</p>
                      <p className="font-inriaserif">
                        Successfully enrolled in a Course
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="text-xl  font-bold flex justify-center">
                        <p className="">
                          Will Redirect to the Course in "
                          {count > 0 ? count : ""}"
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPopup && <Popup />}


      {openGasFeePopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
          <div className="max-w-md p-6 bg-white rounded-lg shadow-lg z-10">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold mb-4">Insufficient Gas Fee</h2>
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                onClick={() => handleCancelGasPayment()}

              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <p className="text-lg mb-4">
              You need at least 0.05 Matic to cover gas fees for this transaction. Please add Matic to your wallet.
            </p>
            <div className="z-20 flex justify-center">


              <NavLink to="/Wallet">   <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={handleConfirmPaymentProcess}
              >
                Add Amount
              </button> </NavLink>


            </div>
          </div>
        </div>
      )}



      {OpenInsufficientBalancePopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="bg-gray-800 bg-opacity-75 w-full h-full absolute"></div>
          <div className="max-w-md p-6 bg-black rounded-lg shadow-lg z-10">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl text-white font-bold mb-4">Insufficient Fund</h2>
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                onClick={() => handleCancelGasPayment()}

              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <p className="text-lg text-white mb-4">
              Insufficient funds to buy the course. Please make sure you have enough funds in your wallet.
            </p>

            <div className="z-20 flex justify-center">


              <NavLink to="/Dashboard/dashboard">  <button
                className="bg-FABcBlue hover:bg-FABcBlue  text-white font-bold py-2 px-4 rounded-xl"
                onClick={handleConfirmPaymentProcess}
              >
                Add Amount
              </button></NavLink>


            </div>
          </div>
        </div>
      )}


      {openApplyCoupon && (




        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-black text-center text-white font-bricolagegrotesque p-6 rounded-lg shadow-md  w-96 mx-auto">
            <h2 className="text-xl font-bold text-blue-600 mb-4">Apply Coupon</h2>

            <div className="mb-4">
              <span className="font-semibold text-gray-400">Total Amount: </span>
              {discount !== null ? (
                <span className="line-through font-bold">{currencySymbol} {" "}{totalAmount.toFixed(2)}</span>
              ) : (
                <span className="font-bold pl-2">{currencySymbol} {" "}{totalAmount.toFixed(2)}</span>
              )}
            </div>

            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                className="p-2 w-full border border-gray-800 bg-black rounded-lg outline-none "
              />
            </div>

            {/* Display applied coupon code with remove button */}
            {isCouponApplied && (
              <div className="mb-4 flex items-center justify-between">
                <span className="text-gray-700">
                  {`Coupon ${couponCode.toUpperCase()} applied`}
                </span>
                <button
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  onClick={() => {
                    setCouponCode('');
                    setDiscount(null);
                    setIsCouponApplied(false);
                  }}
                >
                  ×
                </button>
              </div>
            )}

            <div className="flex gap-3 mb-4 font-semibold">
              <div className="w-[50%]">
                <button
                  className={`bg-blue-500 w-full text-black py-2 rounded-full ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleApplyCoupon}
                  disabled={isLoading}
                >
                  Apply
                </button>
              </div>

              {/* Pay button under the Amount to Pay */}
              <div className="w-[50%]">
                <button
                  className="bg-green-500 text-black px-4 py-2 rounded-full w-full"
                  onClick={handleCouponPay}
                >
                  Pay
                </button>
              </div>

            </div>

            {discount !== null && isCouponApplied && (
              <div className="mt-4 space-y-2">
                <div>
                  <span className="font-semibold text-gray-700">Discount Applied: </span>
                  <span className="text-green-500">{discount}%</span>
                </div>
                <div>
                  <span className="font-semibold text-gray-700">Discount Amount: </span>
                  <span className="text-green-500">{currencySymbol}{" "}{discountAmount}</span>
                </div>
                <div>
                  <span className="font-semibold text-gray-700">Amount to Pay: </span>
                  <span className="text-blue-600">{currencySymbol}{" "}{amountToPay}</span>
                </div>
              </div>
            )}

            {error && (
              <p className="text-red-500 mt-4  font-semibold">{error}</p>
            )}
          </div>
        </div>





      )}

      <Footer />
    </div>
  );
}