import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './Routes/route';
import { Provider } from 'react-redux';
import { store } from './store';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'


const root = ReactDOM.createRoot(document.getElementById('root'));

const projectId = '58a936400ecc50aa6c05bd0215a14824'
 
const mainnet = {
  chainId: 42161,  
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',  
  rpcUrl: 'https://arb1.arbitrum.io/rpc'  
}
const polygon = {
  chainId: 137, 
  name: 'Polygon',
  currency: 'MATIC', 
  explorerUrl: 'https://explorer-mainnet.maticvigil.com/', 
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/', 
};

const amoy = {
  chainId: 80002, 
  name: 'Polygon Amoy Testnet',
  currency: 'MATIC', 
  explorerUrl: 'https://amoy.polygonscan.com/', 
  rpcUrl: 'https://rpc-amoy.polygon.technology/', 
};

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://ex.d5art.com', 
  icons: ['https://ex.d5art.com:4000/assets/logo.png']
}
const ethersConfig = defaultConfig({
 
  metadata,

  
  enableEIP6963: true, 
  enableInjected: true, 
  enableCoinbase: true, 
  rpcUrl: '...', 
  defaultChainId: 137, 

})
createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygon,amoy],
  projectId,
  themeVariables: {
  '--w3m-accent	':'#0db1a9',
  },
 
  enableAnalytics: true 
})

root.render(
  <Provider store={store}>
  <Router>
    <Routers/>
  </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
