
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import createHost from "cross-domain-storage/host";


const storageHost = createHost([
  {
      origin: "http://localhost:3000", // Allow access from Guest 1
      allowedMethods: ["get", "set", "remove"],
  },
  {
      origin: "http://localhost:3001", // Allow access from Guest 2
      allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: "https://edu.d5art.com", // Allow access from Guest 2
    allowedMethods: ["get", "set", "remove"],
},
{
  origin: "https://ex.d5art.com", // Allow access from Guest 2
  allowedMethods: ["get", "set", "remove"],
},
]);

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("edu_email", formData.email);
    localStorage.setItem("storage_role", "admin");
    toast.success("Data saved to host storage!");
    try {
      const response = await fetch("https://edu.d5art.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok && data.status === "Success") {
        toast.success("Login successful");
      
        
        navigate("/");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <div className="font-bricolagegrotesque py-10 md:py-0 md:h-screen flex items-center w-screen bg-[#191919] text-white">
      <Toaster />
      <div className="w-full px-10 md:px-16">
        <h1 className="text-5xl font-bold">Login</h1>
        <div className="flex justify-center">
          <div className="flex flex-col gap-5 w-[90%] md:w-[65%] bg-black/75 p-10 rounded-2xl shadow-md">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row gap-10">
                <div className="flex flex-col md:w-[50%]">
                  <label className="text-white">Email Address</label>
                  <input
                    type="email"
                    className="mt-2 text-black border-[2px] w-[100%] border-white outline-none rounded-full py-2 px-3"
                    name="email"
                    placeholder="Enter your email address"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="flex flex-col md:w-[50%]">
                  <label className="text-white">Password</label>
                  <input
                    type="password"
                    className="mt-2 text-black border-[2px] w-[100%] border-white outline-none rounded-full py-2 px-3"
                    name="password"
                    placeholder="Enter your password"
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center py-5 px-3">
                <button
                  className="text-white bg-FABcBlue rounded-full w-[50%] py-2"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div className="grid gap-5 content-between justify-items-center">
                <a href="/ForgetPassword" className="text-sky-300 text-sm">
                  Forget password?
                </a>
                <p className="text-sm text-center text-white">
                  Don't have an Account yet?{" "}
                  <NavLink to="/Register" className="text-FABcYellow">
                    Register Now
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

