import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
// import './seeker.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import Spinners from '../Loader/Spinners';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from '../Loader/Loading';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { setRecruiterEmail} from '../store';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'js-cookie';
import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';
import { GoogleLogin } from 'react-google-login';


import CountryList from "../Config/Json/CountryCode.json";
import StateList from "../Config/Json/States.json";



export default function Register() {
  

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedAvatar, setSelectedAvatar] = useState('avatar1');

    const { disconnect } = useDisconnect();

    const { address, chainId, isConnected } = useWeb3ModalAccount();

    console.log("address, chainId, isConnected", address, chainId, isConnected);


    const sliderRef = useRef(null);
    const sliderRef1 = useRef(null);
  

    const handleSlideChange = (index) => {
        setCurrentStep(index + 1);
    };

    const handleSlideChange1 = (index) => {
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange,
    };
    const customPaging = (i) => (
        <div className={`dot ${currentStep === i + 1 ? 'active' : ''}`}></div>
    );
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange1,
        customPaging: customPaging,
    };
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption);

    };

    const handleStateChange = (event) => {
        const selectedStateName = event.target.value;
        setSelectedState(selectedStateName);

    };

   
    const [state, setState] = useState([]);

    const [selectedState, setSelectedState] = useState('');
  

    const countryOptions = CountryList;

    useEffect(() => {

        setState(StateList);
    }, []);
 

    ///----------opt

    const [isOtpOpen, setIsOtpOpen] = useState(false);

    const generateOtp = async (email) => {
        try {


            const response = await axios.post(
                "https://ex.d5art.com/api/generate-email-otp",
                { email },
                { headers: { "Content-Type": "application/json" } }
            );

            if (response.data.success) {
                toast.success(
                    "OTP generated successfully! Check your phone for the OTP."
                );
                setIsOtpOpen(true);
            } else {
                toast.error("Failed to generate OTP. Please try again.");
            }
        } catch (error) {
            console.error("Error generating OTP:", error);
            toast.error("Error generating OTP. Please try again.");
        }
    };

   
    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
    };


     


    const [selectedstate, setSelectedSate] = useState(null);

    const handleChanges1 = (selectedOption) => {
        setSelectedSate(selectedOption);
    };

    const stateOptions = StateList;
  
    
    const [isTransformed, setIsTransformed] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    const toggleModal = () => {
        setIsLoading1(true);
        navigate('/');
        if (isOpen) {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(false);
                setIsTransformed(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(true);
                setIsTransformed(true);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsTransformed(true);
        } else {

            setIsTransformed(false);
        }
    }, [isOpen]);
    const [active, setActive] = useState('div1');

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };
    // const [isOpen5, setIsOpen5] = useState(false);
    // const togglemodal5 = () => {
    //     setIsOpen5(!isOpen5);

    // };
    const [isOpenRecruiter, setIsOpenRecruiter] = useState(false);
    const toggleRecruiter = () => {
        setIsOpenRecruiter(!isOpenRecruiter);

    };


    

    




    const navigate = useNavigate();


    const handleClick2 = async () => {

        if (!recruiterLoginValidate()) {
            return;
        }

        setIsOpen(!isOpen);
         setIsLoading(true);
        recruiterLoginSubmit();

    };




    const avatarSources = ['./assets/Mascots/image 90.png', './assets/Mascots/image 90 (1).png', './assets/Mascots/image 3yr.png'];
    const serveravatar = ['./pictures/image 90.png', './pictures/image 90 (1).png', './image 3yr.png']

      

    const [isOpenOtpRecruiter, setOpenOtpRecruiter] = useState(false);
  

    const handleOtpRecruiter = () => {
        setOpenOtpRecruiter(!isOpenOtpRecruiter)
    }

    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const [email, setEmail] = useState('');
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };


    const [passwordVisibleRecruiter, setPasswordVisibleRecruiter] = useState(false);
    const [passwordRecruiter, setPasswordRecruiter] = useState('');
    const [confirmPasswordRecruiter, setConfirmPasswordRecruiter] = useState('');



    const handlePasswordRecruiter = () => {
        setPasswordVisibleRecruiter(!passwordVisibleRecruiter);
    };


    const [emailOTPRecruiter, setEmailOTPRecruiter] = useState(['', '', '', '']);
    const [isButtonEnabledRecruiter, setButtonEnabledRecruiter] = useState(false);
    const inputRefsRecruiter = useRef(Array(4).fill(null));

    const handleOTPRecruiter = (index) => (e) => {
        const value = e.target.value;

        const updatedEmailOTP = [...emailOTPRecruiter];
        updatedEmailOTP[index] = value;
        setEmailOTPRecruiter(updatedEmailOTP);

        if (value.length === 1 && index < 3) {
            inputRefsRecruiter.current[index + 1].focus();
        }

        setButtonEnabledRecruiter(updatedEmailOTP.join('').length === 4);
    };







    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNextClick1 = async () => {
        if (currentIndex === 0) {
            try {
                await axios.post('https://ex.d5art.com/api/resetEmployerPasswordOtp', { email });
                setCurrentIndex(1);
                toast.success("OTP sent successfully. Please check your email.");
            } catch (error) {
                console.error('Error sending OTP:', error);
                toast.error(error.response?.data?.message || "Failed to send OTP. Please try again.");
            }
        } else if (currentIndex === 1) {
            if (passwordRecruiter !== confirmPasswordRecruiter) {
                toast.error("Password and confirm password do not match");
                return;
            }

            const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,}$/;
            if (!passwordRecruiter.match(passwordRegex)) {
                toast.error("Password must contain at least one number, one letter, and one special character");
                return;
            }

            try {
                const response = await axios.post('https://ex.d5art.com/api/verifyEmployerOTPAndUpdatePassword', {
                    email,
                    emailOtp: emailOTPRecruiter.join(''),
                    password: passwordRecruiter,
                });

                if (response.data.status === "success") {
                    console.log(email, emailOTPRecruiter, passwordRecruiter);
                    console.log("emailotp", emailOTPRecruiter.join(''));
                    toast.success("Password reset is successful");
                    setTimeout(() => {
                        navigate('/Register');
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error("Password reset is unsuccessful");
                }
            } catch (error) {
                console.error('Error verifying OTP and updating password:', error);
                toast.error(error.response?.data?.message || "Invalid OTP");
            }
        }
    };







    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex));
    };




 







    // Recruiter registration
    // Recruiter personal Information



    const [personalInfo1, setPersonalInfo1] = useState({
        name: '',
        email: '',
        country: '',
        countryCode: '',
        state: '',
        mobileNumber: '',
        avatar: './assets/Mascots/image 90.png',
        description: '',
        password: '',
        confirmpassword: '',

    });

    console.log('personalInfo1', personalInfo1);

    const [filteredStateOptions, setFilteredStateOptions] = useState([]);
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handleInputChange4 = (section, field, value) => {
        switch (section) {
            case 'personalInfo1':
                if (field === 'avatar') {
                    setSelectedAvatar(value);
                } else if (field === 'country' || field === 'state' || field === 'countryCode') {
                    if (Array.isArray(value)) {
                        const selectedValues = value.map(option => option.label);
                        setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: selectedValues }));
                    } else {
                        if (field === 'country') {
                            setPersonalInfo1((prevInfo) => ({
                                ...prevInfo,
                                country: value.label,
                                countryCode: value.value
                            }));

                            const filteredStates = stateOptions.filter(state => state.country_name === value.label);
                            console.log("filtered states", filteredStates);
                            setFilteredStateOptions(filteredStates);
                            setPersonalInfo1((prevInfo) => ({ ...prevInfo, state: '' }));
                        } else if (field === 'state') {
                            setPersonalInfo1((prevInfo) => ({ ...prevInfo, state: value.name }));
                        } else if (field === 'countryCode') {
                            setPersonalInfo1((prevInfo) => ({ ...prevInfo, countryCode: value.value }));
                        } else {
                            setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value.label || value.value }));
                        }
                    }
                } else if (field === 'password' || field === 'confirmpassword') {
                    setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value }));
                    setPasswordsMatch(value === personalInfo1.confirmpassword || field === 'confirmpassword' && personalInfo1.password === value);
                } else {
                    setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value }));
                }

            default:
                break;
        }
    };


    const handleSliderAfterChange1 = (index) => {
        const avatarIndex = index + 1;
        const newAvatar = `avatar${avatarIndex}`;
        const avatarImageSrc = avatarSources[index];
        console.log("newavatar", newAvatar);
        setSelectedAvatar(newAvatar);

        setPersonalInfo1((prevInfo) => ({
            ...prevInfo,
            avatar: avatarImageSrc,
        }));
    };



    const handleSubmit1 = (e) => {
        e.preventDefault();
        console.log('Personal Info:', personalInfo1);

        if (!passwordsMatch) {
            console.error('Passwords do not match');
            return;
        }

    };

    const recruiterValidatePersonalInfo = () => {

        for (const key in personalInfo1) {
            if (personalInfo1.hasOwnProperty(key) && (Array.isArray(personalInfo1[key]) ? personalInfo1[key].length === 0 : personalInfo1[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(personalInfo1.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,}$/;
        if (!passwordRegex.test(personalInfo1.password)) {
            toast.error("Password must contain at least one number, one letter, and one special character, and be at least 8 characters long.");
            return false;
        }

        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }

        return true;
    };



    const submit = async () => {
        try {
            console.log("submit111111", personalInfo1);

            if (!recruiterValidatePersonalInfo()) {
                return;
            }


            const response = await axios.post('https://ex.d5art.com/api/getRecruiterDetails', personalInfo1);
            console.log(response.data);

            toast.success('Data stored successfully!');

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }



        } catch (error) {
            console.error(error);

            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('An error occurred. Please try again.');
            }
        }
    };






    // Recruiter organization details

    const [organizationDetails, setorganizationDetails] = useState({
        organizationName: '',
        organizationDescription: '',
        industryType: '',
        websiteURL: '',
        yourDesignation: '',
        walletAddress: '',
    });
    console.log('organizationDetails', organizationDetails, address);

    useEffect(() => {
        if (isConnected === true) {
            setorganizationDetails(prevDetails => ({
                ...prevDetails,
                walletAddress: address,
            }));
        }

    }, [address]);

    const handleInputChange5 = (section, field, value) => {
        switch (section) {
            case 'organizationDetails':
                if (field === 'organizationName') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        organizationName: value,
                    }));
                } else if (field === 'organizationDescription') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        organizationDescription: value,
                    }));
                } else if (field === 'industryType') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        industryType: value,
                    }));
                } else if (field === 'websiteURL') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        websiteURL: value,
                    }));
                } else if (field === 'yourDesignation') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        yourDesignation: value,
                    }));
                }
                break;
            default:
                break;
        }
    };
    const handleSubmit2 = async (e) => {
        e.preventDefault();
        console.log('setorganizationDetails:', organizationDetails);


        console.log('Temp Name and Email:', tempNameAndEmail);


    };


    const [tempNameAndEmail, setTempNameAndEmail] = useState({

        name: '',
        email: '',
    });



    const recruiterValidateOrganizationDetails = () => {

        if (!recruiterValidatePersonalInfo()) {
            return;
        }

        for (const key in organizationDetails) {
            if (key === 'walletAddress') continue;
            if (organizationDetails.hasOwnProperty(key) && (Array.isArray(organizationDetails[key]) ? organizationDetails[key].length === 0 : organizationDetails[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }

        console.log("address", address)


        return true;
    };


    const submit1 = async () => {
        console.log('5555555', tempNameAndEmail, organizationDetails);

        try {
            if (!recruiterValidateOrganizationDetails()) {
                return;
            }

            const generatedWalletAddress = await generate_wallet_address();

            const response = await fetch('https://ex.d5art.com/api/getRecruiterOrgDetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ organizationDetails, email: personalInfo1.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            toast.success('Registered successfully!');
            window.location.href = '/Register';

        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('Wallet address already exists');
            }
        }
    };


    const generate_wallet_address = async () => {
        console.log('5555555', tempNameAndEmail);

        try {

            if (!personalInfo1.email) {

                toast.error('Email ID not found!');

                return;
            }
            const response = await fetch('https://ex.d5art.com/api/generateWalletAddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: personalInfo1.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setTempNameAndEmail((prevState) => ({
            ...prevState,
            name: personalInfo1.name,
            email: personalInfo1.email,
        }));
        console.log('3333333', personalInfo1.name)
    }, [personalInfo1.name, personalInfo1.email]);


    useEffect(() => {
        console.log("Submitting tempNameAndEmail:", tempNameAndEmail);
    }, [tempNameAndEmail]);











  

    // recruiter login


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const dispatch = useDispatch();
  

    const [recruiterLogin, setRecruiterLogin] = useState({
        email: '',
        password: '',
    });



    console.log('recruiterLogin', recruiterLogin);

    const handleInputChange8 = (section, field, value) => {
        switch (section) {
            case 'recruiterLogin':
                if (field === 'email') {
                    setRecruiterLogin((prevDetails) => ({
                        ...prevDetails,
                        email: value,
                    }));
                } else if (field === 'password') {
                    setRecruiterLogin((prevDetails) => ({
                        ...prevDetails,
                        password: value,
                    }));
                }
                break;
            default:
                break;
        }
    };

    // validation 
    const recruiterLoginValidate = () => {
        if (!recruiterLogin.email || !recruiterLogin.password) {
            toast.error('Email and password are required.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(recruiterLogin.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        return true;
    };

    const handleSubmit5 = async (e) => {
        e.preventDefault();

        if (!recruiterLoginValidate()) {
            return;
        }

        const response = await recruiterLoginSubmit();

        if (response.status === 'success') {
            console.log('Login successful. Redirecting to the home page.');

            Cookies.set('authToken', response.data.token, { expires: 1 });

            const userEmail = response.data.user.email;
            setRecruiterLogin({ email: '', password: '' });
            setIsLoggedIn(true);
            fetchUserDetails(userEmail);
            window.location.href = '/RMS/Recruiter/';

        } else {
            console.log('Login failed. Display an error message or stay on the same page.');

        }
    };





    // recruiter login
    const recruiterLoginSubmit = async () => {
        console.log('submit111111', recruiterLogin);
        try {
            console.log('submit123123213', recruiterLogin);

            if (!recruiterLogin.email || !recruiterLogin.password) {
                console.log('Email and password are required.');
                toast.error('Email and password are required.');
                return { status: 'error' };
            }

            const response = await axios.post('https://ex.d5art.com/api/login', recruiterLogin);
            console.log('3333333333', response);

            if (response.data.status === 'success') {
                toast.success('Welcome to D5Art!');
                // dispatch(setUserEmail(formData.email));
                setTimeout(() => {
                    dispatch(setRecruiterEmail(recruiterLogin.email));
                    navigate('/RMS/Recruiter/');
                }, 2000);
                console.log('rrrrrrrrrrrrrrrrrr', recruiterLogin.email)

                console.log('Login successful.');
                return response;
            } else {
                console.log('Login failed.');
                toast.error('OOPS!! Login failed');
                return { status: 'error' };
            }
        } catch (error) {
            console.error(error);
            toast.error('OOPS!! Login failed try correct credentials');

            setTimeout(() => {

                window.location.href = '/Register';

            }, 3000);
            return { status: 'error' };
        } finally {

            setTimeout(() => {
                setIsLoading(false);


            }, 3000);
        }
    }



    const fetchUserDetails = async (email) => {
        try {
            const response = await axios.get(`https://ex.d5art.com/api/login/${email}`);
            console.log(response.data);
            if (response.data.status === 'success') {
                const userName = response.data.user.name;
                const userEmail = response.data.user.email;
                setUserInfo({ name: userName, email: userEmail });
            } else {
                console.log('Failed to fetch user details.');
            }
        } catch (error) {
            console.error(error);
        }
    };


    const handleRecruiterWalletLogin = async () => {

        if (!isConnected) {
            console.log('Please connect your wallet first');
            return;
        }

        console.log("address", address)
        try {
            const response = await axios.post('https://ex.d5art.com/api/recruiter_walletAddress_login', { WalletAddress: address });


            console.log("handleRecruiterWalletLogin", response)

            console.log('response', response)
            const { email, accessToken, message } = response.data;

            if (message === "exists") {
                toast.success('Welcome to D5Art!');

                

                setTimeout(() => {
                    dispatch(setRecruiterEmail(email));
                    navigate('/RMS/Recruiter/');
                }, 2000);

            }

            else {
                toast.error('Please register your wallet address');
            }
        } catch (error) {
            console.error('Error checking wallet address:', error);
        }
    };







    const handleWalletLogOut = async () => {

        if (!isConnected) {
            toast.error("connect you wallet")
            return
        }


        disconnect()
    }



    //////----google login -------



    const [isGoogleRegister, setIsGoogleRegister] = useState(false);

    const [googleUser, setGoogleUser] = useState(null);

    //const clientId ="678267799693-nb358u8abna1booq6ft20jgnmth8iffv.apps.googleusercontent.com";


    const clientId = "678267799693-nb358u8abna1booq6ft20jgnmth8iffv.apps.googleusercontent.com";


    const handleGoogleSignIn_recruiter = async (res) => {
        console.log("SignUp with Google button clicked");
        setIsGoogleRegister(true);

        if (res && res.profileObj) {
            const email = res.profileObj.email;

            console.log('Google Login Response:', res);
            console.log("isGoogleRegister onSuccess", isGoogleRegister);

            setGoogleUser(res.profileObj);

            try {
                const response = await axios.post("https://ex.d5art.com/api/google_recruiter_login", { email });
                const { accessToken, message } = response.data;

                console.log("API Response:", response);
                if (message === "exists") {
                    toast.success("Welcome to FABC!");

                   

                    setTimeout(() => {
                        dispatch(setRecruiterEmail(email));
                        navigate('/RMS/Recruiter/');
                    }, 2000);
                } else {
                    toast.error("Email does not exist. Please register.");
                }
            } catch (error) {
                console.error("Error during Google register check:", error);
                toast.error("Error during Google register check. Please try again later.");
            }
        } else {
            console.error("Google login response does not contain profile information.");
            //  toast.error("Google sign-in failed. Please try again.");
            setIsGoogleRegister(false);
        }
    };



    const onFailure = (error) => {
        console.error("Google sign-up failed:", error);
        setIsGoogleRegister(false);

    };

    


    // navigate to respective user registration

    const handleRedirectSeeker = () => {
        navigate('/seeker/registration')

    };

    const handleRedirectRecruiter = () => {
        navigate('/recruiter/registration')

    };


    const handleRedirectValidator = () => {
        navigate('/validator/registration')

    };





    return (
        <div className='bg-[#101010] text-white h-screen font-poppins bg-cover' style={{ backgroundImage: `url(/assets/BG/Loginbg.png)` }}>
            <Toaster />
            {isLoading1 && (
                <div className=''>
                    <div className='z-100'>
                        <Loading isVisible1={isLoading1} />
                    </div>
                </div>
            )}
            {isOpen && (

                <div className=''>
                    <div className='z-100'>
                        <Spinners isVisible={isLoading} />
                    </div>
                    <div className='px-7 md:px-10 rounded-2xl z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 overflow-y-auto'>
                        <div className='md:w-[15%] flex justify-center md:justify-end'>
                            <img src='/assets/Others/LeftSide.png' className='rotate-90 md:rotate-0'></img>
                        </div>
                        <div className={`${isTransformed ? 'md:w-[70%]' : 'border-0 w-0  '} z-10  backdrop-blur-sm bg-transparent transform-transition duration-1000 ease-out-in rounded-3xl`}>
                            <div className='flex justify-center py-4'>
                                <p className='text-2xl text-white'>Login As</p>
                            </div>
                            <div>
                                <div className=' md:px-10 w-[100%] flex flex-col md:flex-row justify-center gap-2'>
                                    <div className='group md:w-[20%] border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  group-hover:transition-transform duration-500 ' >
                                        <div className='flex justify-center py-5 md:py-2 text-white '>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? 'hidden' : ''}`} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectSeeker();
                                             
                                            }}>Seeker </a>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? '' : 'hidden'}`} id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectRecruiter();
                                            
                                            }}>Recruiter </a>
                                        </div>
                                    </div>
                                    <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} >
                                        <div className='flex justify-end '>
                                            <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                                        </div>
                                        <form onSubmit={handleSubmit5}>
                                            <div className='flex justify-center py-2 text-white'>
                                                <p>Recruiter Login</p>
                                            </div>
                                            <div className='flex flex-col gap-3 px-7 md:px-12 mt-3 '>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full' placeholder='Enter your mail ID'
                                                        required
                                                        onChange={(e) => handleInputChange8('recruiterLogin', 'email', e.target.value)}></input>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        className='bg-black bg-opacity-5 text-center text-white outline-none py-2 w-[95%] '
                                                        placeholder='Enter your Password'
                                                        required
                                                        onChange={(e) => handleInputChange8('recruiterLogin', 'password', e.target.value)}></input>
                                                        <i
                                                            className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
                                                            onClick={handleTogglePasswordVisibility}
                                                        ></i>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtpRecruiter}>Forget password</button></div>

                                                <div className='flex justify-center mt-3'>
                                                    <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
                                                        handleClick2();
                                                    }}>
                                                        <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>

                                                </div>

                                                <div className='flex justify-center '>
                                                    <p className='text-white font-bold text-sm'>or</p>
                                                </div>

                                                <div className="flex flex-col md:flex-row justify-center gap-2 md:pl-5">
                                                    <div className='flex justify-center'>
                                                        <div className="flex items-center" onClick={handleRecruiterWalletLogin}>
                                                            <w3m-button
                                                                balance="hide" />
                                                        </div>

                                                        <>
                                                            {isConnected && (
                                                                <button onClick={handleWalletLogOut}>
                                                                    <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                </button>
                                                            )}
                                                        </>

                                                    </div>

                                                    <div className='flex justify-center'>
                                                        <div className="md:w-[1px] md:h-10 bg-white/50"></div>
                                                    </div>

                                                    <div className='flex justify-center'>
                                                        <div className='flex justify-center overflow-hidden rounded-full' onClick={handleGoogleSignIn_recruiter}>
                                                            <GoogleLogin
                                                                clientId={clientId}
                                                                buttonText="Signin as Recruiter"
                                                                onSuccess={handleGoogleSignIn_recruiter}
                                                                onFailure={onFailure}
                                                                cookiePolicy={"single_host_origin"}
                                                                isSignedIn={false}
                                                                prompt="select_account"

                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center'>
                                                    <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' onClick={toggleRecruiter}>Create new account</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                
                          
                                    <div className='group md:w-[20%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 ' >
                                        <div className='flex justify-center py-5 md:py-2 text-white'>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? 'hidden' : ''}`} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectValidator();
                                          
                                            }}>Validator</a>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? '' : 'hidden'}`} id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectRecruiter();
                                          
                                            }}>Recruiter </a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='md:w-[15%] flex justify-center md:justify-start'>
                            <img src='/assets/Others/RightSide.png' className='rotate-90 md:rotate-0'></img>
                        </div>
                    </div>
                    <div className='px-28 py-10 flex justify-center -translate-y-[27rem] '>
                        <div className='rounded-full p-6 border-[6px] border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_10px_#08f,0_0_10px_#08f]'>
                            <img src='assets/Logo/D5ART_Fav icon.png' ></img>
                        </div>
                    </div>
                </div>
            )}
   
            {isOpenOtpRecruiter && (
                <div className="rounded-2xl overflow-y-scroll z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
                    <div className="modal-container w-[40%] absolute top-1">
                        <div className="modal-content  ">
                            <div className='slide-in-from-top1' >
                                <div>
                                    <div>
                                        <div className=' items-center'>
                                            <div className=''>
                                                <p className='text-[#0ea4a9] font-bold text-center mt-20'>Recruiter Registration</p>
                                            </div>
                                            <div className='flex justify-end h-7'>
                                                <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleOtpRecruiter}><i class="fa-solid fa-xmark " ></i></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 mb-20">
                                        <div className="flex justify-between mt-5">
                                            <button onClick={handlePrevClick}>
                                                <i className="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                                            </button>
                                            <div className="p-5 w-[85%] rounded-3xl bg-white/5 grid gap-3">
                                                {currentIndex === 0 && (
                                                    <div className={`content${currentIndex + 1}`}>
                                                        <div className='content1 p-5 '>
                                                            <div className='w-full flex flex-col'>
                                                                <div className='flex border-b border-gray-500  '>
                                                                    <div className='flex items-end '>
                                                                        <div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                    </div>
                                                                    <div className='w-[98%]'>
                                                                        <input
                                                                            type="email"
                                                                            className='bg-black bg-opacity-5 pl-5 text-white outline-none py-2 w-full'
                                                                            placeholder='Enter your email'
                                                                            value={email}
                                                                            onChange={handleEmailChange}
                                                                        />
                                                                    </div>
                                                                    <div className='flex items-end'>
                                                                        <div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-center pt-5'>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: '#2563eb',
                                                                            color: 'white',
                                                                            padding: '7px 20px',
                                                                            border: 'none',
                                                                            borderRadius: '50px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={handleNextClick1}
                                                                    >
                                                                        Verify<i className="fa-solid fa-circle-check ml-2" style={{ color: "#ffffff" }}></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {currentIndex === 1 && (
                                                    <div className={`content${currentIndex + 1} `}>
                                                        <div className='content2 p-5'>
                                                            <div className='flex justify-center '>
                                                                <div className='flex flex-col gap-5 w-full'>
                                                                    <div className='flex flex-col gap-3'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisibleRecruiter ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='New Password'
                                                                                    value={passwordRecruiter}
                                                                                    onChange={(e) => setPasswordRecruiter(e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisibleRecruiter ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handlePasswordRecruiter}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={confirmPasswordRecruiter}
                                                                                    onChange={(e) => setConfirmPasswordRecruiter(e.target.value)}
                                                                                />
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center px-5'>
                                                                        <p className='text-center'>Kindly refer to your mail we have sent an OTP</p>
                                                                    </div>
                                                                    <div className='flex justify-center gap-4 '>
                                                                        {emailOTPRecruiter.map((otp, index) => (
                                                                            <input
                                                                                key={`emailRecruiter-${index}`}
                                                                                type="text"
                                                                                className="bg-gray-300 text-black pl-3 py-1 w-[2.5rem] text-xl rounded-xl"
                                                                                maxLength={1}
                                                                                value={otp}
                                                                                onChange={handleOTPRecruiter(index)}
                                                                                ref={(input) => (inputRefsRecruiter.current[index] = input)}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                    <div className='flex justify-center'>
                                                                        <button
                                                                            onClick={handleNextClick1}
                                                                            disabled={!isButtonEnabledRecruiter}
                                                                            style={{
                                                                                backgroundColor: isButtonEnabledRecruiter ? '#16a34a' : '#2563eb',
                                                                                color: 'white',
                                                                                padding: '7px 20px',
                                                                                border: 'none',
                                                                                borderRadius: '50px',
                                                                                cursor: isButtonEnabledRecruiter ? 'pointer' : 'not-allowed',
                                                                            }}
                                                                        >
                                                                            Verify <i className="fa-solid fa-circle-check text-[#ffffff]"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <button onClick={handleNextClick1}>
                                                <i className="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
          
           
                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>
          
            <div className='' >
                {isOpenRecruiter && (
                    <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
                        <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
                            <div className="modal-content">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Recruiter Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleRecruiter}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="roadmap">
                                            <button className={currentStep === 1 ? 'active' : ''}><img src='/assets/Others/block.png'></img></button>
                                            <p className='text-white pt-3 w-[75%] md:w-auto flex'>-----------------<span className='hidden md:block'>----</span></p>
                                            <button className={currentStep === 2 ? 'active' : ''}><img src='/assets/Others/block.png'></img></button>
                                        </div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit1(e);
                                            handleSubmit2(e);
                                        }}>
                                            <div className=' mt-4 mb-20'>

                                                <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
                                                    <div className='flex justify-center' >
                                                        <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                        <div className='flex flex-col md:flex-row gap-5 md:gap-9'>
                                                            <div className='md:w-[60%] flex items-center'>
                                                                <div className='w-full flex flex-col  gap-5 md:gap-14'>
                                                                    <div className='flex flex-col gap-5 md:gap-14 px-7 md:px-12  '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter your Name'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'name', e.target.value)} ></input>
                                                                                <i class="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>

                                                                        </div>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    type='email'
                                                                                    placeholder='Enter your mail ID'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'email', e.target.value)} ></input>
                                                                                <i class="fa-solid fa-envelope text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col md:flex-row  gap-4 mt-5 px-7 md:px-12'>
                                                                        <div className='md:w-[50%]'>
                                                                            <Select
                                                                                value={countryOptions.find(option => option.label === personalInfo1.country)}
                                                                                onChange={(selectedOption) => {
                                                                                    handleInputChange4('personalInfo1', 'country', selectedOption);
                                                                                    handleInputChange4('personalInfo1', 'countryCode', selectedOption);
                                                                                }}
                                                                                options={countryOptions}
                                                                                placeholder='Country'
                                                                                styles={customStyles}
                                                                                getOptionLabel={(option) => `${option.label} (${option.value}) ${option.country_flag}`}
                                                                                getOptionValue={(option) => option.label}
                                                                            />
                                                                        </div>
                                                                        <div className='md:w-[50%]'>
                                                                            <Select
                                                                                value={filteredStateOptions.find(option => option.name === personalInfo1.state)}
                                                                                onChange={(selectedOption) => handleInputChange4('personalInfo1', 'state', selectedOption)}
                                                                                options={filteredStateOptions}
                                                                                placeholder='State'
                                                                                styles={customStyles}
                                                                                getOptionLabel={(option) => option.name}

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter your Mobile number'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'mobileNumber', e.target.value)}   ></input>
                                                                                <i class="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisible ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter Password'
                                                                                    value={personalInfo1.password}

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'password', e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handleTogglePasswordVisibility}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={personalInfo1.confirmpassword}

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'confirmpassword', e.target.value)}
                                                                                />
                                                                                {passwordsMatch ? (
                                                                                    <i className='fa-solid fa-check text-green-500 text-xl mt-3'></i>
                                                                                ) : (
                                                                                    <i className='fa-solid fa-times text-red-500 text-xl mt-3'></i>
                                                                                )}

                                                                            </div>

                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className='md:w-[30%]'>

                                                                <Slider className='bg-[#111111] w-[75%] md:w-[100%] mx-auto'
                                                                    ref={sliderRef1}
                                                                    {...settings1}
                                                                    afterChange={(index) => handleSliderAfterChange1(index)}>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar1')}>
                                                                        <img className='px-3 md:ml-10 2xl:ml-20' src='/assets/Mascots/image 90.png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar2')}>
                                                                        <img className='px-3' src='/assets/Mascots/image 90 (1).png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar3')}>
                                                                        <img className='px-3' src='/assets/Mascots/image 3yr.png'></img>
                                                                    </div>

                                                                </Slider>
                                                                <p className='text-center text-white'>Choose Avatar</p>
                                                                <div className='flex justify-center'>
                                                                    <textarea type="text" placeholder='About the YourSelf...' className='w-[90%] md:mx-0 md:w-full rounded-[20px] outline-none bg-[#090909] h-28  mt-5 border border-sky-700 inner-shadow2 p-3 text-sm'
                                                                        onChange={(e) => handleInputChange4('personalInfo1', 'description', e.target.value)}></textarea>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='flex justify-center mb-5 mt-5 2xl:mt-0'>
                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {

                                                                submit();
                                                            }}>
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'  ></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out' >Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className='flex justify-center p-5'>
                                                        <p className='text-white text-center text-2xl font-play '>Organization Details</p>
                                                        <div className='flex flex-col gap-5 md:flex-row pt-10'>
                                                            <div className='md:w-[55%] flex flex-col gap-3 px-2 md:px-10 '>
                                                                <p className='text-sm'>Organization Name:</p>
                                                                <input type='text' placeholder='Enter your Company name' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                    onChange={(e) => handleInputChange5('organizationDetails', 'organizationName', e.target.value)}></input>
                                                                <textarea type="text" placeholder='About the Company...' className='w-full rounded-[20px] outline-none bg-[#090909] h-full mt-2 md:mt-5 border border-sky-700 inner-shadow2 p-5 text-sm'
                                                                    onChange={(e) => handleInputChange5('organizationDetails', 'organizationDescription', e.target.value)}></textarea>
                                                            </div>
                                                            <div className='md:w-[45%] flex flex-col gap-7'>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Industry type:</p>
                                                                    <input type='text' placeholder='Select from below' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'industryType', e.target.value)}></input>
                                                                </div>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Website URL:</p>
                                                                    <input type='text' placeholder='Enter your URL' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'websiteURL', e.target.value)}></input>
                                                                </div>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Your Designation:</p>
                                                                    <input type='text' placeholder='Enter your designation' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'yourDesignation', e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-4'>
                                                            <p className='text-white font-bold'>Connect your wallet (optional) </p>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-2'>
                                                            <div onClick={handleInputChange5}
                                                                required>
                                                                <w3m-button
                                                                    balance="hide" />
                                                            </div>

                                                            <>
                                                                {isConnected && (
                                                                    <button onClick={handleWalletLogOut}>
                                                                        <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                    </button>
                                                                )}
                                                            </>

                                                        </div>


                                                        <div><img className='md:absolute md:translate-x-[50rem] 2xl:translate-x-[65rem] 2xl:translate-y-10 mt-5 md:mt-0 mx-auto md:mx-0 w-[75%] md:w-auto' src='/assets/Mascots/image 90.png' ></img></div>

                                                        <div className='flex  justify-center mt-5 md:mt-16'>
                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={async () => {
                                                              
                                                                submit1();
                            
                                                            }}>

                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[128px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'  ></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out' >Register</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>

                                                            </button>

                                                        </div>
                                                    </div>
                                                </Slider>



                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>
            </div>
          


        </div>

    )
}