// import React from "react";
// import Header from "../Layout/LoginHeader";
// import Footer from '../../../Layout/Footer';

// export default function Faq() {
//   return (
//     <div className="font-bricolagegrotesque text-white bg-[#191919]" style={{ backgroundImage: "url('/assets/BG/FABC.png')" }}>
//       <Header />
//       <div className="main-container mb-12">
//         <div className=" py-7">
//           <p className="text-3xl font-bold text-center">FAQ</p>
//         </div>
//         <div className="flex justify-center ">
//           <div className="w-[70%]">
//             <div>
//               <p className="font-bold text-xl">What is FABC?</p>
//             </div>
//             <div>
//               <p className="text-sm mt-5">
//                 FABC is the world's first native Web3 unbiased Skill Exchange platform. Our mission is to bridge the gap between education and employment by leveraging Blockchain technology and AI. We offer a decentralized and transparent system where learners can acquire and validate skills through blockchain-based NFT CVs. These tokenized skill certifications ensure individual integrity and immutability, providing a trustworthy and secure way for employers to providing a trustworthy and secure way for employers to verify qualifications.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-center mt-10">
//           <div className="w-[70%]">
//             <div>
//               <p className="font-bold text-xl">What are NFT certificates?</p>
//             </div>
//             <div>
//               <p className="text-sm mt-5">
//                 NFT certificates are blockchain-verified digital certificates that you earn as you complete courses. They represent milestones in your skill development and provide a tangible record of your achievements
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-center mt-10">
//           <div className="w-[70%]">
//             <div>
//               <p className="font-bold text-xl">
//                 How can FABC help me find employment?
//               </p>
//             </div>
//             <div>
//               <p className="text-sm mt-5">
//                 By providing you with verified skills and a Blockchain-based NFT CV, we make it easier for employers to trust your qualifications. Our platform connects you with opportunities that match your abilities, helping you secure employment.

//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-center mt-10">
//           <div className="w-[70%]">
//             <div>
//               <p className="font-bold text-xl">
//                 What support is available to learners?

//               </p>
//             </div>
//             <div>
//               <p className="text-sm mt-5">
//                 We offer comprehensive support through our community, interactive modules, and practical assessments. Our team is always available to help you with any questions or challenges you may face.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

import React, { useState } from "react";
import Header from "../Layout/LoginHeader";
import Footer from '../../../Layout/Footer';
import { faChevronRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

const FAQ = () => {
  const faq = [
    {
      question: "What is FABC?",
      answer:
        " FABC is the world's first native Web3 unbiased Skill Exchange platform. Our mission is to bridge the gap between education and employment by leveraging Blockchain technology and AI."
    },
    {
      question: "How does FABC ensure trustworthy and secure verification of qualifications?",
      answer:
        " FABC offers a decentralized and transparent system where learners can acquire and validate skills through blockchain-based NFT CVs. These tokenized skill certifications ensure individual integrity and immutability, providing a trustworthy and secure way for employers to verify qualifications."
    },
    {
      question: "What are NFT certificates",
      answer:
        "NFT certificates are blockchain-verified digital certificates that you earn as you complete courses. They represent milestones in your skill development and provide a tangible record of your achievements."
    },
    {
      question: "What support is available to learners?",
      answer:
        "We offer comprehensive support through our community, interactive modules, and practical assessments. Our team is always available to help you with any questions or challenges you may face."
    },
    {
      question: " How can FABC help me find employment?",
      answer:
        " By providing you with verified skills and a Blockchain-based NFT CV, we make it easier for employers to trust your qualifications. Our platform connects you with opportunities that match your abilities, helping you secure employment.",
    },

  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the selected item
    }
  };

  return (
    <div>

      <div
        className="  sec-1  bg-cover bg-[#191919] font-poppins text-white"
        style={{ backgroundImage: 'url("/assets/BG/FABC.png")' }}
      >
        <Header />

        <h1 className="text-4xl text-center font-bold font-Artemus uppercase text-shadow-custom" data-aos="fade-up" data-aos-duration="">faq’s</h1>

        <div className="md:w-[55%] w-[90%] mx-auto mt-10 pb-10" data-aos="fade-up" data-aos-duration="1000">
          {faq.slice(0, 5).map((data, index) => (
            <div
              key={index}
              onClick={() => toggleExpand(index)}
              className={`bg-white text-black rounded cursor-pointer mt-2 duration-500 transform transition ease-in-out ${expandedIndex === index ? "h-full" : "h-full"
                }`}
            >
              <div className="flex ">
                <div className="w-[90%] p-3">
                  <h1 className="font-semibold py-2">{data.question}</h1>
                </div>
                <div className="w-[10%] flex items-center justify-center">
                  <button className="font-bold">
                    {expandedIndex === index ? (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className={`transform transition-transform duration-500`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={`transform transition-transform duration-500`}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div
                className={`duration-500 px-5 bg-gradient-to-r from-[#0087A5] to-[#0E2DA7] ease-in-out overflow-hidden ${expandedIndex === index
                  ? "max-h-[150px] rounded-b md:max-h-[120px] py-5"
                  : "max-h-0"
                  }`}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                  className="text-sm text-white font-semibold duration-500"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQ;