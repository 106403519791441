import React from 'react'
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";


export default function Landing() {

    const [moveImage, setMoveImage] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://edu.d5art.com/api/Allcourses');
                const data = await response.json();
                const filteredCourses = data.filter(course => course.status === 'enabled');
                setCourses(filteredCourses);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
        fetchCourses();
    }, []);



    const truncateDescription = (description) => {
        const words = description.split(' ');
        const truncatedDescription = words.slice(0, 20).join(' ');
        const showMore = words.length > 20;
        return showMore ? `${truncatedDescription}.....` : truncatedDescription;
    };


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleSubmitChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields
        const { firstName, lastName, email, phone, message } = formData;
        if (!firstName || !lastName || !email || !phone || !message) {
            toast.error('All fields are required');
            return;
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        toast.success('Submitted successfully');

        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: ''
        });
    };


    // const handleSearch = () => {
    //     navigate(`/courses?search=${searchTerm}`);
    // };


    const imageList = [
        { src: './assets/Brand/MetaMask.png', className: 'md:mt-10 translate-y-2' },
        { src: './assets/Brand/Polygon.png', className: 'translate-y-1 md:translate-y-3' },
        { src: './assets/Brand/Arbitrum.png', className: 'w-9/12 md:-translate-y-2' },
        { src: './assets/Brand/Magna.png', className: '-translate-y-2' },
        { src: './assets/Brand/MetaMask.png', className: 'md:mt-10 translate-y-2' },
        { src: './assets/Brand/Polygon.png', className: 'translate-y-2 md:translate-y-3' },
        { src: './assets/Brand/Arbitrum.png', className: 'w-9/12 md:-translate-y-2' },
        { src: './assets/Brand/Magna.png', className: '-translate-y-2' }
      ];


    const handleSearch = () => {
        const url = `https://edu.d5art.com/courses?search=${encodeURIComponent(searchTerm)}`;
        window.location.href = url;
    };



    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        const moveInterval = setInterval(() => {
            setMoveImage((prevMove) => !prevMove);
        }, 1500); // Adjust the interval as needed

        return () => {
            clearInterval(moveInterval);
        };
    }, []);

    const settings = {

        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 2500);

        return () => clearInterval(timer);
    }, []);

    const handleBlog = (url) => {
        const fullUrl = url.startsWith('http') ? url : `https://${url}`;
        window.open(fullUrl, '_blank');
    }
    

    const teamMembers = [
        {
            name: 'Sakthi Visakan Rajaguru',
            role: 'CEO',
            imageSrc: './assets/SME/Sakthi.png',
        },
        {
            name: 'Alun Richard’s',
            role: 'ADVISORY BOARD MEMBER',
            imageSrc: './assets/SME/alun.png',
        },
        {
            name: 'Graziella Thake',
            role: 'HUMAN CAPITAL DOMAIN EXPERT',
            imageSrc: './assets/SME/Graziella.png',
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div>
           
            <div className='bg-[#1A1919] font-poppins min-h-screen ' style={{ backgroundImage: "url('./assets/Others/background.png')" }}>
            <Header />
            <Toaster />
                <div className='Section-1'>
                    <div className='w-full md:w-[100%] pt-14 px-10'>
                        <div className='flex justify-around h-full'>
                            <div className='h-full flex items-center translate-y-24'>
                                <img style={{
                                    transition: 'transform 0.9s ',
                                    transform: moveImage ? 'translateY(25px)' : 'translateY(0)',
                                }} className='' src='./assets/Others/Ellipse6.png' alt='circle1'></img>
                            </div>
                            <div className='' style={{
                                transition: 'transform 2s ',
                                transform: moveImage ? 'translateY(45px)' : 'translateY(0)',
                            }}>
                                <img src='./assets/Others/Ellipse4.png' alt='circle2'></img>
                            </div>
                            <div style={{
                                transition: 'transform 0.1s ',
                                transform: moveImage ? 'translateY(20px)' : 'translateY(0)',
                            }}>
                                <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                            </div>

                        </div>
                        <div>
                        

                            <p className='text-white text-center font-bold tracking-widest text-3xl lg:text-5xl '>Learn and Mint  your <span style={{
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                backgroundImage: 'linear-gradient(to right, #00768B,#0E2DA7)',
                            }}>NFT</span>  by expanding your knowledge and mastering new skills</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-10'>
                        <p className='w-[90%] md:w-[40%] font-museomoderno text-center text-white font-musemo text-lg'>
                        Our vision is to revolutionize the User journey from Education to Employability by fostering cutting-edge skill development and upskilling in the dynamic Web 3.0 ecosystem.                            </p>
                    </div>
                    {/* <div className='flex justify-center mt-6'>
                        <p className='w-[70%] md:w-[30%] bg-[#323745] rounded-full shadow-lg px-4 py-1 flex  items-center gap-2'><i class="fa-solid fa-magnifying-glass" style={{ color: "#f8f9fc" }}></i><input className='w-[100%] rounded-full bg-transparent outline-none py-2 text-white'
                            placeholder='Search Anything'
                            value={searchTerm}
                            onChange={handleChange}

                        ></input></p>
                    </div> */}
                    <div className='flex flex-col lg:flex-row  gap-5 md:gap-0'>
                        <div className='lg:w-[30%] flex justify-center bg-cover bg-center' style={{ backgroundImage: 'url("./assets/Others/Ellipse7.png")' }}>
                            <img className='w-[75%]' src='./assets/Others/avatar1.png'></img>
                        </div>
                        <div className='lg:w-[40%] grid content-around '>
                            {/* <div className='flex justify-center'>
                                <button className='bg-gradient-to-r from-[#00768B] to-[#0E2DA7] text-white p-[2px] rounded-full' onClick={handleSearch}>
                                    <button className='bg-black px-5 py-2 rounded-full'>
                                        Explore Courses
                                    </button>
                                </button>
                            </div> */}
                            <div className='flex justify-center text-2xl font-museomoderno mt-5 md:mt-0'>
                                <div className='w-[40%]'>
                                    <p className='text-white '>12+</p>
                                    <p className='text-white font-musemo text-base'>Trusted Partners</p>
                                </div>
                                <div className='w-[40%] flex justify-end'>
                                    <div>
                                        <p className='text-white'>20k+</p>
                                        <p className='text-white font-musemo text-base'>Happy Learners</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-[30%] flex justify-center bg-contain bg-no-repeat bg-center' style={{ backgroundImage: 'url("./assets/Others/Ellipse7.png")' }}>
                            <img className='w-[70%]' src='./assets/Others/avatar2.png'></img>
                        </div>

                    </div>
                    <div>
                        <div className='mt-5'>
                            <p className='text-white font-museomoderno text-center text-2xl '>Trusted By</p>
                        </div>
                        <div className='flex justify-center px-3 lg:px-0'>
                            <Slider className='mt-3 w-[90%]' {...settings} prevArrow={<></>} nextArrow={<></>}>
                                                        
                            {imageList.map((image, index) => (
                                    <div key={index}>
                                        <img className={image.className} src={image.src} alt={`slider-image-${index}`} />
                                    </div>
                                    ))}

                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='Section-2'>
                    <div className='mt-5'>
                        <p className='text-[#FFBA00] font-museomoderno font-bold text-center text-3xl'>Virtual Identity</p>
                    </div>
                    <div className='mt-5 flex flex-col lg:flex-row pb-10 '>
                        <div className='lg:w-[50%] flex font-nerko text-3xl' >
                            <div className='w-[30%] mt-20 bg-cover bg-center bg-no-repeat' style={{ backgroundImage: 'url("./assets/Others/Ellipse7.png")' }}>
                                <img src='./assets/Others/avatar3.png'></img>
                                <p className='font-musemo text-gray-500 text-center'>inam</p>
                            </div>
                            <div className='w-[30%] bg-contain bg-center bg-no-repeat' style={{ backgroundImage: 'url("./assets/Others/Ellipse7.png")' }} >
                                <img src='./assets/Others/avatar4.png.png'></img>
                                <p className='font-musemo text-gray-500 text-center'>Puna</p>
                            </div>
                            <div className='w-[30%] mt-20 bg-contain bg-right bg-no-repeat' style={{ backgroundImage: 'url("./assets/Others/Ellipse7.png")' }}>
                                <img src='./assets/Others/avatar5.png'></img>
                                <p className='font-musemo text-gray-500 text-center'>Edith</p>
                            </div>
                        </div>
                        <div className='lg:w-[50%] px-3 lg:px-0'>
                            <div className='w-full md:w-[100%] '>
                                <div className='flex justify-around h-full'>

                                    <div className='' style={{
                                        transition: 'transform 2s ',
                                        transform: moveImage ? 'translateY(45px)' : 'translateY(0)',
                                    }}
                                    ><img src='./assets/Others/Ellipse4.png' alt='circle2'></img>

                                    </div>
                                    <div style={{
                                        transition: 'transform 0.5s ',
                                        transform: moveImage ? 'translateY(25px)' : 'translateY(0)',
                                    }}>
                                        <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                                    </div>

                                </div>
                                <div>

                                </div>
                            </div>
                            <div className='flex px-5 mt-10'>
                                <p className=' text-white md:w-[80%]'>Your privacy is the  most  impeccable asset,Create Your virtual avatar identity  and explore the endless world of <span className='text-[#00768B] font-bold'>WEB 3.0</span> with us, </p>
                            </div>

                            <div className='w-full md:w-[100%] relative'>
                                <div className='flex justify-center md:justify-between px-5 h-full'>

                                    <NavLink to="https://ex.d5art.com">


                                        <div className='flex justify-start mt-8'>
                                            <button className='flex pt-3 pb-1 px-2 bg-[#1A1919] z-20 rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Create</p>
                                                <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                            </button>
                                        </div>
                                    </NavLink>
                                    <div className='absolute md:relative z-0' style={{
                                        transition: 'transform 0.1s ',
                                        transform: moveImage ? 'translateY(20px)' : 'translateY(0)',
                                    }}>
                                        <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                                    </div>
                                    <div className='hidden md:block' style={{
                                        transition: 'transform 2s ',
                                        transform: moveImage ? 'translateY(45px)' : 'translateY(0)',
                                    }}
                                    ><img src='./assets/Others/Ellipse4.png' alt='circle2'></img>

                                    </div>


                                </div>
                                <div>

                                </div>
                            </div>
                            <div className='w-full md:w-[100%] mt-2'>
                                <div className='flex justify-between px-5 h-full'>

                                    <div style={{
                                        transition: 'transform 1s ',
                                        transform: moveImage ? 'translateY(20px)' : 'translateY(0)',
                                    }}>
                                        <img src='./assets/Others/Ellipse6.png' alt='circle2'></img>
                                    </div>



                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Section-3'>
                    <div className='w-full md:w-[100%] pt-14 px-10'>
                        <div className='w-[70%] flex justify-end h-full'>

                            <div
                                className=""
                                style={{
                                    transition: 'transform 5s ',
                                    transform: moveImage ? 'translateY(45px) translateX(45px)' : 'translateY(0) translateX(0)',
                                }}
                            >
                                <img src='./assets/Others/Ellipse6.png' alt='circle2' />
                            </div>


                        </div>
                        <div>
                            <p className='text-[#FFBA00] font-museomoderno font-bold text-center text-3xl'>Most Popular Courses</p>
                        </div>
                    </div>
                    <div className='w-full md:w-[100%] px-10 pb-10'>
                        <div className='flex justify-evenly h-full'>
                            <div style={{
                                transition: 'transform 0.1s ',
                                transform: moveImage ? 'translateY(20px) translateX(20px)' : 'translateY(0) translateX(0)',
                            }}>
                                <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                            </div>
                            <div className='h-full flex items-center translate-y-24'>
                                <img style={{
                                    transition: 'transform 0.9s ',
                                    transform: moveImage ? 'translateY(25px) translateX(25px)' : 'translateY(0) translateX(0)',
                                }} className='' src='./assets/Others/Ellipse4.png' alt='circle1'></img>
                            </div>



                        </div>
                        <div className=' flex justify-center'>
                            <p className='text-center text-white w-[85%]'>
                            Here are some of the in-demand Web 3.0 courses to choose from to enhance your knowledge.<br/>
                            For more options visit the course section.                                
                                </p>
                        </div>
                    </div>
                    <div className='flex justify-center text-white'>


                        <div className='w-[90%] flex flex-col gap-5 md:gap-0 md:flex-row justify-between my-10'>
                            {courses.slice(0,3).map((course, index) => (
                                <div key={index} className='md:w-[30%] p-5 grid content-between hover:bg-black/50 duration-500 rounded-2xl'>
                                    <div>
                                        <div className='flex'><img src={`/assets/Courses${course.image_url}`} alt='Course Image' /></div>
                                        <p className='flex justify-between mt-3'><p className='font-play text-xl font-bold'>{course.course_name}</p><span className='text-sm mt-1'><i className="fa-regular fa-calendar mr-2"></i>{course.duration_hours} hours</span></p>
                                        <p className='flex justify-between text-xs mt-3'><p><i className="fa-solid fa-list-ul mr-2 text-base"></i>{course.category}</p> <p><span className='text-[#F29D38] flex justify-center gap-1 mt-1'><i className='fa-solid fa-star'></i><i className='fa-solid fa-star'></i><i className='fa-solid fa-star'></i><i className='fa-solid fa-star'></i><i className='fa-solid fa-star'></i></span></p></p>
                                        <p className='text-sm my-3 text-gray-300'>{truncateDescription(course.course_description)}</p>
                                    </div>
                                    <div className='flex justify-between text-sm'>
                                        <div className='flex'>
                                            <div><img src='./assets/Others/Token.png' alt='Token Icon' /></div>
                                            <div><p className='mt-1.5 pl-2 font-bold'>{course.price} D5ART TOKENS</p></div>
                                        </div>
                                        <div>
                                            <NavLink to={`https://edu.d5art.com/precourse?id=${course.course_id}`}>
                                                <button className='bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]'>
                                                    <button className='flex pt-2.5 pb-0.5 font-play px-2 bg-black rounded-full group'>
                                                        <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[85px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                        <p className='mx-3 -translate-y-0.5 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-base'>Enroll</p>
                                                        <button className=' p-3 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                <div className='Section-4'>
                    <div className='w-full md:w-[100%] md:pt-14 px-10'>
                        <div className='w-[42%] flex justify-end  h-full'>
                            <div className='' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(35px)' : 'translateY(0)',
                            }}
                            ><img src='./assets/Others/Ellipse6.png' alt='circle2'></img>
                            </div>
                        </div>
                        <div>
                            <p className=' text-[#FFBA00] font-museomoderno text-center font-bold text-3xl'>NFT CV</p>
                        </div>
                        <div className='w-[75%] mx-auto flex justify-end h-full'>
                            <div className='' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(35px) translateX(35px)' : 'translateY(0) translateX(0)',

                            }}
                            ><img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                            </div>
                        </div>
                        <p className='text-[#FFFFFF]/75 mx-auto text-center md:w-[80%] font-museomoderno'>

Create a secure and verifiable NFT CV containing your skills, experience, expertise and projects, omitting your personal details for an unbiased recruitment.<br/>
Coupled with an exclusive Recruitment Management System (RMS), it ensures a fair, merit-based validation and streamlined hiring process.
                        
                        </p>
                        <div className='w-[75%] mx-auto  h-full'>
                            <div className='-translate-y-20' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(25px) ' : 'translateY(0) ',

                            }}
                            ><img src='./assets/Others/Ellipse4.png' alt='circle2'></img>

                            </div>
                        </div>
                        <div>
                            <img src='./assets/Others/nftCV.png' className='mx-auto'></img>
                            <div className='flex justify-center'>
                                <NavLink className="flex justify-center relative" to="https://ex.d5art.com">
                                    <button className='absolute md:-translate-y-16 text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]'>
                                        <button className='flex pt-3 pb-1 font-play px-2.5 bg-[#1A1919] rounded-full group ' >
                                            <button className='p-2 rounded-full translate-y-1 group-hover:translate-x-[111px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-3 -translate-y-0.75 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-lg'>Explore</p> <button className=' p-4 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                        </button>
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Section-5'>
                    <div className='w-full md:w-[100%] pt-14 px-10'>
                        <div className='w-[52%] flex justify-end  h-full'>
                            <div className='' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(35px)' : 'translateY(0)',
                            }}
                            ><img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                            </div>
                        </div>
                        <div>
                            <p className='text-[#FFBA00] font-museomoderno text-center font-bold text-3xl'>Tokenization</p>
                        </div>
                        <div className='w-[100%] flex justify-evenly h-full'>

                            <div className='-translate-y-20' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(25px) translateX(25px)' : 'translateY(0) translateX(0)',

                            }}
                            ><img src='./assets/Others/Ellipse4.png' alt='circle2'></img>

                            </div>
                            <div className='' style={{
                                transition: 'transform 5s ',
                                transform: moveImage ? 'translateY(45px) translateX(45px)' : 'translateY(0) translateX(0)',

                            }}
                            ><img src='./assets/Others/Ellipse6.png' alt='circle2'></img>

                            </div>

                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row  mt-5'>
                        <div className='lg:w-[50%] flex justify-center items-center ' >
                            <img src='./assets/Others/Ellipse7.png' className='absolute w-[50%]'></img>
                            <img className='w-[50%] md:w-auto' src='./assets/Others/coin.png' ></img>
                        </div>
                        <div className='lg:w-[40%] mt-8 px-5 flex items-center'>
                            <div>
                                <p className='text-white text-center md:text-start'>Earn  rewards in form of 
                                    &nbsp;<span className='text-[#00768B] font-bold'>D5ART tokens </span>by becoming one of the top learners on the platform and use that to <span className='text-[#00768B] font-bold'>create and manage your knowledge battle </span>
                                    &nbsp;and clan to challenge your friends and co-learners.</p>
                                <div className='flex md:justify-start justify-center mt-10 md:mt-20'>
                                    <NavLink to="https://ex.d5art.com">
                                        <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
                                            <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Explore</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Section-6'>
                    <div className=' md:h-full w-full mt-12 md:mt-24 '>
                        <p className='text-[#FFBA00] font-museomoderno text-center font-bold text-3xl'>Stay Updated</p>
                        <div className='stay-updated '>
                            <div className='px-10 md:px-28 py-10 pt-12 w-full flex flex-col md:flex-row md:justify-between gap-3 md:gap-7'>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/how-to-deploy-a-smart-contract-on-ton-network-f99bf728934a')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 0 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('./assets/Others/blog4.webp')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/build-your-own-custom-blockchain-using-polygon-supernet-e71bd75afe70')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 1 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('./assets/Others/blog2.gif')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/polygon-the-next-generation-of-blockchain-f75bb217ace0')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 2 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('./assets/Others/blog3.webp')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/enhancing-trust-and-transparency-polygon-id-steps-to-create-an-organization-issuer-node-f997b32b3d1b')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 3 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('./assets/Others/blog1.gif')" }}>
                                </div>
                            </div>
                        </div>
                        <p className='text-[#FFFFFF]/75 mx-auto text-center w-[80%] font-museomoderno'>
                        Keep yourself updated with insights into the latest news, trends, tips and best practices in recruitment, career development and Blockchain Technology.                        </p>
                    </div>
                </div>
                <div className='Section-7 '>
                    <div className='flex justify-center'>
                        <div className='w-full  md:w-[50%] pb-16 px-10'>
                            <div className='flex justify-center'>
                                <div className='' style={{
                                    transition: 'transform 2s ',
                                    transform: moveImage ? 'translateY(45px)' : 'translateY(0)',
                                }}
                                ><img src='./assets/Others/Ellipse6.png' alt='circle2'></img>

                                </div>
                            </div>
                            <div className='flex justify-around h-full'>
                                <div className='h-full flex items-center '>
                                    <img style={{
                                        transition: 'transform 0.9s ',
                                        transform: moveImage ? 'translateY(25px)' : 'translateY(0)',
                                    }} className='' src='./assets/Others/Ellipse4.png' alt='circle1'></img>
                                </div>

                                <div style={{
                                    transition: 'transform 0.1s ',
                                    transform: moveImage ? 'translateY(20px)' : 'translateY(0)',
                                }}>
                                    <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                                </div>

                            </div>
                            <div className='-translate-y-20'>
                                <p className='text-[#FFBA00] font-museomoderno text-center font-bold text-3xl'>Testimonials</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center mt-5 pb-10'>
                        <div className='w-[100%] flex flex-col gap-12 lg:gap-0 lg:flex-row justify-around px-10'>
                            <div className='lg:w-[45%] grid content-between  bg-[#1e1e1e] border-t-2 border-gray-600' style={{ borderRadius: "25px" }}>
                                <div className='-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden'><img src='./assets/Testmonials/brinda.jpeg'></img>
                                </div>
                                <div className='px-3'>
                                    <p className='text-white font-poppins text-sm'>Cracking Blockchain gave me the foundational knowledge I needed. I landed a job as a Blockchain Developer within two months of completing it.
                                       </p>
                                </div>
                                <div className='flex justify-between items-center  px-1 mt-2'>
                                    <div className='px-4  bg-contain bg-no-repeat py-5 md:w-[45%]' style={{ backgroundImage: "url('./assets/Others/Vectorcolon.png')", borderRadius: "25px" }}>
                                        <p className='text-white text-xl '>Brinda</p>
                                        <p className='text-white '>Blockchain Analyst</p>
                                    </div>

                                </div>
                            </div>
                            <div className='lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600' style={{ borderRadius: "25px" }}>
                                <div className='-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden'><img src='./assets/Testmonials/hemanth.jpeg'></img></div>
                                <div className='px-3 text-white font-poppins text-sm'>
                                    <p >Cracking Blockchain was the perfect starting point. Now, I'm working as a Blockchain Analyst.</p>

                                </div>
                                <div className='flex justify-between items-center  px-1 mt-2'>
                                    <div className='px-4  bg-contain bg-no-repeat py-5 md:w-[45%]' style={{ backgroundImage: "url('./assets/Others/Vectorcolon.png')", borderRadius: "25px" }}>
                                        <p className='text-white text-xl '>Hemanth</p>
                                        <p className='text-white '>Smartcontract Developer</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14'>
                        <div className='w-[100%] flex flex-col gap-12 lg:gap-0  lg:flex-row justify-around px-10'>
                            <div className='lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600' style={{ borderRadius: "25px" }}>
                                <div className='-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden'><img src='./assets/Testmonials/Sathish.jpeg'></img></div>
                                <div className='px-3'>
                                    <p className='text-white font-poppins text-sm'>The Blockchain and MetaMask course advanced my career. I’m now proud to work as a Blockchain Integration Specialist.
                                    </p>
                                </div>
                                <div className='flex justify-between items-center  px-1 mt-2'>
                                    <div className='px-4  bg-contain bg-no-repeat py-5 md:w-[45%]' style={{ backgroundImage: "url('./assets/Others/Vectorcolon.png')", borderRadius: "25px" }}>
                                        <p className='text-white text-xl'>Sathish</p>
                                        <p className='text-white '>Blockchain Developer</p>
                                    </div>
                                </div>
                            </div>
                            <div className='lg:w-[45%] grid content-between bg-[#1e1e1e] border-t-2 border-gray-600' style={{ borderRadius: "25px" }}>
                                <div className='-translate-y-10 w-[5.5rem] h-[5.5rem] rounded-full overflow-hidden'><img src='./assets/Testmonials/nithish.jpeg'></img></div>
                                <div className='px-3'>
                                    <p className='text-white font-poppins text-sm'>Cracking Blockchain exceeded my expectations. It helped me become a Smart Contract Developer.
                                     </p>
                                </div>
                                <div className='flex justify-between items-center  px-1 mt-2'>
                                    <div className='px-4  bg-contain bg-no-repeat py-5 md:w-[45%]' style={{ backgroundImage: "url('./assets/Others/Vectorcolon.png')", borderRadius: "25px" }}>
                                        <p className='text-white text-xl'>Nitish</p>
                                        <p className='text-white '>Smartcontract Developer </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Section-8'>
                    <div className='Council-of-SME py-10'>
                        <div className='md:flex h-screen overflow-hidden '>
                            <div className='md:w-[40%] flex justify-center'>
                                <div className='rotate-45'>
                                    <div className='' style={{
                                        transition: 'transform 1s ease-in-out',
                                        transform: moveImage ? 'translatey(50px)' : 'translatey(0)',

                                    }}
                                    ><img src='./assets/Others/Ellipse6.png' alt='circle2'></img>
                                    </div>
                                </div>
                            </div>

                            <div className='absolute flex items-center justify-center md:h-full w-full md:pt-20 text-white '>
                                <div className='flex justify-center '>
                                    <div className='w-[90%] md:w-[85%]'>
                                        <p className='font-museomoderno text-4xl text-center text-[#FFBA00] font-bold'>Council of  <span style={{
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #0EA4A9, #F29D38)',
                                        }}>SME</span></p>
                                        <p className='py-5 md:py-10 text-lg text-center font-museomoderno'>At FABC, we're proud to collaborate with a diverse network of global Consortium partners who share our commitment to innovation and excellence.</p>
                                        <div className='hidden md:block'>
                                            <div className='flex justify-around mt-5'>
                                                <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid gap-3'>
                                                    <div className='flex justify-center'>
                                                        <div>
                                                            <img src='./assets/SME/Sakthi.png' className='rounded-3xl'></img>
                                                        </div>
                                                    </div>
                                                    <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Sakthi Visakan Rajaguru</p>
                                                    <p className='font-black text-center font-play text-lg'>CEO</p>

                                                </div>
                                                <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                                    <div className='flex justify-center'>
                                                        <div>
                                                            <img src='./assets/SME/alun.png' className='rounded-3xl'></img>
                                                        </div>
                                                    </div>
                                                    <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Alun Richard’s</p>
                                                    <p className='font-black text-center font-play text-lg'>ADVISORY BOARD MEMBER</p>

                                                </div>
                                                <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                                    <div className='flex justify-center'>
                                                        <div>
                                                            <img src='./assets/SME/Graziella.png' className='rounded-3xl'></img>
                                                        </div>
                                                    </div>
                                                    <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Graziella Thake</p>
                                                    <p className='font-black text-center font-play text-lg'>HUMAN CAPITAL DOMAIN EXPERT</p>

                                                </div>
                                                <div className='hidden p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                                    <div className='flex justify-center'><div>
                                                        <img src='./assets/SME/adam.png' className='rounded-3xl'></img>
                                                    </div></div>
                                                    <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Adam Roosevelt</p>
                                                    <p className='font-black text-center font-play text-lg'>SUBJECT MATTER EXPERT</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='block md:hidden '>
                                            <div className='flex justify-between mt-5'>
                                                <button onClick={goToPrev}><i class="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i></button>
                                                <div className='p-5 w-[70%] rounded-3xl bg-white/5 grid gap-3'>
                                                    <div className='flex justify-center'>
                                                        <div>
                                                            <img src={teamMembers[currentIndex].imageSrc} className='rounded-3xl' alt={teamMembers[currentIndex].name} />
                                                        </div>
                                                    </div>
                                                    <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>{teamMembers[currentIndex].name}</p>
                                                    <p className='font-black text-center font-play text-lg'>{teamMembers[currentIndex].role}</p>
                                                </div>
                                                <button onClick={goToNext}><i class="fa-solid fa-chevron-right  px-3 py-2 rounded-full bg-white/10"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Section-9'>
                    <div className='flex justify-center'>
                        <div className='w-[100%] px-10'>
                            <div className='flex justify-center'>
                                <div className='' style={{
                                    transition: 'transform 2s ',
                                    transform: moveImage ? 'translateY(45px)' : 'translateY(0)',
                                }}
                                ><img src='./assets/Others/Ellipse6.png' alt='circle2'></img>

                                </div>
                            </div>
                            <div className='flex justify-around h-full'>
                                <div className='h-full flex items-center '>
                                    <img style={{
                                        transition: 'transform 0.9s ',
                                        transform: moveImage ? 'translateY(25px)' : 'translateY(0)',
                                    }} className='' src='./assets/Others/Ellipse4.png' alt='circle1'></img>
                                </div>

                                <div style={{
                                    transition: 'transform 0.9s ',
                                    transform: moveImage ? 'translateY(20px) translateX(20px)' : 'translateY(0) translateX(0px)',
                                }}>
                                    <img src='./assets/Others/Ellipse5.png' alt='circle2'></img>
                                </div>

                            </div>
                            <div className='-translate-y-20'>
                                <p className='text-[#FFBA00] font-museomoderno text-center font-bold text-3xl'>Reach us</p>
                            </div>
                        </div>
                    </div>
                    <p className='py-5  text-white text-lg text-center font-museomoderno w-[80%] mx-auto'>Join us to enhance your career with cutting-edge blockchain technology. Access NFT CVs, exclusive courses, and industry insights. </p>
                    <div className='pb-20 pt-10 w-[90%] mx-auto flex flex-col md:flex-row justify-center gap-5'>
                        <div className='md:w-[60%] bg-[#D9D9D90F] text-gray-300 rounded-2xl p-10' data-aos="fade-up" data-aos-duration="3000">
                            <form onSubmit={handleSubmit}>
                                <div className='flex flex-col md:flex-row justify-center gap-10'>
                                    <div className='md:w-[50%] flex flex-col gap-10'>
                                        <div className='flex flex-col'>
                                            <label className='text-sm 2xl:text-lg'>First Name</label>
                                            <input
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleSubmitChange}
                                                className='bg-transparent border-b outline-none w-[100%]'
                                                required
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <label className='text-sm 2xl:text-lg'>Email</label>
                                            <input
                                                name="email"
                                                type='email'
                                                value={formData.email}
                                                onChange={handleSubmitChange}
                                                className='bg-transparent border-b outline-none w-[100%]'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='md:w-[50%] flex flex-col gap-10'>
                                        <div className='flex flex-col'>
                                            <label className='text-sm 2xl:text-lg'>Last Name</label>
                                            <input
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleSubmitChange}
                                                className='bg-transparent border-b outline-none w-[100%]'
                                                required
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <label className='text-sm 2xl:text-lg'>Phone</label>
                                            <input
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleSubmitChange}
                                                className='bg-transparent border-b outline-none w-[100%]'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col mt-10'>
                                    <label className='text-sm 2xl:text-lg'>Message</label>
                                    <input
                                        name="message"
                                        value={formData.message}
                                        onChange={handleSubmitChange}
                                        className='bg-transparent placeholder:text-sm py-1 border-b outline-none w-[100%]'
                                        placeholder='Write your message...'
                                        required
                                    />
                                </div>
                                <div className='mt-10 md:pt-20 pb-5 flex justify-center'>
                                    <button
                                        type="submit"
                                        className='text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]'
                                    >
                                        <div className='flex pt-3 pb-1 font-play px-2.5 bg-[#09090B] rounded-full group'>
                                            <div className=' w-5 h-5 rounded-full translate-y-1 group-hover:translate-x-[110px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></div>
                                            <p className='mx-3 -translate-y-0.75 group-hover:-translate-x-4 transform-transition duration-500 ease-in-out text-lg'>Submit</p>
                                            <div className='p-4 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></div>
                                        </div>
                                    </button>
                                </div>
                            </form>

                        </div>
                        <div className='md:w-[40%]'>
                            <div><img src='./assets/Others/avatar6.png'></img></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div >
    )
}
